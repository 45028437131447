import { useEffect, useState } from "react";
import { GetCourses } from "../../services/courseServices";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { Dense_Button_Light } from "../components/buttons";

export default function Courses() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [courseList, setCourseList] = useState([]);

  async function getCourseLise() {
    const courseList = await GetCourses();
    setCourseList(courseList);
  }

  useEffect(() => {
    getCourseLise();
  }, []);

  return (
    <>
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light underline">Online Courses</div>
          <div className="header-xxl light pad-top-m">Master the art of</div>
          <div className="header-xxl">Aesthetic Medicine</div>
          <p className="body-l pad-top-m">
            Our hands-on training courses are designed for small groups of up to
            5 participants, ensuring personalized attention and a focused
            learning experience. Led by industry experts, these intimate
            sessions allow you to master the latest techniques in aesthetic
            medicine with the guidance and support you need to excel.
          </p>
          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video margin-top-l w-video w-embed"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FNVwzdQ2ujOI%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DNVwzdQ2ujOI&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FNVwzdQ2ujOI%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="MedSpa Network Training Highlight: Cadaver Lab Group Training"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="brown-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs center">Featured</div>
          <div className="header-xxl light pad-top-m center">CME Course</div>
          <div className="header-xxl center">{courseList[0]?.title}</div>
          <p className="body-l center pad-top-m">
            {courseList[0]?.course_description}
          </p>
          <div className="featured-event">
            <img
              src={`/image/${courseList[0]?.image}`}
              loading="lazy"
              alt=""
              className="featured-event-image"
            />
            <div className="featured-event-details brown">
              <div className="featured-event-title-bar">
                <div className="featured-event-title">
                  <div className="header-s">{courseList[0]?.title}</div>
                  <div className="date-badge-light general-1">
                    <div>NEW</div>
                  </div>
                </div>
              </div>
              <div className="featured-event-details-bar">
                <Dense_Button_Light
                  text="Start Course"
                  link={
                    `/course?` +
                    new URLSearchParams({
                      _id: courseList[0]?._id,
                      title: courseList[0]?.title,
                      video: courseList[0]?.teaserVideo,
                    }).toString()
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs light underline">Courses</div>
          <div className="header-xl pad-top-l">Available Courses</div>
          <div className="class-list pad-top-m">
            {courseList
              .sort((a, b) => {
                return a.title < b.title ? -1 : 1;
              })
              .map((course, index) => {
                return (
                  <div className="event-card-type-2" key={index}>
                    <div className="featured-event-title-bar">
                      <div className="featured-event-title">
                        <div
                          className="header-s light"
                          style={{ color: "white" }}
                        >
                          {course.title}
                        </div>
                      </div>
                    </div>
                    <div className="featured-event-details-bar">
                      <div className="div-block-50">
                        <div className="badge">
                          <div className="eyebrow s light-aurora">Online</div>
                        </div>
                      </div>

                      <Dense_Button_Light
                        text="Start Course"
                        link={
                          `/course?` +
                          new URLSearchParams({
                            _id: course._id,
                            title: course.title,
                            video: course.teaserVideo,
                          }).toString()
                        }
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="light-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">
            Ready to Grow With Us?
          </div>
          <div className="header-xl pad-top-l">Get Your Membership Today.</div>
          <div className="header-xl light pad-top-xs">
            Join MedSpa for $1,495/year and Gain Access to the Resources,
            Training, and Community you Need to Success in Aesthetic Medicine.
          </div>
          <div className="button-group">
            <a href="#" className="button-dense-primary-dark ">
              Join us Today
            </a>
            <a href="#" className="button-dense-primary-light ">
              View Pricing
            </a>
          </div>
          <h1 className="header-s regular pad-top-xl">
            From the aesthetics community
          </h1>
          <div
            id="w-node-_5b2bdfd3-45f6-4ecf-b4a3-69f7eba3d298-eba3d289"
            className="w-layout-layout three-stack pad-top-m wf-layout-layout"
          >
            <div
              id="w-node-_5b2bdfd3-45f6-4ecf-b4a3-69f7eba3d299-eba3d289"
              className="w-layout-cell"
            >
              <div className="testimonial-card light">
                <div className="testimonial-header-container">
                  <img
                    loading="lazy"
                    src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    alt=""
                    className="testimonial-image"
                  />
                  <div className="testimonial-date">Jan 25 2024</div>
                </div>
                <p className="body-s">
                  &quot;Pivit in my career was enormous after I participated in
                  lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare.&quot;
                </p>
                <div className="div-block-19">
                  <div className="body-s">Jane Doe</div>
                  <div className="body-s extra-thin">Salon Owner</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_5b2bdfd3-45f6-4ecf-b4a3-69f7eba3d2a6-eba3d289"
              className="w-layout-cell"
            >
              <div className="div-block-10"></div>
              <div className="testimonial-card light">
                <div className="testimonial-header-container">
                  <img
                    loading="lazy"
                    src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    alt=""
                    className="testimonial-image"
                  />
                  <div className="testimonial-date">Jan 25 2024</div>
                </div>
                <p className="body-s">
                  &quot;Pivit in my career was enormous after I participated in
                  lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare.&quot;
                </p>
                <div className="div-block-19">
                  <div className="body-s">Jane Doe</div>
                  <div className="body-s extra-thin">Salon Owner</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_5b2bdfd3-45f6-4ecf-b4a3-69f7eba3d2b4-eba3d289"
              className="w-layout-cell"
            >
              <div className="div-block-10"></div>
              <div className="testimonial-card light">
                <div className="testimonial-header-container">
                  <img
                    loading="lazy"
                    src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    alt=""
                    className="testimonial-image"
                  />
                  <div className="testimonial-date">Jan 25 2024</div>
                </div>
                <p className="body-s">
                  &quot;Pivit in my career was enormous after I participated in
                  lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare.&quot;
                </p>
                <div className="div-block-19">
                  <div className="body-s">Jane Doe</div>
                  <div className="body-s extra-thin">Salon Owner</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_5b2bdfd3-45f6-4ecf-b4a3-69f7eba3d2c2-eba3d289"
              className="w-layout-cell"
            >
              <div className="testimonial-card light">
                <div className="testimonial-header-container">
                  <img
                    loading="lazy"
                    src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    alt=""
                    className="testimonial-image"
                  />
                  <div className="testimonial-date">Jan 25 2024</div>
                </div>
                <p className="body-s">
                  &quot;Pivit in my career was enormous after I participated in
                  lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare.&quot;
                </p>
                <div className="div-block-19">
                  <div className="body-s">Jane Doe</div>
                  <div className="body-s extra-thin">Salon Owner</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
