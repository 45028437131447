import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import ReactGA from "react-ga4";
ReactGA.initialize("G-CVD1XQ5B0K");

export default function UserTracking(pathname) {
  console.log(pathname);

  ReactGA.send({
    hitType: "pageview",
    page: pathname,
    title: pathname,
  });

  // console.log(email);
  // const {
  //   setPathPageView,
  //   setIdentity,
  //   setContentType,
  //   setTrackPageView,
  //   setTrackEvent,
  // } = useTrackingCode();

  // setPathPageView(pathname);
  // setIdentity(email || "");
  // setContentType("page_view");
  // setTrackEvent({
  //   value: {
  //     ...search,
  //   },
  // });

  // setTrackPageView();
}
