import "../css/medspa-network-v3.webflow.css";
import "../css/normalize.css";
import "../css/webflow.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Navbar from "../components/navigations";
import Footer from "../components/footer";
import {
  Primary_button_dark,
  Primary_button_light,
  Medium_button_secondary_light,
} from "../components/buttons";

export default function GlpInformationNew() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    signatureDate: new Date().toLocaleDateString(),
  });

  async function submitForm(e) {
    e.preventDefault();
    document.getElementById("submit").value = "Submitting...";
    document.getElementById("submit").disabled = true;
    try {
      const response = await axios.post(
        "/api/v2/emails/semaglutide-landing-page",
        formData
      );

      if (response.data.status === "success") {
        navigate(`/glp-information?${search}&success=true`);
        document.getElementById("submit").value = "Success";
      } else {
        setErrorMessage("Something went wrong, please try again");
        document.getElementById("submit").value = "Submit";
        document.getElementById("submit").disabled = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Navbar type="dark" />
      <section className="dark-background-section image">
        <div className="w-layout-blockcontainer container gap-m ">
          <div className="header-l">Manufactured GLP Program</div>
          <div className="header-xs light" style={{ textAlign: "center" }}>
            Direct from an FDA approved manufacturer to your practice or
            patients, ensuring consistency and convenience.
            <br />
          </div>
          <div>
            <a
              href="#apply"
              className="button-dense-primary-dark "
              style={{ textDecoration: "none" }}
            >
              Apply to the Program
            </a>
          </div>
        </div>
      </section>
      <section id="How" className="light-background-section white">
        <div className="w-layout-blockcontainer container left-justify">
          <div className="paragraph-section">
            <h5 className="paragraph-section-heading">Is it for me?</h5>
            <h1 className="header-xl">
              Why Choose Medspa Network for GLP-1 Products
            </h1>
          </div>
          <div
            id="w-node-_8ff770a1-9997-4c80-5c92-623e14884223-14884223"
            className="w-layout-layout three-stack wf-layout-layout"
            style={{ paddingTop: 64 }}
          >
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e14884224-14884223"
              className="w-layout-cell cell-8"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../../assets/images/group.png")}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">
                  Join a Community of Experts
                </h2>
                <p className="paragraph-card-details">
                  Learn from board-certified plastic surgeons and renowned
                  medical professionals, gaining both knowledge and a supportive
                  network of peers and mentors.
                </p>
              </div>
            </div>
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e1488422b-14884223"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../../assets/images/introduction_to_neuromodulators.jpg")}
                  alt=""
                  className="paragraph-card-image"
                />
                <h2 className="paragraph-card-heading">
                  Stay Ahead with Cutting-Edge Techniques
                </h2>
                <p className="paragraph-card-details">
                  Master the latest non-surgical aesthetic procedures alongside
                  a community of professionals dedicated to advanced techniques
                  and patient safety.
                </p>
              </div>
            </div>
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e14884232-14884223"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../../assets/images/Rectangle-11.png")}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">
                  Connect with Partners &amp; Industry Leaders
                </h2>
                <p className="paragraph-card-details">
                  Leverage our partnerships with top pharmaceutical companies
                  and industry innovators to access the best products, devices,
                  and professional networks for collaboration and growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blue-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs title-underline light">
            Why Choose Medspa Network?
          </div>
          <div className="header-xl top-pad-m">
            Exclusive Benefits with Medspa Network
          </div>
          <div className="header-xl light">GLP-1 Partner Program</div>
          <div
            id="w-node-baf977d2-a00a-51e6-8143-d91326088cae-6df1ccd1"
            className="w-layout-layout full-width-stack pad-top-xl wf-layout-layout"
          >
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088caf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={""} />
              <div>
                <div className="header-m">Direct to patient</div>
                <p className="body-s light">
                  Enjoy the convenience of shipping GLP-1 products directly to
                  your patients' homes.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cb7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src="" />
              <div>
                <div className="header-m">Low Cost to Provider</div>
                <p className="body-s light">
                  Exclusive Medspa Network pricing includes shipping, with bulk
                  orders available to maximize savings.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cbf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src="" />
              <div>
                <div className="header-m">No Cold Shipping Required</div>
                <p className="body-s light">
                  Manufactured GLP-1 products are shipped 2-day without the need
                  for cold chain logistics.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cc7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src="" />
              <div>
                <div className="header-m">Guaranteed Inventory</div>
                <p className="body-s light">
                  Prevent patient and practice delays with our robust inventory
                  management system.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cc7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src="" />
              <div>
                <div className="header-m">Buy and Bill</div>
                <p className="body-s light">
                  Providers prescribe through our distrobution partner, The
                  Pharmacy Hub, ensuring a seamless billing process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="semaglutide" className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container gap-l left-justify">
          <div className="full-width space-between">
            <h1 className="header-m">Exclusive access to High-End Products</h1>
            <a href="#" className="link-block icon-button w-inline-block">
              <div className="button-m light">See All</div>
              <img
                src={require("../../assets/icons/next-1.png")}
                loading="lazy"
                alt=""
                className="tiny-icon"
              />
            </a>
          </div>
          <div className="slider-container">
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">1mg Semaglutide</div>
              <div className="div-block-17">
                <img
                  src={require("../../assets/images/product-images/Semaglutide12-5mg.png")}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-information"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">2.5mg Semaglutide</div>
              <div className="div-block-17">
                <img
                  src={require("../../assets/images/product-images/Semaglutide12-5mg.png")}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-information"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">5mg Semaglutide</div>
              <div className="div-block-17">
                <img
                  src={require("../../assets/images/product-images/Semaglutide12-5mg.png")}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-information"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">12.5mg Semaglutide</div>
              <div className="div-block-17">
                <img
                  src={require("../../assets/images/product-images/Semaglutide12-5mg.png")}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-information"
                />
              </div>
            </div>
          </div>
          <div className="slider-container">
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">10mg Tirzepatide</div>
              <div className="div-block-17">
                <img
                  src={require("../../assets/images/product-images/Semaglutide12-5mg.png")}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-information"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">30mg Tirzepatide</div>
              <div className="div-block-17">
                <img
                  src={require("../../assets/images/product-images/Semaglutide12-5mg.png")}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-information"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">60mg Tirzepatide</div>
              <div className="div-block-17">
                <img
                  src={require("../../assets/images/product-images/Semaglutide12-5mg.png")}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-information"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="apply" className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="form w-form">
            {queryParams.get("success") === "true" ? (
              <div
                // className="w-form-done"
                style={{
                  backgroundColor: "green",
                  width: "100%",
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <h4 style={{ color: "black", fontSize: 16, fontWeight: 400 }}>
                  Your form was successfully submitted, we will be in touch
                  soon!
                </h4>
              </div>
            ) : (
              <>
                <div className="header-m">Start your application here!</div>
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form"
                  data-wf-page-id="6703fd059851b7d46e8a08cb"
                  data-wf-element-id="89c99320-b25c-8e08-6050-501f9097ffbd"
                  style={{ width: "100%" }}
                  onSubmit={(e) => {
                    submitForm(e);
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <label for="name" className="field-label-2">
                      Practice Name
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name"
                      data-name="Name"
                      placeholder=""
                      type="text"
                      id="name"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          practiceName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <label for="name-5" className="field-label-2">
                      Contact Name
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name-5"
                      data-name="Name 5"
                      placeholder=""
                      type="text"
                      id="name-5"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          contactName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <label for="name-4" className="field-label-2">
                      Contact Phone Number
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name-4"
                      data-name="Name 4"
                      placeholder=""
                      type="text"
                      id="name-4"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          contactPhone: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <label for="name-3" className="field-label-2">
                      Contact Email
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name-3"
                      data-name="Name 3"
                      placeholder=""
                      type="email"
                      id="name-3"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          contactEmail: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <label for="name-2" className="field-label-2">
                      Do you currently prescribe GLP&#x27;s?
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      type="text"
                      id="name-2"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          current: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <input
                    type="submit"
                    data-wait="Please wait..."
                    className="button-primary-light "
                    id="submit"
                    value="Submit"
                  />
                </form>
              </>
            )}
          </div>
        </div>
      </section> */}
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-l full-width center">FAQ&#x27;S</div>
          <div className="faqs">
            <div className="faq-row">
              <div className="header-s">One All-In Cost</div>
              <p className="body-s">
                A prescription of Semaglutide comes in a multiple use vial.
                Price includes shipping, syringes, and alcohol prep pads.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Buy-and-Bill</div>
              <p className="body-s">
                The Semaglutide program is a buy-and-bill program for physician
                offices and medical spas. Providers prescribe Semaglutide for
                each patient, or they can bulk order for clinic use. Once the
                prescription/order is filled, a practice and/or medical spa will
                be billed for the prescription/order.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">The Pharmacy Hub</div>
              <p className="body-s">
                Members will be able to order product through our distribution
                partner, The Pharmacy Hub. The Pharmacy Hub will handle all
                product kit logistics, billing and shipping through their
                state-of-the-art distribution facilities. Although The Pharmacy
                Hub is a 503A pharmacy, they receive this manufactured
                Semaglutide product from an independent, FDA-approved
                manufacturer, who has 503B distributor licenses in over 20
                states. The Pharmacy Hub is LegitScript certified and
                Surescripts certified.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Bulk Orders</div>
              <p className="body-s">
                If you would like to place orders for clinic use, a Bulk Order
                is 25 vials. Current Bulk Order Delivery States: AZ, CO, CT, FL,
                GA, IL, IN, KS, KY, LA, MA, MS, NC, NJ, NV, OH, OK, OR, PA, SC,
                TN, TX, WI.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Shipping</div>
              <p className="body-s">
                The Pharmacy Hub offers 2nd Day UPS Shipping. There are no cold
                shipping requirements, however in warmer months, the pharmacy
                may use cold shipping out of an abundance of caution. If
                applicable to your order, this will increase the cost by $10. A
                provider can get product to a clinic or patient quickly. If a
                provider prescribes a product by 4:00PM EST Monday through
                Wednesday, a patient or provider&#x27;s facility will receive
                product in two (2) business days. Thursday and Friday
                prescriptions will be shipped Monday of the following week and
                delivered in two (2) business days. Weather and holidays can
                affect delivery times and dates. A practice/provider must be
                registered with an account before any prescriptions can be
                filled.
                <br />
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Setting Up an Account</div>
              <p className="body-s">
                Whether you are a Provider, Clinic, or medical spa, we require
                that you register an account by filling out an Onboarding Form.
                The Onboarding Form requires pertinent information, such as DEA
                number, NPI number and state licensure number. A completed
                Onboarding Form is required prior to prescribing. This is the
                only way you will be able to prescribe and order the products.
                Processing your account application may take up to 3 business
                days as information from the application needs to be verified.
                Once your application is processed, you will receive a welcome
                email from The Pharmacy Hub with your account and support
                information, as well as an instructional video for online
                ordering through their DispensePro site (if you do not have
                e-scribing capabilities through an EHR/EMR).
                <br />
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Billing &amp; Reporting</div>
              <p className="body-s">
                As previously mentioned, this is a Buy-and-Bill program. Your
                practice or medical spa will be responsible for payment of each
                prescription. As you generate and send a prescription, The
                Pharmacy Hub will process the credit card on file associated
                with your account once a prescription is received. As the charge
                on the card clears, the prescription immediately goes to
                fulfillment and is shipped.
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
