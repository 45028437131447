import axios from "axios";

export default function Events() {}

export async function GetEvents() {
  const res = await axios.get("/api/v2/Website/events/get-events");
  return res.data.data.items;
}

export async function GetEventById(id) {
  const res = await axios.get(
    `/api/v2/Website/events/get-event-details?_id=${id}`
  );
  return res.data.data;
}
