import { useState, useEffect } from "react";
import Navbar from "../../components/navigations";
import { Dense_Button_Light } from "../../components/buttons";
import EventCardType2 from "../../components/eventCardType2";
import axios from "axios";
import moment from "moment";
import Footer from "../../components/footer";
import FaqSection from "../../components/faqSection";
import IndutryPartnershipsSection from "../../components/indutry-partnerships-section";
import EventsListTag from "../../components/events-list-tag";

export default function SocietyEvents() {
  const eventType = "society";
  const [eventsList, setEventsList] = useState([]);
  const [filter, setFilter] = useState(eventType.toLocaleLowerCase());
  const [tagsCounts, setTagsCounts] = useState({});

  async function getEventsList() {
    axios.get("/api/v2/Website/events/get-events").then((response) => {
      setEventsList(response.data.items);
      let tags = {};
      response.data.items.map((event) => {
        if (tags[event.fields.eventType]) {
          tags[event.fields.eventType] += 1;
          tags["all"] += 1;
        } else {
          tags[event.fields.eventType] = 1;
          tags["all"] = 1;
        }
      });
      setTagsCounts(tags);
    });
  }

  useEffect(() => {
    getEventsList();
  }, []);

  const FAQs = [
    {
      question: "What do the CME courses cover?",
      answer:
        "Our CME courses cover a wide range of topics in aesthetic medicine, including injectables, laser treatments, skincare, and more. These courses are designed to help you stay up-to-date with the latest techniques and advancements in the field.",
    },
    {
      question: "Do i receieve course education materials?",
      answer:
        "Yes, all of our CME courses come with educational materials, including presentations, videos, and other resources to help you learn and retain the information. These materials are accessible online and can be reviewed at your own pace.",
    },
    {
      question: "Can i upgrade my membership?",
      answer:
        "Yes, you can upgrade your membership at any time by contacting our support team. We offer a range of membership options to suit your needs, including individual and group memberships.",
    },
    {
      question:
        "What differentiates MedSpa Network from other membership programs?",
      answer:
        "MedSpa Network is unique in that we offer a comprehensive range of resources, training, and community support to help you succeed in aesthetic medicine. Our membership program is designed to provide you with the tools and knowledge you need to excel in your career.",
    },
  ];

  return (
    <>
      <Navbar type="dark" />

      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light title-underline">Events</div>
          <div className="header-xxl pad-top-m">Join us at</div>
          <div className="header-xxl light pad-top-xs">
            National Society Events
          </div>
          <p className="body-l pad-top-m">
            Join us at our upcoming events to learn from industry experts,
            network with your peers, and stay up-to-date with the latest trends
            in aesthetic medicine.
          </p>

          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video w-video w-embed margin-top-l"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FX2rGgsqaq3A%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DX2rGgsqaq3A&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FX2rGgsqaq3A%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs light underline">Calendar</div>
          <div className="header-xl pad-top-l">Upcoming Events</div>
          <div className="events-list">
            {eventsList
              .filter((event) => {
                if (filter == "all") {
                  return true;
                } else {
                  return (
                    event.fields.eventType.toLowerCase() == filter.toLowerCase()
                  );
                }
              })
              .map((event, index) => {
                return (
                  <EventCardType2
                    key={index}
                    title={event.fields.eventTitle}
                    date={moment(event.fields.eventStartDate).format(
                      "DD MMM YYYY"
                    )}
                    location={event.fields.eventType}
                    link={"/masterclass/february2025"}
                    id={event.sys.id}
                    index={index}
                    type={event.fields.eventType}
                  />
                );
              })}

            {eventsList.filter((event) => {
              if (filter == "all") {
                return true;
              } else {
                return (
                  event.fields.eventType.toLowerCase() == filter.toLowerCase()
                );
              }
            }).length == 0 ? (
              <div className="header-l">
                We are sorry there are no events scheduled at this time.
              </div>
            ) : null}

            {eventsList.length > 5 ? (
              <div className="button m center">Load More</div>
            ) : null}
          </div>
        </div>
      </section>
      <IndutryPartnershipsSection
        backgroundColor={"lightgrey-background-section"}
      />
      <FaqSection backgroundColor={"white-background-section"} FAQList={FAQs} />
      <Footer />
    </>
  );
}
