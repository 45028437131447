"use client";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MobileNav from "./mobileNav";

export default function LoggedOutNav() {
  const [aboutDropDown, setaboutDropDown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function openAboutNav() {
    setaboutDropDown(true);
  }

  function closeAboutNav() {
    setaboutDropDown(false);
  }

  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar-relative w-nav"
    >
      {location.pathname == "/labRegistration" ||
      location.pathname == "/labregistration" ||
      location.pathname == "/vendorregistration" ||
      location.pathname == "/vendorRegistration" ? null : (
        <div className="div-block-127">
          <div className="text-block-54">
            Register for our two day Facial Rejuvenation Intensive
          </div>
          <a href="/labRegistration" className="button-13 w-button">
            Register Now
          </a>
        </div>
      )}
      <div className="div-block-183">
        <a
          href="https://www.facebook.com/medspanetwork/"
          className="w-inline-block"
        >
          <img
            src="/images/facebook.png"
            loading="lazy"
            alt=""
            className="image-34"
          />
        </a>
        <a
          href="https://www.instagram.com/medspanetwork/?ref=6bgtt2veydyv"
          className="w-inline-block"
        >
          <img
            src="/images/instagram.png"
            loading="lazy"
            alt=""
            className="image-34"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/medspa-network/"
          className="w-inline-block"
        >
          <img
            src="/images/linkedin.png"
            loading="lazy"
            alt=""
            className="image-34"
          />
        </a>
      </div>
      <div className="container w-container">
        <a href="/" className="w-nav-brand">
          <img
            src={"/images/MedSpaNetworkLogoMark.webp"}
            // srcSet="images/Artboard-24x-p-500.webp 500w, images/Artboard-24x-p-800.webp 800w, images/Artboard-24x-p-1080.webp 1080w, images/Artboard-24x-p-1600.webp 1600w, images/Artboard-24x-p-2000.webp 2000w, images/Artboard-24x-p-2600.webp 2600w, images/Artboard-24x-p-3200.webp 3200w"
            sizes="75px"
            alt=""
            className="menu-logo-mark"
          />
        </a>
        <nav role="navigation" className="nav-menu-2 w-nav-menu">
          <a href="/courses" className="nav-link w-nav-link">
            Courses
          </a>
          <a href="/events" className="nav-link w-nav-link">
            Events
          </a>
          <div
            onMouseEnter={() => openAboutNav()}
            onMouseLeave={() => closeAboutNav()}
            id="aboutDropDown"
            data-hover="false"
            data-delay="0"
            className="w-dropdown"
          >
            <div className="nav-dropdown w-dropdown-toggle">
              <div className="icon w-icon-dropdown-toggle"></div>
              <div className="nav-link-dropdown-text">About Us</div>
            </div>
            <nav
              className={
                aboutDropDown ? "w-dropdown-list w--open" : "w-dropdown-list"
              }
            >
              <a href="/aboutUs" className="dropdown-link w-dropdown-link">
                Our Team
              </a>
              <a
                href="/industry-partners"
                className="dropdown-link-2 w-dropdown-link"
              >
                Partnerships
              </a>
              <a href="/contact" className="dropdown-link-3 w-dropdown-link">
                Contact Us
              </a>
            </nav>
          </div>

          <button
            onClick={() => {
              navigate("/login", { state: { returnPage: location.pathname } });
            }}
            className="nav-link w-nav-link"
          >
            Login
          </button>
          <a href="/registration" className="nav-link w-nav-link">
            Join Us
          </a>
        </nav>
        <div className="mobileNav">
          <MobileNav />
        </div>
      </div>
    </div>
  );
}
