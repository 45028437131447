import { Dense_Button_Light, Medium_button_secondary_light } from "./buttons";

export default function Footer() {
  return (
    <section className="footer">
      <div className="w-layout-blockcontainer container">
        <div className="vertical">
          <div
            id="w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316a-6a1c3167"
            className="w-layout-layout footer-stack wf-layout-layout"
          >
            <div
              id="w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316b-6a1c3167"
              className="w-layout-cell"
            >
              <div
                id="w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316c-6a1c3167"
                className="w-layout-layout footer-link-stack wf-layout-layout"
              >
                <div
                  id="w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316d-6a1c3167"
                  className="w-layout-cell cell-4"
                >
                  <div className="navigation">Knowledge</div>
                  <a href="/events" className="button">
                    Events
                  </a>
                  <a href="/webinars" className="button">
                    Webinars
                  </a>
                  <a href="/online-courses" className="button">
                    Courses
                  </a>
                </div>
                <div
                  id="w-node-c55ab717-e0c6-e697-37ab-8fb76a1c3176-6a1c3167"
                  className="w-layout-cell cell-5"
                >
                  <div className="navigation">Network</div>
                  <a href="/membership" className="button">
                    Membership
                  </a>
                  <a href="/shop" className="button">
                    Shop
                  </a>
                  <a href="/industry-partners" className="button">
                    Industry Partners
                  </a>
                </div>
                <div
                  id="w-node-c55ab717-e0c6-e697-37ab-8fb76a1c317f-6a1c3167"
                  className="w-layout-cell cell-6"
                >
                  <div className="navigation">About</div>
                  <a href="/team" className="button">
                    Team
                  </a>
                  <a href="/contact" className="button">
                    Contact Us
                  </a>
                  <a href="/membership" className="button">
                    Membership
                    <br />
                  </a>
                </div>
              </div>
            </div>
            <div
              id="w-node-c55ab717-e0c6-e697-37ab-8fb76a1c3188-6a1c3167"
              className="w-layout-cell cell-3"
            >
              <Dense_Button_Light link="/login" text="Log in to Account" />

              <div className="footer-social-bar">
                <a
                  href="https://www.instagram.com/medspanetwork/"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={require("../../assets/logos/instagram-logo-1.png")}
                    loading="lazy"
                    alt=""
                    className="footer-social-icon"
                  />
                </a>
                <a
                  href="https://www.facebook.com/medspanetwork/"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={require("../../assets/logos/facebook-1.png")}
                    loading="lazy"
                    alt=""
                    className="footer-social-icon"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/medspa-network"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={require("../../assets/logos/linkedin.png")}
                    loading="lazy"
                    alt=""
                    className="footer-social-icon"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCLLWwCFZHtGF99XmxUF8leg"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={require("../../assets/logos/youtube-2.png")}
                    loading="lazy"
                    alt=""
                    className="footer-social-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <img
            src={require("../../assets/logos/whiteLogo.png")}
            loading="lazy"
            alt=""
            className="footer-logo"
          />
          <div className="footer-privacy-bar">
            <div className="privacy-links">
              <div className="button s footer-link">Terms of Service</div>
              <div className="button s footer-link">Privacy Policy</div>
            </div>
            <div>© 2024 MedSpa Network</div>
          </div>
        </div>
      </div>
    </section>
  );
}
