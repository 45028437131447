import { useEffect, useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-modal";
import { useTrainingContext } from "../../context/clinicalTrainingContext";
import "../css/medspa-network-v3.webflow.css";
import "../css/webflow.css";
import "../css/normalize.css";
import AgendaLineItem from "../components/agenda-line-item";
// import CountDownTimer from "../components/countdownTimer";
import TestimonialsSection from "../components/testimonials-section";
import Footer from "../components/footer";
import MasterclassStaffCard from "../components/masterclass-staff-card";
import TeamMemberCard from "../components/teamCard";

export default function Labs() {
  const { search } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const [labDetails, setLabDetails] = useState({
    bulletPoints: [],
    agenda: [],
    staff: [],
    vendors: [],
    description: "",
    packages: [],
  });
  const [agenda, setAgenda] = useState([]);

  const searchParams = new URLSearchParams(search);
  const searchCode = new URLSearchParams(search).get("referralCode");
  const affiliateParam = new URLSearchParams(search).get("affiliate");

  console.log(searchParams);

  const { choosePackage, addCoupons } = useTrainingContext();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [shouldGiveDiscount, setShouldGiveDiscount] = useState(false);
  const [modalVisable, setModalVisable] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    name: "Dr. Alan Durkin, MD",
    title: "Board Certified Plastic Surgeon",
    image: "Durkin_Square_1000x1000.webp",
    bio: [""],
  });

  const [userDetails, setUserDetails] = useState({
    referralCode: searchCode ? searchCode : "",
  });

  async function getInitalData() {
    axios
      .get(
        "/api/v2/website/events/get-lab-details?eventName=" +
          location.pathname.split("/")[2]
      )
      .then((res) => {
        setLabDetails(res.data.data);
        setAgenda(res.data.data.agenda.day1.agenda);
        addCoupons(res.data.data.discountCodes);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      setUserDetails({ ...userDetails, ip: res.data.ip });
    });

    getInitalData();

    if (localStorage.getItem("authToken")) {
      setIsLoggedIn(true);
      setShouldGiveDiscount(true);
    }
  }, []);

  function changeAgenda(day) {
    const elms = document.getElementsByClassName("box-wrapper");

    for (let i = 0; i < elms.length; i++) {
      elms[i].classList.add("loading");
    }

    if (day == 1) {
      document.getElementById("day1").classList.add("selected");
      document.getElementById("day2").classList.remove("selected");
      document.getElementById("day1").classList.remove("unselected");
      document.getElementById("day2").classList.add("unselected");
    } else {
      document.getElementById("day2").classList.add("selected");
      document.getElementById("day1").classList.remove("selected");
      document.getElementById("day2").classList.remove("unselected");
      document.getElementById("day1").classList.add("unselected");
    }
    setAgenda(labDetails.agenda[`day${day}`].agenda);
  }

  function openModal(e) {
    e.preventDefault();
    setModalVisable(true);
  }

  function closeModal() {
    setModalVisable(false);
  }

  useEffect(() => {
    const elms = document.getElementsByClassName("box-wrapper");

    for (let i = 0; i < elms.length; i++) {
      setTimeout(() => {
        elms[i].classList.remove("loading");
      }, 100 * i);
    }
  }, [agenda]);

  return (
    <div>
      <Modal
        style={{ zIndex: 999 }}
        ariaHideApp={false}
        isOpen={modalVisable}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="div-block-78" style={{ padding: 20 }}>
          <h1 className="heading-18">{modalInfo.name}</h1>
          {modalInfo.bio.map((paragraph, index) => {
            return (
              <p
                key={index}
                style={{ fontSize: 16, lineHeight: "24px", marginBottom: 16 }}
                className="sourceSans"
              >
                {paragraph}
              </p>
            );
          })}
        </div>
      </Modal>
      <nav
        id="nav"
        className="light-nav-bar blurred"
        style={{ position: "fixed" }}
      >
        <main className="w-layout-blockcontainer navbar-container ">
          <div className="div-block-33">
            <div className="navbar-logo-container">
              <img
                loading="lazy"
                src={require("../../assets/logos/Color=Light 1.png")}
                alt=""
                className="image-10"
                style={{ objectFit: "contain" }}
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
          </div>
          <div className="div-block-9">
            <div className="div-block-8" style={{ border: "none" }}>
              <a href="#agenda" className="light-nav-link">
                Agenda
              </a>
              <a href="#speakers" className="light-nav-link">
                Speakers
              </a>
              <a href="#venue" className="light-nav-link">
                Venue
              </a>
              <a href="#sponsors" className="light-nav-link">
                Sponsors
              </a>
              <a href="#contact" className="light-nav-link">
                Contact
              </a>
            </div>
            <a href="#packages" className="medium-button-accent">
              Register Now!
            </a>
          </div>
        </main>
      </nav>
      <div
        data-poster-url="../../assets/videos/Website-Hero-Video-BW-poster-00001.jpg"
        data-video-urls="../../assets/videos/Website-Hero-Video-BW-transcode.mp4,../../assets/videos/Website-Hero-Video-BW-transcode.webm"
        data-autoplay="true"
        data-loop="true"
        data-wf-ignore="true"
        className="background-video-masterclass w-background-video "
      >
        <video
          id="b8440dfd-c60b-24b0-c557-d4aaa325d202-video"
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          data-wf-ignore="true"
          data-object-fit="cover"
          style={{ height: "75vh" }}
        >
          <source
            src={require("../../assets/videos/Website-Hero-Video-BW-transcode.mp4")}
            data-wf-ignore="true"
          />
          <source
            src={require("../../assets/videos/Website-Hero-Video-BW-transcode.webm")}
            data-wf-ignore="true"
          />
        </video>
        <div className="w-layout-blockcontainer container vertical-nav ">
          <div className="breadcrumb-nav"></div>
          <div className="masterclass-title">
            <div className="header-xxl">Regenerative Aesthetics</div>
            <div className="display-xl">Masterclass</div>
          </div>
          <div className="masterclass-hero-details"></div>
        </div>
      </div>
      <section class="dark-background-section masterclass-floating-boxes">
        <div class="w-layout-blockcontainer container w-container">
          <div class="div-block-41">
            <div class="div-block-40">
              <div class="header-l">Brought to you by</div>
              <img
                src={require("../../assets/logos/whiteLogo.png")}
                loading="lazy"
                alt=""
                class="image-11"
              />
            </div>
            <div
              id="w-node-_63ab8c39-bdfa-ee00-93cc-4abbd4a82278-8d799a3f"
              class="w-layout-layout masterclass-floating-boxes wf-layout-layout"
            >
              <div
                id="w-node-_63ab8c39-bdfa-ee00-93cc-4abbd4a82279-8d799a3f"
                class="w-layout-cell"
              >
                <div class="floating-box">
                  <div class="header-xxl center">13.25</div>
                  <div class="header-s center">Credits</div>
                </div>
              </div>
              <div
                id="w-node-_63ab8c39-bdfa-ee00-93cc-4abbd4a8227a-8d799a3f"
                class="w-layout-cell"
              >
                <div class="floating-box">
                  <div class="header-xxl center">2</div>
                  <div class="header-s center">Days</div>
                </div>
              </div>
              <div
                id="w-node-_44cdffaf-697a-83e2-918a-4742141b79ef-8d799a3f"
                class="w-layout-cell"
              >
                <div class="floating-box">
                  <div class="header-xxl center">7</div>
                  <div class="header-s center">Speakers</div>
                </div>
              </div>
              <div
                id="w-node-_7b5bbe70-1f02-205d-f0f5-ac2a61efe784-8d799a3f"
                class="w-layout-cell"
              >
                <div class="floating-box">
                  <div class="header-xxl center">8</div>
                  <div class="header-s center">Live Models</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="light-background-section">
        <div className="w-layout-blockcontainer container">
          <p className="body-l center">
            Join us for an exclusive, two-day training event in beautiful Vero
            Beach, FL, designed for medical professionals looking to advance
            their expertise in injectables and facial anatomy. This
            comprehensive workshop will feature hands-on experience in
            state-of-the-art cadaver labs, where you'll gain invaluable insights
            into facial structures. Learn the latest techniques in advanced
            injectables through live demonstrations led by industry experts.
            Whether you’re looking to refine your skills or expand your
            knowledge, this event offers a unique blend of theoretical and
            practical learning to help you excel in your practice.
          </p>
        </div>
      </section>
      <section className="light-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="vertical">
            <h2 className="header-xl">Watch the Trailer</h2>
            <div className="div-block-22">
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="w-embed-youtubevideo youtube"
              >
                <iframe
                  src="https://www.youtube.com/embed/X2rGgsqaq3A?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                  frameBorder="0"
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "auto",
                  }}
                  allow="autoplay; encrypted-media"
                  allowFullScreen=""
                  title="Medspa Network training December 2023"
                ></iframe>
              </div>
            </div>
            <div className="div-block-23">
              <div
                id="w-node-_29aafdc6-6d51-98bc-9576-c5910d2f6a06-8d799a3f"
                className="w-layout-layout quick-stack-8 wf-layout-layout"
              >
                <div
                  id="w-node-_29aafdc6-6d51-98bc-9576-c5910d2f6a07-8d799a3f"
                  className="w-layout-cell"
                >
                  <p className="body-l">
                    Gain valuable insights from “over-the-shoulder” training
                    sessions with renowned experts like Dr. Alan Durkin, MD, who
                    will guide you through real-time injections and provide
                    in-depth educational support to enhance your skills.
                  </p>
                </div>
                <div
                  id="w-node-_29aafdc6-6d51-98bc-9576-c5910d2f6a08-8d799a3f"
                  className="w-layout-cell"
                >
                  <p className="body-l">
                    Engage in small group sessions designed to maximize
                    personalized learning, as you rotate through specialized
                    focus areas led by industry experts, with content tailored
                    to your level of experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-block-24"></div>
            <h2 className="header-xl">Key Takeaways</h2>
            <p className="body-l light">
              Gain hands-on experience in a real cadaver lab, providing you with
              an in-depth understanding of facial anatomy. This immersive
              approach allows you to visualize and practice key injection points
              in a safe, controlled environment, improving precision in your
              clinical practice.
            </p>

            <div
              id="w-node-c2786c19-6ee8-0e8b-cd96-62a0aba960c7-8d799a3f"
              className="w-layout-layout quick-stack-9 wf-layout-layout"
            >
              <div
                id="w-node-c2786c19-6ee8-0e8b-cd96-62a0aba960c8-8d799a3f"
                className="w-layout-cell"
              >
                <div className="single-column-component">
                  <h3 className="header-m dark-brown">Cadaver Lab</h3>
                  <p className="body-m">
                    Deepen your knowledge of facial anatomy, exploring the
                    critical structures involved in facial aesthetics.
                  </p>
                </div>
              </div>
              <div
                id="w-node-c2786c19-6ee8-0e8b-cd96-62a0aba960c9-8d799a3f"
                className="w-layout-cell"
              >
                <div className="single-column-component">
                  <h3 className="header-m  dark-brown">
                    Injection Proficiency
                  </h3>
                  <p className="body-m">
                    Master safe, patient-focused injection techniques to achieve
                    optimal outcomes.
                  </p>
                </div>
              </div>
              <div
                id="w-node-_5f6ac462-1a23-2fbb-2f6b-96852af28ab7-8d799a3f"
                className="w-layout-cell"
              >
                <div className="single-column-component">
                  <h3 className="header-m dark-brown">
                    Tailored Treatment Planning
                  </h3>
                  <p className="body-m">
                    Learn to assess patients individually and create
                    personalized treatment plans.
                  </p>
                </div>
              </div>
            </div>
            <div
              id="w-node-_01cbe693-7887-6686-a78e-267ceb8039c0-8d799a3f"
              className="w-layout-layout quick-stack-9 wf-layout-layout"
            >
              <div
                id="w-node-_01cbe693-7887-6686-a78e-267ceb8039c1-8d799a3f"
                className="w-layout-cell"
              >
                <div className="single-column-component">
                  <h3 className="header-m dark-brown">
                    Rejuvenation Techniques
                  </h3>
                  <p className="body-m">
                    Gain insights into achieving natural-looking, aesthetically
                    pleasing results.
                  </p>
                </div>
              </div>
              <div
                id="w-node-_01cbe693-7887-6686-a78e-267ceb8039c2-8d799a3f"
                className="w-layout-cell"
              >
                <div className="single-column-component">
                  <h3 className="header-m dark-brown">
                    Sales &amp; Marketing Strategies
                  </h3>
                  <p className="body-m">
                    Learn effective strategies from top medical spas to promote
                    facial aesthetic services.
                  </p>
                </div>
              </div>
              <div
                id="w-node-_01cbe693-7887-6686-a78e-267ceb8039c3-8d799a3f"
                className="w-layout-cell"
              >
                <div className="single-column-component">
                  <h3 className="header-m dark-brown">Networking</h3>
                  <p className="body-m">
                    Build collaborative relationships with peers and connect
                    with leading professionals in aesthetic medicine.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="speakers" className="blue-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="vertical">
            <div className="paragraph-section-right-pad">
              <h5 className="header-xs white">Meet the team</h5>
              <div className="paragraph-section-paragraph-copy">
                <h2 className="header-xl white">Speakers</h2>
                <p className="body-l white">
                  Our team comprises experienced medical and business
                  professionals who prioritize education, training, and patient
                  care. Our medical practitioners are board-certified experts in
                  medical aesthetics, and our business professionals excel at
                  running client-focused medspas.
                </p>
              </div>
            </div>
            <div
              className="staff-card-container"
              style={{ overflow: "scroll" }}
            >
              {labDetails.staff.map((staff, index) => {
                return (
                  <TeamMemberCard
                    key={index}
                    name={staff.name}
                    credential={staff.title}
                    image={"/image/" + staff.image}
                    socials={{
                      linkedin: "#",
                      instagram: "#",
                    }}
                    bio={staff.bio}
                  />
                );
                {
                  /* return <MasterclassStaffCard staff={staff} key={index} />; */
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <section id="agenda" className="light-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="vertical gap20">
            <h2 className="left-adjusted-heading">
              Event Schedule &amp; Agenda
            </h2>
            <div className="tag-bar">
              <button
                className="tag rounded transparent selected"
                id="day1"
                onClick={() => changeAgenda(1)}
              >
                <p className="paragraph-7">Fri FEB 21</p>
              </button>
              <button
                className="tag rounded transparent unselected"
                id="day2"
                onClick={() => changeAgenda(2)}
              >
                <p className="paragraph-7">SAT FEB 22</p>
              </button>
            </div>
            <div className="agenda-table">
              {agenda.map((item, index) => {
                {
                  return (
                    <AgendaLineItem item={item} key={index} index={index} />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>

      <section id="packages" className="dark-background-section">
        {/* <div className="masterclass-what-you-will-learn-section">
          <p className="masterclass-hero-paragraph">What you&#x27;ll learn</p>
          <div className="div-block-21">
            <div className="badge-dark small">
              <div className="badge-text-centered">Live INJECTION TRAINING</div>
            </div>
            <div className="badge-dark small">
              <div className="badge-text-centered">Cadaver Lab</div>
            </div>
            <div className="badge-dark small">
              <div className="badge-text-centered">Skills in Practice</div>
            </div>
            <div className="badge-dark small">
              <div className="badge-text-centered">Focused Learning Groups</div>
            </div>
          </div>
        </div> */}
        <div className="w-layout-blockcontainer container ">
          <div className="vertical">
            <h2 className="header-l">Select Your Training Package</h2>
            <h2 className="header-l light">
              That Best Suits Your Professional Goals and Expertise.
            </h2>
            <div className="pricing-card-container">
              {labDetails.packages.map((packageDetails, index) => {
                return (
                  <div className="pricing-card" key={index}>
                    <div className="pricing-card-top">
                      <div
                        className="pricing-card-text"
                        style={{ color: "black" }}
                      >
                        2-Day
                      </div>
                      <div
                        className="pricing-card-text"
                        style={{ color: "black" }}
                      >
                        {packageDetails.title}
                      </div>
                      <div
                        className="pricing-card-price"
                        style={{ color: "black" }}
                      >
                        {packageDetails.price.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 0,
                        })}
                      </div>

                      <div className="badge">
                        <img
                          src={require("../../assets/icons/calendar-2.png")}
                          loading="lazy"
                          alt=""
                          className="tag-icon"
                        />
                        <div className="tag-text" style={{ color: "black" }}>
                          Feb 21 2025
                        </div>
                      </div>
                    </div>
                    <div className="pricing-card-bottom">
                      <div>Includes</div>
                      {packageDetails.details.map((detail, index) => {
                        return (
                          <div
                            className="pricing-card-bottom-bullet-container"
                            key={index}
                          >
                            <img
                              src={require("../../assets/icons/next-1.png")}
                              loading="lazy"
                              alt=""
                              className="pricing-card-bullet-icon"
                            />
                            <div className="body-s">{detail}</div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      href="#"
                      className="button-dense-primary-dark margin-top-20 "
                      style={{ maxWidth: 250 }}
                      onClick={() => {
                        choosePackage({
                          title: packageDetails.title,
                          details: packageDetails.details,
                          price: packageDetails.price,
                          originalPrice: packageDetails.price,
                        });
                        navigate(`/masterclass-checkout${search}`, {
                          state: { ...labDetails },
                        });
                      }}
                    >
                      Select &amp; Register
                    </button>
                  </div>
                );
              })}
            </div>
            {/* <div className="pricing-card-container">
              <div className="pricing-card">
                <div className="pricing-card-top">
                  <div className="header-s">1-Day</div>
                  <div className="header-s">Clinical Intensive Training</div>
                  <div className="display-xl blue">$1,500</div>
                  <div className="badge">
                    <img
                      src={require("./assets/images/calendar-2.png")}
                      loading="lazy"
                      alt=""
                      className="tag-icon"
                    />
                    <div className="eyebrow s light">21-22 FEB 2025</div>
                  </div>
                </div>
                <div className="pricing-card-bottom">
                  <div className="body-s light">Includes</div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      src={require("./assets/images/next-1.png")}
                      loading="lazy"
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s light">CME/CE Credits</div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      src={require("./assets/images/next-1.png")}
                      loading="lazy"
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s light">Cadaver Lab</div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      src={require("./assets/images/next-1.png")}
                      loading="lazy"
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s light">
                      Filler and Toxin Didactic
                    </div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      src={require("./assets/images/next-1.png")}
                      loading="lazy"
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s light">
                      Certificate of Completion
                    </div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      src={require("./assets/images/next-1.png")}
                      loading="lazy"
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s light">Live Facelift</div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      src={require("./assets/images/next-1.png")}
                      loading="lazy"
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s light">Business Acumen</div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      src={require("./assets/images/next-1.png")}
                      loading="lazy"
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s light">Laser Training</div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      src={require("./assets/images/next-1.png")}
                      loading="lazy"
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s light">
                      Personalized Technology Training
                    </div>
                  </div>
                </div>
                <button
                  href="#"
                  className="white-fill-button margin-top-20"
                  onClick={() => {
                    choosePackage({
                      title: packageDetails.title,
                      details: packageDetails.details,
                      price: packageDetails.price,
                      originalPrice: packageDetails.price,
                    });
                    navigate(`/masterclass-checkout${search}`, {
                      state: { ...labDetails },
                    });
                  }}
                >
                  Select &amp; Register
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section id="venue" className="light-background-section">
        <div className="w-layout-blockcontainer container ">
          <h2 className="header-l">Event Location</h2>
          <div className="horizontal">
            <div className="masterclass-location-details-container">
              <div className="div-block-27">
                <div className="code-embed-2 w-embed w-iframe">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.3268133093743!2d-80.3665424238134!3d27.676292276200417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88de595360e69cf7%3A0x95491b5daf331fed!2sDr.%20Alan%20Durkin%20MD%20at%20Ocean%20Drive%20Plastic%20Surgery!5e0!3m2!1sen!2sus!4v1727195743937!5m2!1sen!2sus"
                    width="100%"
                    height="270"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="div-block-26">
                <div>
                  <h3 className="header-s">Training Location</h3>
                  <p className="body-s">
                    Ocean Drive Plastic Surgery
                    <br />
                    5070 Hwy A1A, Ste. A<br />
                    Vero Beach, FL, 32963
                  </p>
                </div>
                <a href="#" className="white-fill-button ">
                  Open in Maps
                </a>
              </div>
            </div>
            <div className="masterclass-location-details-container">
              <div className="div-block-27">
                <div className="code-embed-2 w-embed w-iframe">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10861.960640826448!2d-80.36630989987971!3d27.662251374198192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88de5930b0407c9f%3A0x800731989fde47d6!2sCosta%20d&#39;Este%20Beach%20Resort%20%26%20Spa!5e0!3m2!1sen!2sus!4v1727812489502!5m2!1sen!2sus"
                    width="100%"
                    height="270"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="div-block-26">
                <div>
                  <h3 className="header-s">Mixer Location</h3>
                  <p className="body-s">
                    Costa De Este
                    <br />
                    5070 Hwy A1A, Ste. A<br />
                    Vero Beach, FL, 32963
                  </p>
                </div>
                <a href="#" className="white-fill-button ">
                  Open in Maps
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </div>
  );
}
