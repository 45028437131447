export default function Agenda() {
  return (
    <>
      <object
        data="/downloads/decemberAgenda.pdf"
        type="application/pdf"
        style={{ height: "100vh", width: "100vw" }}
      ></object>
    </>
  );
}
