import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { ShoppingCart } from "../components/shoppingCart";
import { UseLocaleStorage } from "../hooks/useLocalStorage";
import { useEffect } from "react";
import axios from "axios";

const ShoppingCartContext = createContext({});

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({ children }) {
  const [cartItems, setCartItems] = UseLocaleStorage(
    "MSN-WEBSITE-ShoppingCart",
    []
  );
  const [products, setProducts] = UseLocaleStorage(
    "MSN-WEBSITE-ProductsList",
    []
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    axios
      .get("/api/products/all")
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const cartQuantity = useMemo(() => {
    return cartItems.reduce((quantity, item) => item.quantity + quantity, 0);
  }, [cartItems]);

  const getItemQuantity = useCallback(
    (id) => {
      return cartItems.find((item) => item.id === id)?.quantity || 0;
    },
    [cartItems]
  );

  const increaseQuantity = useCallback(
    (id) => {
      setCartItems((currentItems) => {
        if (currentItems.find((item) => item.id === id)?.quantity == null) {
          return [...currentItems, { id, quantity: 1 }];
        } else {
          return currentItems.map((item) => {
            if (item.id === id) {
              return { ...item, quantity: item.quantity + 1 };
            } else {
              return item;
            }
          });
        }
      });
    },
    [setCartItems]
  );

  function decreaseQuantity(id) {
    setCartItems((currentItems) => {
      if (currentItems.find((item) => item.id === id)?.quantity === 1) {
        return currentItems.filter((item) => (item.id === id) !== id);
      } else {
        return currentItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
      }
    });
  }

  function removeFromCart(id) {
    setCartItems((currentItems) => {
      const newItems = currentItems.filter((item) => item.id !== id);
      if (newItems.length == 0) {
        setIsOpen(false);
      }
      return newItems;
    });
  }

  function openCart() {
    setIsOpen(true);
  }

  function closeCart() {
    setIsOpen(false);
  }

  function setProductList(data) {
    setProducts(data);
  }

  function resetCart() {
    setCartItems([]);
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        increaseQuantity,
        decreaseQuantity,
        removeFromCart,
        cartQuantity,
        openCart,
        closeCart,
        cartItems,
        setProductList,
        products,
        resetCart,
      }}
    >
      <ShoppingCart isOpen={isOpen} />
      {children}
    </ShoppingCartContext.Provider>
  );
}
