import moment from "moment";

export default function EventCard({ props }) {
  console.log(props);
  return (
    <div
      className="event-card"
      style={{ minWidth: 500, minHeight: 600, maxWidth: 500, maxHeight: 600 }}
    >
      <img
        src={props.fields.bannerImage.fields.file.url}
        loading="lazy"
        alt=""
        className="image-8"
      />
      <div className="virtual-event-details-container">
        <div className="virtual-event-header">
          <div
            className="virtual-event-date"
            style={{ minWidth: 100, minHeight: 100 }}
          >
            <div className="virtual-event-date-month">
              {moment(props.fields.eventStartDate).format("MMM")}
            </div>
            <div className="virtual-event-date-day">
              {" "}
              {moment(props.fields.eventStartDate).format("DD")}
            </div>
          </div>
          <h1 className="heading-6">
            {props.fields.eventTitle.slice(0, 30) + "..."}
          </h1>
        </div>
        <p>{props.fields.eventDescription.slice(0, 150) + "..."}</p>
        <a
          href={"/virtualEvent/" + props.sys.id}
          className="nav-button-fill anim300 pink-fill w-button"
        >
          SEE Event details
        </a>
      </div>
    </div>
  );
}
