import axios from "axios";
const userData = localStorage.getItem("user");
const user = JSON.parse(userData);

export default function Members() {}

export async function CreateAccount(params) {
  try {
    const res = await axios.post(
      "https://www.medspanetwork.com/api/v2/Website/members/create-account",
      {
        ...params,
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function GetCourses() {
  const res = await axios.get("/api/v2/Website/course/get-all-courses");
  return res.data.data;
}

export async function RefreshUserData() {
  const token = localStorage.getItem("authToken");

  const res = await axios
    .get(`/api/v2/Website/members/get-certificates`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { _id: user._id },
    })
    .catch(() => {});
  return res.data;
}

export async function UpdateUserData(userData) {
  const token = localStorage.getItem("authToken");

  console.log("updating user data");
  try {
    const res = await axios.patch(
      "/api/v2/Website/members/update-user",
      {
        _id: user._id,
        ...userData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function UpdateUseCompanyInformation(companyData) {
  const token = localStorage.getItem("authToken");

  console.log("updating user data");
  try {
    const res = await axios.patch(
      "/api/v2/Website/members/update-user-company-information",
      {
        _id: user._id,
        ...companyData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function CancelMembership() {
  const token = localStorage.getItem("authToken");

  console.log("Cancelling Membership");
  try {
    const res = await axios.post(
      "/api/v2/Website/members/cancel",
      {
        _id: user._id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    return res.data.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
