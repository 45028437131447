import axios from "axios";
import Footer from "../components/footer";
import NavBar from "../components/navBar";
import PartnerBar from "../components/partnerBar";
import EventCard from "./components/eventCard";
import { useEffect, useState } from "react";

export default function VirtualEvents() {
  const [eventsList, setEventsList] = useState([]);

  useEffect(() => {
    axios.get("/api/contentful/get-events").then((res) => {
      console.log(res.data);
      setEventsList(res.data.items);
    });
  }, []);

  const events = eventsList
    .filter((a) => (a.fields.eventType == "Virtual" ? a : null))
    .sort((a, b) =>
      a.fields.eventStartDate > b.fields.eventStartDate ? 1 : -1
    )
    .map((event, index) => {
      return <EventCard key={index} props={event} />;
    });

  return (
    <>
      <section className="section-2 virtual-event-hero">
        <NavBar />
        <div className="div-block-13">
          <h1 className="hero-heading">Virtual Events</h1>
        </div>
      </section>
      <section className="two-column-section">
        <div className="half-block">
          <div className="half-paragraph-container">
            <h3 className="heading-2">
              Discover the convenience of MedSpa Network&#x27;s virtual
              professional development courses.
            </h3>
            <p className="bold-paragraph">
              As a member, you gain exclusive access to a wealth of training
              resources, including on-demand sessions and live webinars.
            </p>
            <p className="paragraph">
              Stay at the forefront of the industry with our accessible and
              engaging virtual events, tailored to enhance your expertise and
              network from the comfort of your own space.
            </p>
          </div>
        </div>
        <div className="half-block">
          <img
            src="images/Rectangle-4-7.png"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 37vw"
            setSet="images/Rectangle-4-7-p-500.png 500w, images/Rectangle-4-7.png 765w"
            alt=""
            className="large-image"
          />
        </div>
      </section>
      <section className="section virtual-events-cards">
        <div className="virtual-events-container">{events}</div>
      </section>
      <section className="section members-login-section">
        <h1 className="members-login-heading">Members</h1>
        <div className="text-block-5">
          Login to your account to access On Demand content
          <br />
          and to register for upcoming webinars.
        </div>
        <a href="#" className="nav-button-stroke">
          login
        </a>
      </section>
      <PartnerBar />
      <Footer />
    </>
  );
}
