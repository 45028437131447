import { useState, useEffect } from "react";
import Navbar from "../../components/navigations";
import {
  Dense_button_dark,
  Dense_Button_Light,
} from "../../components/buttons";
import EventCardType2 from "../../components/eventCardType2";
import axios from "axios";
import moment from "moment";
import Footer from "../../components/footer";
import FaqSection from "../../components/faqSection";
import IndutryPartnershipsSection from "../../components/indutry-partnerships-section";
import EventCardType3 from "../../components/eventCardType3";
import EventCardType2Light from "../../components/eventCardType2Light";
import StaffCard from "../../components/staffCard";

export default function FillerFundamentals() {
  const [eventsList, setEventsList] = useState([]);
  const [filter, setFilter] = useState("");

  async function getEventsList() {
    axios.get("/api/v2/Website/events/get-events").then((response) => {
      console.log(response.data.items);
      setEventsList(response.data.items);
    });
  }

  useEffect(() => {
    getEventsList();
  }, []);

  const agenda = [
    {
      title: "Review facial muscle anatomy upper face",
      length: "15 min",
    },
    {
      title: "Review neuromodulators, dilutions, and MOA",
      length: "15 min",
    },
    {
      title: "Brow, brow lift techniques/patterns/dosages",
      length: "15 min",
    },
    {
      title: "Break",
      length: "15 min",
    },
    {
      title: "Demo facial assessment",
      length: "15 min",
    },
    {
      title: "Hand on injections",
      length: "2 Hours",
    },
  ];

  const FAQs = [
    {
      question: "What do the CME courses cover?",
      answer:
        "Our CME courses cover a wide range of topics in aesthetic medicine, including injectables, laser treatments, skincare, and more. These courses are designed to help you stay up-to-date with the latest techniques and advancements in the field.",
    },
    {
      question: "Do i receieve course education materials?",
      answer:
        "Yes, all of our CME courses come with educational materials, including presentations, videos, and other resources to help you learn and retain the information. These materials are accessible online and can be reviewed at your own pace.",
    },
    {
      question: "Can i upgrade my membership?",
      answer:
        "Yes, you can upgrade your membership at any time by contacting our support team. We offer a range of membership options to suit your needs, including individual and group memberships.",
    },
    {
      question:
        "What differentiates MedSpa Network from other membership programs?",
      answer:
        "MedSpa Network is unique in that we offer a comprehensive range of resources, training, and community support to help you succeed in aesthetic medicine. Our membership program is designed to provide you with the tools and knowledge you need to excel in your career.",
    },
  ];

  return (
    <>
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container w-container">
          <div className="header-xs light center underline">
            In-Person Learning
          </div>
          <div className="header-xxl light pad-top-m">4 Hour Class</div>
          <div className="header-xxl">Filler fundamentals</div>
          <p className="body-l center pad-top-m">
            Course designed specifically for healthcare professionals looking to
            deepen their understanding of hyaluronic acid (HA) dermal filler
            therapy. This intensive class will cover the essential principles of
            facial filler use, focusing on safety, anatomy, and the rheology of
            HA fillers. Through a combination of expert-led demonstrations and
            hands-on injection practice, you'll gain the confidence and
            competence needed to deliver superior aesthetic results for your
            patients.
          </p>
          <Dense_Button_Light
            style={{ marginTop: 32 }}
            text="Book Now for $995"
            link="/in-person-learning/book"
            state={{
              title: "Filler fundamentals",
              price: 995,
            }}
          />
        </div>

        <div
          style={{ paddingTop: "56.17021276595745%" }}
          className="full-video w-video w-embed margin-top-l"
        >
          <iframe
            className="embedly-embed"
            src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FX2rGgsqaq3A%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DX2rGgsqaq3A&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FX2rGgsqaq3A%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
            width="940"
            height="528"
            scrolling="no"
            allowFullScreen=""
            title="Medspa Network training December 2023"
          ></iframe>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container">
          <div className="header-xl pad-top-l">Course Agenda</div>
          <div className="class-list pad-top-m">
            {agenda.map((item, index) => {
              return (
                <EventCardType3
                  key={index}
                  title={item.title}
                  length={item.length}
                  link={"/in-person-learning/book"}
                  state={{
                    title: "Filler fundamentals",
                    price: 995,
                  }}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xl">Watch the Trailer</div>
          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video margin-top-l w-video w-embed"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FX2rGgsqaq3A%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DX2rGgsqaq3A&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FX2rGgsqaq3A%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div>
          <p className="body-l pad-top-m thin">
            Get a sneak peek into the &quot;Filler Fundamentals&quot; course by
            watching our short trailer. See firsthand the value of this training
            as our instructors guide you through key techniques and insights
            that will elevate your practice.
          </p>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xl">Key</div>
          <div className="header-xl light">Takeaways</div>
          <p className="body-l pad-top-m">
            Licensed practitioners in Florida will have the unique opportunity
            to perform live injections under expert supervision, with the option
            to bring their own models or use those provided by the event,
            ensuring practical application of newly acquired skills.
          </p>
          <div
            id="w-node-_16e638ac-8f60-6cc4-07e2-8d2c832ac2a2-a3dbe62f"
            className="w-layout-layout full-width-stack pad-top-l wf-layout-layout"
          >
            <div
              id="w-node-_0fed9320-dc3b-913b-904f-8fed217ab4cd-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Cadaver Lab</div>
              <p className="body-m pad-top-m">
                Deepen your knowledge of facial anatomy, exploring the critical
                structures involved in facial aesthetics.
              </p>
            </div>
            <div
              id="w-node-_0fed9320-dc3b-913b-904f-8fed217ab4d4-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Injection Proficiency</div>
              <p className="body-m pad-top-m">
                Master safe, patient-focused injection techniques to achieve
                optimal outcomes.
              </p>
            </div>
            <div
              id="w-node-_0fed9320-dc3b-913b-904f-8fed217ab4db-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Tailored Treatment Planning</div>
              <p className="body-m pad-top-m">
                Learn to assess patients individually and create personalized
                treatment plans.
              </p>
            </div>
            <div
              id="w-node-_4530a525-62e4-afc7-69e6-180cce47c3d3-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Rejuvenation Techniques</div>
              <p className="body-m pad-top-m">
                Gain insights into achieving natural-looking, aesthetically
                pleasing results.
              </p>
            </div>
            <div
              id="w-node-_070794e6-4972-9052-7013-d3c2acad4519-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Sales &amp; Marketing Strategies</div>
              <p className="body-m pad-top-m">
                Learn effective strategies from top medical spas to promote
                facial aesthetic services.
              </p>
            </div>
            <div
              id="w-node-e035fde8-c57a-faf5-538b-3f3a7bbf7e10-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Networking</div>
              <p className="body-m pad-top-m">
                Build collaborative relationships with peers and connect with
                leading professionals in aesthetic medicine.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xl">In-Person Training Available Dates</div>
          <div className="events-list pad-topm">
            <EventCardType2Light
              title="Filler Fundamentals"
              date="21-22 FEB 2025"
              link="/in-person-learning/book"
              state={{ title: "Filler fundamentals", price: 995 }}
            />
            <EventCardType2Light
              title="Filler Fundamentals"
              date="21-22 FEB 2025"
              link="/in-person-learning/book"
              state={{ title: "Filler fundamentals", price: 995 }}
            />
            <EventCardType2Light
              title="Filler Fundamentals"
              date="21-22 FEB 2025"
              link="/in-person-learning/book"
              state={{ title: "Filler fundamentals", price: 995 }}
            />
            <EventCardType2Light
              title="Filler Fundamentals"
              date="21-22 FEB 2025"
              link="/in-person-learning/book"
              state={{ title: "Filler fundamentals", price: 995 }}
            />
          </div>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify">
          <div className="header-xs title-underline">Meet the Team</div>
          <div className="header-xl pad-top-l">Speakers</div>
          <p className="body-l pad-top-m">
            Our team comprises experienced medical and business professionals
            who prioritize education, training, and patient care. Our medical
            practitioners are board-certified experts in medical aesthetics, and
            our business professionals excel at running client-focused medspas.
          </p>
          <div className="staff-card-container">
            <StaffCard
              name="Dr. Alan Durkin, M.D., M.S."
              title="Plastic Surgeon"
              bio="Text"
              image={require("../../../assets/images/staff/Photo.png")}
              instagram="https://www.instagram.com/"
              linkedin="https://www.linkedin.com/"
            />
            <StaffCard
              name="Stephanie Beiber, APRN"
              title="Master Injector"
              bio="Text"
              image={require("../../../assets/images/staff/Photo.png")}
              instagram="https://www.instagram.com/"
              linkedin="https://www.linkedin.com/"
            />
          </div>
        </div>
      </section>
      <FaqSection backgroundColor={"white-background-section"} FAQList={FAQs} />
      <Footer />
    </>
  );
}
