import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";

export default function MobileNav() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (navbarOpen && ref.current && !ref.current.contains(event.target)) {
        setNavbarOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
    };
  }, [navbarOpen]);

  return (
    <nav className="navbar" ref={ref}>
      {navbarOpen ? (
        <MdClose
          onClick={() => setNavbarOpen((prev) => !prev)}
          style={{ width: "32px", height: "32px" }}
        />
      ) : (
        <FiMenu
          onClick={() => setNavbarOpen((prev) => !prev)}
          style={{
            width: "32px",
            height: "32px",
          }}
        />
      )}
      <ul className={`menu-nav${navbarOpen ? " show-menu" : ""}`}>
        <a
          onClick={() => setNavbarOpen(false)}
          href="/courses"
          className="nav-link w-nav-link"
        >
          Courses
        </a>
        <a
          onClick={() => setNavbarOpen(false)}
          href="/events"
          className="nav-link w-nav-link"
        >
          Events
        </a>
        <a
          onClick={() => setNavbarOpen(false)}
          href="/contact"
          className="nav-link w-nav-link"
        >
          Contact Us
        </a>

        <a
          onClick={() => setNavbarOpen(false)}
          href="/aboutUs"
          className="nav-link w-nav-link"
        >
          Our Team
        </a>

        <a
          onClick={() => setNavbarOpen(false)}
          href="/industry-partners"
          className="nav-link w-nav-link"
        >
          Partnerships
        </a>

        <button
          onClick={() => {
            navigate("/login", { state: { returnPage: location.pathname } });
          }}
          className="nav-link w-nav-link"
        >
          Login
        </button>
        <a href="/registration" className="nav-link w-nav-link">
          Register
        </a>
      </ul>
    </nav>
  );
}
