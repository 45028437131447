import DarkStaticNavBar from "../components/darkStaticNavBar";
import Footer from "../components/footer";

export default function ThankYou() {
  return (
    <>
      <DarkStaticNavBar />
      <div className="section-21">
        <h1 className="congrats-heading">CONGRATS</h1>
        <h1 className="thank-you-sub-text">
          Your Event Ticket has been purchased
        </h1>
        <h1 className="thank-you-paragraph">
          We cant wait to see you! Keep an eye on your email, we just sent you a
          receipt and some more information. We have also added you to our
          mailing list for future events.
        </h1>
        <div className="text-block-42">Share this event</div>
        <div className="div-block-97">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://www.medspanetwork.com/cadaverRegistration"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/63d930ebaa540c85e38b7f68/6414a7b697d7282457bc6032_facebook.png"
              loading="lazy"
              alt=""
              className="share-button"
            />
          </a>
          <a
            href="https://twitter.com/share?text=twitter&amp;url=https://www.medspanetwork.com/cadaverRegistration"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/63d930ebaa540c85e38b7f68/6414a800049e1810746f836e_twitter.png"
              loading="lazy"
              alt=""
              className="share-button"
            />
          </a>
          <a
            href="https://www.linkedin.com/sharing/share-offsite/?&amp;url=https://www.medspanetwork.com/cadaverRegistration"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/63d930ebaa540c85e38b7f68/6414a7bddf53aae47c30496d_linkedin.png"
              loading="lazy"
              alt=""
              className="share-button"
            />
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
}
