import React from "react";
import { Link } from "react-router-dom";

export function Dense_Button_Light({ link, text, state }) {
  return (
    <Link
      to={link}
      state={state}
      className="button-dense-primary-light "
      style={{ textDecoration: "none", cursor: "pointer" }}
    >
      {text}
    </Link>
  );
}

export function Dense_button_dark({ link, text, state }) {
  return (
    <Link
      to={link}
      state={state}
      className="button-dense-primary-dark "
      style={{ textDecoration: "none", cursor: "pointer" }}
    >
      {text}
    </Link>
  );
}

export function Primary_button_dark({ link, text, state }) {
  return (
    <Link
      to={link}
      state={state}
      className="button-primary-dark "
      style={{ textDecoration: "none", cursor: "pointer" }}
    >
      {text}
    </Link>
  );
}

export function Primary_button_light({ link, text, state }) {
  return (
    <Link
      to={link}
      state={state}
      className="button-primary-light "
      style={{ textDecoration: "none", cursor: "pointer" }}
    >
      {text}
    </Link>
  );
}

export function Medium_button_secondary_light({ link, text, state }) {
  return (
    <Link
      to={link}
      state={state}
      className="medium-button-secondary-light "
      style={{ textDecoration: "none", cursor: "pointer", textAlign: "center" }}
    >
      {text}
    </Link>
  );
}

export function Medium_button_secondary_dark({ link, text, state }) {
  return (
    <Link
      to={link}
      state={state}
      className="medium-button-secondary-dark "
      style={{ textDecoration: "none", cursor: "pointer" }}
    >
      {text}
    </Link>
  );
}
