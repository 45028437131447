import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function TempPassword() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const [password, setPassword] = useState({
    password: "",
    passwordVerify: "",
  });
  const [requirements, setRequirements] = useState({
    eightChars: false,
    uppercaseLetter: false,
    lowercaseLetter: false,
    oneNumber: false,
  });
  let url;

  function setNewPassword(e) {
    const tempPassword = e.target.value;
    console.log(e.target);

    if ((e.target.name = "password")) {
      if (tempPassword.length >= 8 && requirements.eightChars === false) {
        setRequirements((prevState) => ({ ...prevState, eightChars: true }));
      } else if (tempPassword.length < 8) {
        setRequirements({ ...requirements, eightChars: false });
      }

      if (
        /[A-Z]/.test(tempPassword) &&
        requirements.uppercaseLetter === false
      ) {
        setRequirements({ ...requirements, uppercaseLetter: true });
      } else if (
        requirements.uppercaseLetter &&
        /[A-Z]/.test(tempPassword) === false
      ) {
        setRequirements({ ...requirements, uppercaseLetter: false });
      }

      if (
        /[a-z]/.test(tempPassword) &&
        requirements.lowercaseLetter === false
      ) {
        setRequirements({ ...requirements, lowercaseLetter: true });
      } else if (
        requirements.lowercaseLetter &&
        /[a-z]/.test(tempPassword) === false
      ) {
        setRequirements({ ...requirements, lowercaseLetter: false });
      }

      if (/\d/.test(tempPassword) && requirements.oneNumber === false) {
        setRequirements({ ...requirements, oneNumber: true });
      } else if (requirements.oneNumber && /\d/.test(tempPassword) === false) {
        setRequirements({ ...requirements, oneNumber: false });
      }
    }

    setPassword({
      ...password,
      [e.target.id]: tempPassword,
    });
  }

  function sendNewPassword(e) {
    e.preventDefault();
    axios
      .post(
        "/api/employee/changeTempPassword?" +
          new URLSearchParams({
            _id: localStorage.getItem("authToken"),
            newPassword: password.password,
          })
      )
      .then((res) => {
        if (res.data.ok === true) {
          navigate("/");
        } else {
          setErrorMessage(res.data.message);
        }
      });
  }

  console.log(password);

  return (
    <div className="login-section wf-section">
      <div className="html-embed w-embed">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#111f2d"
            d="M28.6,-33.2C42,-29.6,61.1,-27.9,70.5,-18.4C79.8,-8.9,79.4,8.4,67.7,15.1C56,21.8,33.2,18,20,27.1C6.9,36.2,3.4,58.2,-4.8,64.9C-13.1,71.5,-26.2,62.9,-40.3,54C-54.3,45.2,-69.2,36.3,-78,22.2C-86.9,8.2,-89.7,-10.9,-83.7,-26.5C-77.7,-42.1,-62.9,-54.2,-47.5,-57.1C-32.1,-60.1,-16,-53.9,-4.2,-48.1C7.6,-42.3,15.2,-36.8,28.6,-33.2Z"
            transform="translate(100 100)"
          ></path>
        </svg>
      </div>
      <div className="div-block-20">
        <div className="div-block-19">
          <img
            src="/images/MSN_Colored.svg"
            loading="lazy"
            sizes="(max-width: 479px) 250px, 450px"
            srcSet="images/Side-by-Side-p-500.webp 500w, images/Side-by-Side-p-800.webp 800w, images/Side-by-Side-p-1080.webp 1080w, images/Side-by-Side-p-1600.webp 1600w, images/MSN_Colored.svg 2712w"
            alt=""
            className="login-logo"
          />
          <div className="login-hero-text">
            Lets change that temporary password
          </div>
          <div className="login-card w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form-6"
              onSubmit={(e) => {
                sendNewPassword(e);
              }}
            >
              {errorMessage}
              <label htmlFor="password">New Password</label>
              <input
                type="text"
                className="w-input"
                maxLength="256"
                name="password"
                data-name="password"
                placeholder=""
                id="password"
                required={true}
                onChange={(e) => {
                  setNewPassword(e);
                }}
              />
              <label htmlFor="passwordVerify">Verify New Password</label>
              <input
                type="text"
                className="w-input"
                maxLength="256"
                name={"passwordVerify"}
                data-name="passwordVerify"
                placeholder=""
                id="passwordVerify"
                required={true}
                onChange={(e) => {
                  setNewPassword(e);
                }}
              />

              <div className="div-block-87">
                <img
                  src={
                    requirements.eightChars
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>8 Characters or more</div>
              </div>
              <div className="div-block-87">
                <img
                  src={
                    requirements.uppercaseLetter
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>An Uppercase letter</div>
              </div>
              <div className="div-block-87">
                <img
                  src={
                    requirements.lowercaseLetter
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>A Lowercase Letter</div>
              </div>
              <div className="div-block-87">
                <img
                  src={
                    requirements.oneNumber
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>Atleast one number</div>
              </div>
              <div className="div-block-87">
                <img
                  src={
                    password.password === password.passwordVerify &&
                    password.password !== ""
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>Passwords Match</div>
              </div>

              {requirements.eightChars &&
              requirements.uppercaseLetter &&
              requirements.lowercaseLetter &&
              requirements.oneNumber &&
              password.password === password.passwordVerify ? (
                <input
                  type="submit"
                  value="Update Password"
                  data-wait="Please wait..."
                  className="login-button w-button"
                />
              ) : null}
              <div className="container-2 w-container"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
