import Footer from "../components/footer";
import DarkStaticNavBar from "../components/darkStaticNavBar";
import { useState, useEffect } from "react";
import { useMatch, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import tz from "moment-timezone";
import axios from "axios";
import ReactGA from "react-ga4";

export default function VirtualEvent() {
  const match = useMatch("/virtualEvent/:id");
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState({
    eventPresenters: [],
    presenters: [],
    shortDescriptions: [],
  });
  const [formData, setFormData] = useState({});
  const [processing, setProcessing] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let url;

  useEffect(() => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      setFormData({ ...formData, ip: res.data.ip });
    });
    axios
      .get("/api/v2/Website/events/get-event-details?_id=" + match.params.id)
      .then((res) => {
        if (res.data.status == "success") {
          setEventDetails({ ...eventDetails, ...res.data.data });
        } else {
          console.log(res.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match.params.id]);

  // Form Submission
  ////////////////////////////////////////////////////
  async function submitForm(e) {
    e.preventDefault();
    // setProcessing(true);
    console.log("register");
    axios
      .post("/api/v2/Website/events/zoom-event-registration", {
        formData: formData,
        eventData: eventDetails,
      })
      .then((res) => {
        if (res.data.status == "success") {
          console.log(res.data);
          // Send a sign up event hit to Google Analytics
          ReactGA.send({ hitType: "sign_up", method: location.search });

          // Then forward user to the Event Thank You Page
          ////////////////////////////////////////////////////
          navigate("/eventThankYou", {
            state: {
              formData: formData,
              eventDetails: eventDetails,
              pathname: location.pathname,
            },
          });
        } else {
          console.log(res.data.message);
        }
      });
  }

  return (
    <>
      {loading ? null : (
        <>
          <DarkStaticNavBar />
          <div
            className="section-19 wf-section"
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(3, 23, 38, 0.67), rgba(3, 23, 38, 0.67)), url(${eventDetails.bannerImage.fields.file.url})`,
              objectFit: "cover",
            }}
          >
            <div className="columns-6 w-row" style={{ width: "100%" }}>
              <div className="column-7 w-col w-col-6">
                <div className="div-block-110">
                  <div className="event-cost-icon-container">
                    <img
                      src="/images/price-tag.webp"
                      loading="lazy"
                      alt=""
                      className="image-18"
                    />
                  </div>
                  <div className="event-icon-text">FREE</div>
                </div>
                <div className="div-block-112">
                  <div className="div-block-111">
                    <div className="event-date-icon-container">
                      <img
                        src="/images/calendar.webp"
                        loading="lazy"
                        alt=""
                        className="image-18"
                      />
                    </div>
                    <div className="event-icon-text">
                      {moment(eventDetails.eventStartDate).format(
                        "dddd, MMMM Do YYYY"
                      )}
                    </div>
                  </div>
                  <div className="div-block-111">
                    <div className="event-time-icon-container">
                      <img
                        src="/images/time.webp"
                        loading="lazy"
                        alt=""
                        className="image-18"
                      />
                    </div>
                    <div className="event-icon-text">
                      {moment(eventDetails.eventStartDate)
                        .tz("America/New_York")
                        .format("h:mm a z")}
                    </div>
                  </div>
                </div>
                <h1 className="heading-19">{eventDetails.eventTitle}</h1>
              </div>
              <div
                className="column-8 w-col w-col-6"
                style={{ backgroundColor: "white" }}
              >
                <h1 className="heading-28">
                  Complete Your
                  <br />
                  Registration
                </h1>
                <div className="form-block-3 w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="form-5 webinarSignupPage"
                    onSubmit={(e) => {
                      submitForm(e);
                    }}
                  >
                    <label htmlFor="firstName">First Name*</label>
                    <input
                      type="text"
                      className="blue-text-field w-input"
                      maxLength="64"
                      name="firstName"
                      data-name="firstName"
                      placeholder="First Name"
                      id="first_name"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.id]: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="lastName">Last Name*</label>
                    <input
                      type="text"
                      className="blue-text-field w-input"
                      maxLength="64"
                      name="lastName"
                      data-name="lastName"
                      placeholder="Last Name"
                      id="last_name"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.id]: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="org">Company Name *</label>
                    <input
                      type="text"
                      className="blue-text-field w-input"
                      maxLength="256"
                      name="org"
                      data-name="org"
                      placeholder="Your Company Name"
                      id="org"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.id]: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="website">Company Website *</label>
                    <input
                      type="text"
                      className="blue-text-field w-input"
                      maxLength="256"
                      name="website"
                      data-name="website"
                      placeholder="Your Company Website"
                      id="website"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.id]: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="email">Email Address*</label>
                    <input
                      type="email"
                      className="blue-text-field w-input"
                      maxLength="256"
                      name="email"
                      data-name="Email"
                      placeholder="example@email.com"
                      id="email"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.id]: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="phone">Best Phone Number*</label>
                    <input
                      type="phone"
                      className="blue-text-field w-input"
                      maxLength="256"
                      name="phone"
                      data-name="phone"
                      placeholder="(***)***-****"
                      id="phone"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.id]: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="member">Are you an MSN Member?*</label>
                    <select
                      defaultValue={"No"}
                      className="blue-text-field w-input"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          msn_member: e.target.value,
                        });
                      }}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {processing ? (
                      <input
                        value="Processing"
                        className="submit-button-4 w-button"
                        style={{ backgroundColor: "grey", textAlign: "center" }}
                      />
                    ) : (
                      <input
                        type="submit"
                        value="Register Now"
                        data-wait="Please wait..."
                        className="submit-button-4 w-button"
                      />
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="section-25 wf-section">
            <div className="columns-7 w-row">
              <div
                className="column-9 w-col w-col-6"
                style={{
                  backgroundImage:
                    "url(/images/" + eventDetails.bannerImage + ")",
                  objectFit: "cover",
                  backgroundPosition: "10%, 10%",
                  backgroundSize: 800,
                  backgroundRepeat: "none",
                }}
              >
                <div className="div-block-117">
                  <div className="text-block-51">{eventDetails.eventTitle}</div>
                </div>
              </div>
              <div className="w-col w-col-6">
                <div className="div-block-114">
                  <div className="div-block-115">
                    <div className="text-block-50">SHARE</div>
                  </div>
                  <div className="div-block-116">
                    <a
                      href={
                        "https://www.facebook.com/sharer/sharer.php?u=https://www.medspanetwork.com/event/" +
                        match.params.id
                      }
                    >
                      <img
                        src="/images/facebook.webp"
                        loading="lazy"
                        sizes="(max-width: 479px) 25vw, 30px"
                        alt=""
                        className="image-19"
                      />
                    </a>
                    <a
                      href={
                        "https://twitter.com/share?text=twitter&url=https://www.medspanetwork.com/event/" +
                        match.params.id
                      }
                    >
                      <img
                        src="/images/twitter.webp"
                        loading="lazy"
                        sizes="(max-width: 479px) 25vw, 30px"
                        alt=""
                        className="image-19"
                      />
                    </a>
                    <a
                      href={
                        "https://www.linkedin.com/sharing/share-offsite/?&url=https://www.medspanetwork.com/event/" +
                        match.params.id
                      }
                    >
                      <img
                        src="/images/linkedin.webp"
                        loading="lazy"
                        alt=""
                        className="image-19"
                      />
                    </a>
                  </div>
                </div>
                <h1 className="heading-29">Description</h1>
                <div className="text-block-49">
                  {eventDetails.eventDescription}
                </div>
              </div>
            </div>
          </div>
          <div className="section-27 wf-section">
            <h1 className="heading-32">What You&#x27;ll Learn</h1>
            <div className="div-block-126">
              {eventDetails.shortDescriptions.map((tag, index) => {
                return (
                  <div key={index} className="div-block-124">
                    <div className="div-block-125">
                      <img src="/images/check_1.webp" loading="lazy" alt="" />
                    </div>
                    <div className="text-block-53">{tag}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="section-26 wf-section">
            <div className="div-block-118">
              <div className="div-block-119"></div>
              <div className="div-block-119"></div>
              <div className="div-block-119"></div>
              <div className="div-block-120">
                <h1 className="heading-30">Speakers</h1>
                <div className="div-block-121">
                  {eventDetails.presenters.map((selectedPresenter, index) => {
                    return (
                      <div key={index} className="div-block-122">
                        <img
                          src={selectedPresenter.fields.image.fields.file.url}
                          loading="lazy"
                          alt=""
                          className="image-20"
                        />
                        <h3 className="heading-31">
                          {selectedPresenter.fields.name}
                        </h3>
                        <div className="text-block-52">
                          {selectedPresenter.fields.title}
                        </div>
                        {/* <a href="#" className="button-12 w-button">
                      Read Bio
                    </a> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
