import { UserContextProvider, useUserContext } from "./context/userContext.js";
// import AuthProvider, { AuthContext } from "./context/authContext.js";
import { ShoppingCartProvider } from "./context/ShoppingCartContext.js";
import { TrainingProvider } from "./context/clinicalTrainingContext.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AlertBar from "./pages/components/alertBar.js";
import UserTracking from "./hooks/googleAnalytics.js";
import { AuthProvider } from "./context/authContext.js";

import "./assets/styles/modal.css";
import "./assets/styles/hamburger.css";
import "./pagev3/css/medspa-network-v3.webflow.css";
import "./pagev3/css/webflow.css";
import "./pagev3/css/normalize.css";
import "./pagev3/css/globals.css";
import "./pagev3/css/calendar.css";

import Error from "./pagev3/layout/404.js";
import Home from "./pages/home";
import Masterclass from "./pagev3/layout/masterclass.js";
import Login from "./pagev3/layout/login.js";
import Logout from "./pages/Login/logout";
import ForgotPassword from "./pages/forgot-password/index.js";
import NewPassword from "./pages/Login/forgotPassword.js";
import MyAccount from "./pagev3/layout/account/my-account.js";
import TempPassword from "./pages/temp-password";
import Terms from "./pagev3/layout/terms.js";
import Privacy from "./pagev3/layout/privacy.js";
import ThankYou from "./pages/Lab-Registration/thankyou.js";
import VendorThankYou from "./pages/Vendor-Registration/vendorThankyou";
import Survey from "./pages/Survey";
import SurveyThankYou from "./pages/Survey/surveyThankYou";
import Contact from "./pagev3/layout/contact";
import Test from "./pages/testFiles/test";
import ServiceStatus from "./pages/Admin-Utils/serviceStatus";
import ViewAttendees from "./pages/Admin-Utils/viewAttendees";
import Shop from "./pages/shop";
import Product from "./pages/product";
import AboutUs from "./pagev3/layout/about.js";
import Memberships from "./pages/memberships";
import Team from "./pagev3/layout/team.js";
import EventCalendar from "./pagev3/layout/event-calendar.js";
import RohrerPackage from "./pages/landing-pages/rohrerPackage";
import Register from "./pages/register";
import RequestTraining from "./pages/request-a-training";
import VirtualEvents from "./pages/virutal-events";
import InPerson from "./pagev3/layout/inPerson.js";
import FillerFundamentals from "./pagev3/layout/In-Person-Courses/filler-fundamentals.js";
import Course from "./pagev3/layout/course.js";
import VirtualEvent from "./pages/virtual-event";
import Agenda from "./pages/agenda";
import RohrerOrdering from "./pages/landing-pages/rohrerOrdering.js";
import EventThankYou from "./pagev3/layout/event-thank-you.js";
import Lexington from "./pages/landing-pages/lexington.js";
import SemaglutideSignup from "./pages/semaglutide-signup/index.js";
import Semaglutide from "./pages/semaglutide-signup/semaglutide.js";
import PredictCancer from "./pages/EXTREME_TEST_FILES/predict";
import WebinarTest from "./pages/testFiles/webinarTest.js";
import TestSpecials from "./pages/testFiles/testSpecials.js";
import TestPurchase from "./pages/testFiles/test-purchase.js";
import CareGLP from "./pages/semaglutide-signup/care-glp.js";
import Homev3 from "./pagev3/layout/home.js";
import MasterclassCheckout from "./pagev3/layout/masterclass-checkout.js";
import GlpInformation from "./pagev3/layout/glp-information.js";
import Membership from "./pagev3/layout/membership.js";
import Checkout from "./pagev3/layout/paidCheckout.js";
import Events from "./pagev3/layout/events/events.js";
import Webinars from "./pagev3/layout/webinars.js";
import WebinarRegistration from "./pagev3/layout/webinarRegistration.js";
import CookieConsent from "./pagev3/components/cookie-consent.js";
import Signup from "./pagev3/layout/signup.js";
import MembershipCheckout from "./pagev3/layout/membership-checkout.js";
import SuccessfulAccountCreation from "./pagev3/layout/successful-account-creation.js";
import Payments from "./pagev3/layout/account/payments.js";
import TeamSpotlight from "./pagev3/layout/team-spotlight.js";
import Partners from "./pagev3/layout/partners.js";
import PartnerSpotlight from "./pagev3/layout/partner-spotlight.js";
import Courses from "./pagev3/layout/courses.js";
import TestUtil from "./pagev3/layout/testUtil.js";
import Certificates from "./pagev3/layout/account/certificates.js";
import CourseCompletion from "./pagev3/layout/course-completion.js";
import Quiz from "./pagev3/layout/quiz.js";
import WebinarDetails from "./pagev3/layout/webinarDetails.js";
import AccountMembership from "./pagev3/layout/account/membership.js";
import Leaving from "./pagev3/layout/leaving.js";
import MasterclassThankYou from "./pagev3/layout/masterclass-thankyou.js";
import GlpSignup from "./pagev3/layout/glp-signup.js";
import SocietyEvents from "./pagev3/layout/events/society.js";
import IndustryEvents from "./pagev3/layout/events/industry.js";
import TrainingEvents from "./pagev3/layout/events/training.js";
import GlpInformationNew from "./pagev3/layout/glp-information-new.js";
import Article from "./pagev3/layout/article.js";
import Blogs from "./pagev3/layout/blogs.js";

// function RequireAuth({ children }) {
//   return authed ? children : <Navigate to="/" state={{ from: location }} />;
// }

function App() {
  const { createMemoryHistory } = require("history");
  const history = createMemoryHistory();

  UserTracking(window.location.pathname + window.location.search);

  const cookie_consent = localStorage.getItem("cookie_consent");
  return (
    <div className="App" style={{ overflowX: "hidden", position: "relative" }}>
      <AuthProvider>
        <TrainingProvider>
          <UserContextProvider>
            <ShoppingCartProvider>
              <BrowserRouter
                location={history.location}
                navigator={history}
                future={{ v7_startTransition: true }}
              >
                {cookie_consent === "true" ? null : <CookieConsent />}

                <Routes>
                  <Route index element={<Homev3 />} />
                  <Route path="/membership" element={<Membership />} />
                  <Route
                    path="/membership-checkout"
                    element={<MembershipCheckout />}
                  />
                  <Route
                    path="/successful-account-creation"
                    element={<SuccessfulAccountCreation />}
                  />
                  <Route path="checkout" element={<Checkout />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/in-person-learning" element={<InPerson />} />
                  <Route path="/webinars" element={<Webinars />} />
                  <Route path="/webinar/:id" element={<WebinarDetails />} />
                  <Route
                    path="/webinar/register"
                    element={<WebinarRegistration />}
                  />
                  <Route
                    path="/in-person-learning/filler-fundamentals"
                    element={<FillerFundamentals />}
                  />
                  <Route
                    path="/team-spotlight/:staff"
                    element={<TeamSpotlight />}
                  />
                  <Route path="/create-account" element={<Signup />} />
                  <Route path="/leaving" element={<Leaving />} />
                  <Route path="/account" element={<MyAccount />} />
                  <Route
                    path="/my-membership"
                    element={<AccountMembership />}
                  />
                  <Route path="/certificates" element={<Certificates />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/industry-partners" element={<Partners />} />
                  <Route
                    path="/partner/:partner"
                    element={<PartnerSpotlight />}
                  />
                  <Route path="/online-courses" element={<Courses />} />
                  <Route path="/course" element={<Course />} />
                  <Route
                    path="/completed-course"
                    element={<CourseCompletion />}
                  />
                  <Route path="/quiz" element={<Quiz />} />
                  <Route path="/testUtil" element={<TestUtil />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/new-password" element={<NewPassword />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/course/:id" element={<Course />} />
                  {/* <Route path="/shop" element={<Homev3 />} /> */}
                  <Route path="/product/:id" element={<Product />} />
                  <Route path="/industry-partners" element={<Partners />} />
                  <Route path="/tempPassword" element={<TempPassword />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/thankYou" element={<ThankYou />} />
                  <Route
                    path="/masterclass-thank-you"
                    element={<MasterclassThankYou />}
                  />
                  <Route path="/vendorThankyou" element={<VendorThankYou />} />
                  <Route path="/survey" element={<Survey />} />
                  <Route path="/surveyThankYou" element={<SurveyThankYou />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/test" element={<Test />} />
                  <Route path="/testSpecials" element={<TestSpecials />} />
                  <Route path="/testPurchase" element={<TestPurchase />} />
                  <Route path="/viewAttendees" element={<ViewAttendees />} />
                  <Route path="/serviceStatus" element={<ServiceStatus />} />
                  <Route path="/forgotPassword" element={<ForgotPassword />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/rohrerPackage" element={<Home />} />
                  <Route path="/request" element={<RequestTraining />} />
                  <Route path="/virtualEvents" element={<VirtualEvents />} />
                  <Route path="/virtualEvent/:id" element={<VirtualEvent />} />
                  <Route path="/agenda" element={<Agenda />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/memberships" element={<Memberships />} />
                  <Route path="/registration" element={<Register />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/rohrerOrdering" element={<RohrerOrdering />} />
                  <Route path="/eventThankYou" element={<EventThankYou />} />
                  <Route
                    path="/semaglutideSignup"
                    element={<SemaglutideSignup />}
                  />
                  <Route path="/semaglutide" element={<GlpSignup />} />
                  <Route path="/glp-signup" element={<GlpSignup />} />
                  <Route path="/glp-information" element={<GlpInformation />} />
                  <Route
                    path="/glp-information-new"
                    element={<GlpInformationNew />}
                  />
                  <Route path="/predictCancer" element={<PredictCancer />} />
                  <Route path="/lexington" element={<Lexington />} />
                  <Route path="/webinarTest" element={<WebinarTest />} />
                  <Route path="/care-GLP" element={<CareGLP />} />
                  <Route path="/masterclass/:id" element={<Masterclass />} />
                  <Route
                    path="/masterclass-checkout"
                    element={<MasterclassCheckout />}
                  />
                  <Route path="/event-calendar" element={<EventCalendar />} />
                  <Route path="/events/:type" element={<Events />} />
                  <Route path="/society-Events" element={<SocietyEvents />} />
                  <Route path="/industry-Events" element={<IndustryEvents />} />
                  <Route path="/training-Events" element={<TrainingEvents />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/article/:id" element={<Article />} />
                  <Route path="*" element={<Error />} />
                </Routes>
              </BrowserRouter>
            </ShoppingCartProvider>
          </UserContextProvider>
        </TrainingProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
