import axios from "axios";

export default function TestUtil() {
  async function getTopTen() {
    const results = await axios.get("/api/v2/Merz/utils/getTopTen");
    console.log(results.data);
  }

  getTopTen();

  return (
    <div>
      <h1>testUtil</h1>
    </div>
  );
}
