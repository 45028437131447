import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  function submitForm(e) {
    e.preventDefault();
    axios
      .post("/api/v2/Website/accounts/subscribe", {
        ...formData,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          navigate.reload();
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <section className="section dark-bg footer">
      <div className="three-column-conatiner center">
        <div className="column">
          <img
            src={require("../../assets/images/Layer_2-2.png")}
            loading="lazy"
            alt=""
            className="image-6"
          />
          <div className="div-block-6">
            <img
              src={require("../../assets/images/Facebook-Icon.png")}
              loading="lazy"
              alt=""
              className="social-icon"
            />
            <img
              src={require("../../assets/images/Twitter-Icon.png")}
              loading="lazy"
              alt=""
              className="social-icon"
            />
            <img
              src={require("../../assets/images/LinkedIn-Icon.png")}
              loading="lazy"
              alt=""
              className="social-icon"
            />
            <img
              src={require("../../assets/images/Instagram-Icon.png")}
              loading="lazy"
              alt=""
              className="social-icon"
            />
          </div>
        </div>
        <div className="column">
          <h5 className="white-text small-header">About</h5>
          <div className="div-block-7">
            <a href="/eventCalendar" className="footer-link">
              Events &amp; Education
            </a>
            <a href="/registration" className="footer-link">
              Join Us
            </a>
            <a href="/contact" className="footer-link">
              Contact
            </a>
            <a href="/privacy" className="footer-link">
              Privacy Policy
            </a>
            <a href="/terms" className="footer-link">
              Terms &amp; Conditions
            </a>
          </div>
        </div>
        <div className="column">
          <h5 className="white-text small-header">
            sign up for our communications
          </h5>
          <div className="form-block-footer w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form"
              data-wf-page-id="65087d7cb990cce96d491a34"
              data-wf-element-id="5cc6f056-6a12-194a-ec30-d906069c7d40"
              onSubmit={(e) => {
                submitForm(e);
              }}
            >
              <input
                type="text"
                className="text-field-footer"
                maxLength="256"
                name="email"
                data-name="email"
                placeholder="Email"
                id="email"
                onChange={(e) => {
                  setFormData({ email: e.target.value });
                }}
              />
              <input
                type="submit"
                value="Submit"
                data-wait="Please wait..."
                className="submit-button-footer"
              />
              <h3 style={{ color: "red" }}>{message}</h3>
            </form>
          </div>
        </div>
      </div>
      <div className="div-block-8"></div>
      <div className="text-block">
        © 2023 MedSpa Network. All Rights Reserved.
      </div>
    </section>
  );
}
