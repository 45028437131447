import axios from "axios";
const userData = localStorage.getItem("user");
const user = JSON.parse(userData);

export default function Courses() {}

export async function GetCourses() {
  const token = localStorage.getItem("authToken");

  const res = await axios.get("/api/v2/Website/courses/get-all-courses");
  return res.data.data;
}

export async function GetCourseById(id) {
  const token = localStorage.getItem("authToken");

  console.log(token);
  const res = await axios.get(`/api/v2/Website/courses/get-course-by-id`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { _id: id },
  });

  return res.data.data;
}

export async function GetMembersCompletedClasses() {
  const token = localStorage.getItem("authToken");

  const res = await axios.get(
    `/api/v2/Website/courses/get-members-completed-classes`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { _id: user._id },
    }
  );

  return res.data.data;
}

export async function GetClassDetails(id) {
  const token = localStorage.getItem("authToken");

  const res = await axios.get(`/api/v2/Website/courses/get-course`, {
    params: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      _id: id,
    },
  });

  return res.data.data;
}

export async function GetTraningVideo(id) {
  const token = localStorage.getItem("authToken");

  const res = await axios.get(`/api/v2/Website/courses/get-course`, {
    params: {
      _id: id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  return res.data.data;
}

export async function GetQuiz(_id) {
  const token = localStorage.getItem("authToken");

  const res = await axios.post(
    `/api/v2/Website/courses/get-quiz`,
    {
      _id: _id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  console.log(res.data);

  return res.data.data;
}

export async function MarkClassFinished(classId, courseId) {
  const token = localStorage.getItem("authToken");

  const res = await axios.post(
    "/api/v2/Website/courses/mark-class-finished",
    {
      _id: user._id,
      classId: classId,
      courseId: courseId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
}

export async function MarkCourseFinished(courseId) {
  const token = localStorage.getItem("authToken");

  const res = await axios.post(
    "/api/v2/Website/courses/mark-course-finished",
    {
      courseId: courseId,
      userId: user._id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
}
