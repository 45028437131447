import { Dense_button_dark, Dense_Button_Light } from "./buttons";

export default function EventCardType2Light({ title, link, date }) {
  return (
    <div className="event-card-type-2 light">
      <div className="featured-event-title-bar">
        <div className="featured-event-title">
          <div className="header-s">{title}</div>
        </div>
      </div>
      <div className="featured-event-details-bar">
        <div className="div-block-50">
          <div className="badge">
            <div className="eyebrow s">{date}</div>
          </div>
          <div className="badge">
            <div className="eyebrow s">In-Person</div>
          </div>
        </div>
        <Dense_Button_Light link={link} text="Book Now" />
      </div>
    </div>
  );
}
