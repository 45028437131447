import { useEffect, useState, useMemo } from "react";
import { useRef } from "react";

export default function CountUp({ number, additionalText }) {
  const [countUp, setCountUp] = useState(0);
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef(null);
  var i = 0;

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    [ref]
  );

  useEffect(() => {
    if (isIntersecting) {
      var interval = setInterval(countUpFunction, 1500 / number);

      function countUpFunction() {
        console.log("Start");
        if (i === number) {
          clearInterval(interval);
          return;
        }
        i = i + 1;
        setCountUp(i);
      }
    }

    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [isIntersecting]);

  return <p ref={ref}>{countUp + additionalText}</p>;
}
