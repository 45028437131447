import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { useNavigate, useLocation } from "react-router-dom";
import { CreateAccount } from "../../services/members";
import { useState } from "react";
import { useUserContext } from "../../context/userContext";

export default function MembershipCheckout() {
  const { loginUser } = useUserContext();

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const [formData, setFormData] = useState({ ...location.state, price: 1500 });

  async function submitPayment(e) {
    e.preventDefault();
    const results = await CreateAccount(formData);
    if (results.status == "success") {
      localStorage.setItem("authToken", results.data._id);
      loginUser(results.data);
      navigate("/successful-account-creation", {
        state: { message: "Payment Submitted", ...location.state },
      });
    } else {
      alert(results.message);
    }
  }

  return (
    <>
      <Navbar />
      <section className="white-background-section full-height">
        <div className="w-layout-blockcontainer container full-height ">
          <div className="header-l">MedSpa Network Membership</div>
          <div
            id="w-node-_9be168e8-e24e-8697-2f5a-398ee81e90fa-3ba2f31c"
            className="w-layout-layout full-width-stack pad-top-xl wf-layout-layout"
          >
            <div
              id="w-node-_9be168e8-e24e-8697-2f5a-398ee81e90fb-3ba2f31c"
              className="w-layout-cell"
            >
              <div className="pricing-card">
                <div className="pricing-card-top">
                  <div className="header-s">1-Year</div>
                  <div className="header-s">Membership</div>
                  <div className="display-xl blue">
                    {formData.price.toLocaleString("USD", {
                      style: "currency",
                      currency: "USD",
                      trailingZeros: false,
                    })}
                  </div>
                  <div className="badge">
                    <div className="eyebrow s">Billed Yearly</div>
                  </div>
                </div>
                <div className="pricing-card-bottom">
                  <div className="body-s">Includes</div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      loading="lazy"
                      src={require("../../assets/icons/next.png")}
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s">Online CME</div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      loading="lazy"
                      src={require("../../assets/icons/next.png")}
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s">
                      Exclusive Partnership Discounts
                    </div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      loading="lazy"
                      src={require("../../assets/icons/next.png")}
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s">In-Person Training Discounts</div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      loading="lazy"
                      src={require("../../assets/icons/next.png")}
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s">Access to Top Trainers</div>
                  </div>
                  <div className="pricing-card-bottom-bullet-container">
                    <img
                      loading="lazy"
                      src={require("../../assets/icons/next.png")}
                      alt=""
                      className="pricing-card-bullet-icon"
                    />
                    <div className="body-s">Members Only Webinars</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_9be168e8-e24e-8697-2f5a-398ee81e90fc-3ba2f31c"
              className="w-layout-cell"
            >
              <div className="light-form w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  data-wf-page-id="670e78a8412948fa3ba2f31c"
                  data-wf-element-id="c492ff74-2391-8a68-7a0e-63d6ceae5986"
                  onSubmit={(e) => {
                    submitPayment(e);
                  }}
                >
                  <div className="form-2-fields">
                    <div className="form-half-width">
                      <label for="firstName-2" className="field-label-2 light">
                        First Name
                      </label>
                      <input
                        className="checkout-field light w-input"
                        maxlength="256"
                        name="firstName-2"
                        data-name="First Name 2"
                        placeholder=""
                        type="text"
                        id="firstName-2"
                        required=""
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            billingFirstName: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-half-width">
                      <label for="lastName-2" className="field-label-2 light">
                        Last Name
                      </label>
                      <input
                        className="checkout-field light w-input"
                        maxlength="256"
                        name="lastName-2"
                        data-name="Last Name 2"
                        placeholder=""
                        type="text"
                        id="lastName-2"
                        required=""
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            billingLastName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="field-group">
                    <label for="phone" className="field-label-2 light">
                      Phone Number
                    </label>
                    <input
                      className="checkout-field light w-input"
                      maxlength="256"
                      name="phone"
                      data-name="phone"
                      placeholder=""
                      type="tel"
                      id="phone"
                      required=""
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          billingPhoneNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="field-group">
                    <label for="email-2" className="field-label-2 light">
                      Email
                    </label>
                    <input
                      className="checkout-field light w-input"
                      maxlength="256"
                      name="email-2"
                      data-name="Email 2"
                      placeholder=""
                      type="email"
                      id="email-2"
                      required=""
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          billingEmail: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="field-group">
                    <label for="address-3" className="field-label-2 light">
                      Billing Address
                    </label>
                    <input
                      className="checkout-field light w-input"
                      maxlength="256"
                      name="address"
                      data-name="address"
                      placeholder=""
                      type="text"
                      id="address"
                      required=""
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          billingAddress: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="field-group">
                    <div className="form-half-width">
                      <label
                        for="billingCity-2"
                        className="field-label-2 light"
                      >
                        Billing City
                      </label>
                      <input
                        className="checkout-field light w-input"
                        maxlength="256"
                        name="billingCity-2"
                        data-name="Billing City 2"
                        placeholder=""
                        type="text"
                        id="billingCity-2"
                        required=""
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            billingCity: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="field-group">
                    <div className="form-half-width">
                      <label
                        for="billingState-2"
                        className="field-label-2 light"
                      >
                        Billing State
                      </label>
                      <input
                        className="checkout-field light w-input"
                        maxlength="256"
                        name="billingState-2"
                        data-name="Billing State 2"
                        placeholder=""
                        type="text"
                        id="billingState-2"
                        required=""
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            billingState: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="field-group">
                    <div className="form-half-width">
                      <label
                        for="billingZipCode"
                        className="field-label-2 light"
                      >
                        Billing Zip Code
                      </label>
                      <input
                        className="checkout-field light w-input"
                        maxlength="256"
                        name="billingZipCode"
                        data-name="billingZipCode"
                        placeholder=""
                        type="number"
                        id="billingZipCode"
                        required=""
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            billingZipCode: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="field-group">
                    <label for="cardNumber" className="field-label-2 light">
                      CArd Number
                    </label>
                    <input
                      className="checkout-field light w-input"
                      maxlength="256"
                      name="cardNumber"
                      data-name="cardNumber"
                      placeholder=""
                      type="number"
                      id="cardNumber"
                      required=""
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          billingCardNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="field-group">
                    <div className="form-2-fields">
                      <div className="form-half-width">
                        <label
                          for="cardExpiration"
                          className="field-label-2 light"
                        >
                          Card Expiration (MMYYYY)
                        </label>
                        <input
                          className="checkout-field light w-input"
                          minLength={6}
                          name="cardExpiration"
                          data-name="cardExpiration"
                          placeholder=""
                          type="plain"
                          id="cardExpiration"
                          required=""
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              billingCardExpiration: e.target.value
                                .replace("/", "")
                                .replace("-", "")
                                .replace(" ", ""),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="field-group">
                      <div className="form-half-width">
                        <label for="cardCVV" className="field-label-2 light">
                          Card CVV
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxlength="256"
                          name="cardCVV"
                          data-name="cardCVV"
                          placeholder=""
                          type="number"
                          id="cardCVV"
                          required=""
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              billingCVV: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    value="Submit"
                    className="button-dense-primary-dark full-width "
                  />
                </form>
                <button
                  onClick={() => {
                    submitPayment();
                  }}
                >
                  Skip Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
