import { useLayoutEffect } from "react";
import AlertBar from "./alertBar";
import HamburgerMenu from "./hamburger";

export default function NavBar() {
  useLayoutEffect(() => {
    const currentPage = window.location.pathname;
    const urlPath = currentPage.split("/").filter(Boolean);
    const lastItem = urlPath[urlPath.length - 1];
    try {
      document.getElementById(lastItem).classList.add("w--current");
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div id="navBar" style={{ flexDirection: "column", display: "flex" }}>
      <div className="nav-container">
        <div className="logo-container">
          <a href="/">
            <img
              src={require("../../assets/images/MSN_Colored_Mark_White_Text-p-1080.png")}
              loading="lazy"
              alt=""
              className="image"
            />
          </a>
        </div>

        <div className="nav-link-container">
          <div
            data-hover="true"
            data-delay="0"
            className="w-dropdown"
            onMouseEnter={() => {
              const element = document.getElementById("browseDrop");

              element.classList.add("w--open");
            }}
            onMouseLeave={() => {
              const element = document.getElementById("browseDrop");

              element.classList.remove("w--open");
            }}
          >
            <div className="dropdown-toggle w-dropdown-toggle">
              <div className="icon w-icon-dropdown-toggle"></div>
              <div className="nav-link">Browse Courses</div>
            </div>
            <nav className="dropdown-list-3 w-dropdown-list" id="browseDrop">
              <div className="div-block-208">
                <div className="columns-9 w-row">
                  <div className="w-col w-col-4">
                    <h3 className="dropdown-header">Injectables</h3>
                    <a
                      href="/toxin-courses/toxin-fundamentals"
                      className="dropdown-link-8 w-dropdown-link"
                      id="toxin-fundamentals"
                    >
                      Toxin Fundamentals
                    </a>

                    <a
                      href="/toxin-courses/toxin-masterclass"
                      aria-current="page"
                      className="dropdown-link-8 w-dropdown-link"
                      id="toxin-masterclass"
                    >
                      Toxin Masterclass
                    </a>
                    {/* <a
                      href="/toxin-courses/combination-aesthetics"
                      className="dropdown-link-8 w-dropdown-link"
                      id="combination-aesthetics"
                    >
                      Combination Aesthetics
                    </a> */}
                  </div>
                  <div className="w-col w-col-4">
                    <h3 className="dropdown-header">Filler</h3>
                    <a
                      href="/filler-courses/filler-fundamentals"
                      className="dropdown-link-8 w-dropdown-link"
                      id="filler-fundamentals"
                    >
                      Filler Fundamentals
                    </a>

                    <a
                      href="/filler-courses/filler-masterclass"
                      className="dropdown-link-8 w-dropdown-link"
                      id="filler-masterclass"
                    >
                      Filler Masterclass
                    </a>
                  </div>
                  <div className="column-65 w-col w-col-4">
                    <h3 className="dropdown-header">Devices</h3>
                    <a
                      href="/device-courses/lasers"
                      className="dropdown-link-8 w-dropdown-link"
                      id="lasers"
                    >
                      Lasers
                    </a>
                    {/* <a
                      href="/device-courses/microneedling"
                      className="dropdown-link-8 w-dropdown-link"
                      id="microneedling"
                    >
                      Microneedling
                    </a> */}
                    <a
                      href="/device-courses/ultherapy"
                      className="dropdown-link-8 w-dropdown-link"
                      id="ultherapy"
                    >
                      Ultherapy
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>

          {/* <div
            data-hover="true"
            data-delay="0"
            className="w-dropdown"
            onMouseEnter={() => {
              const element = document.getElementById("learningDrop");

              element.classList.add("w--open");
            }}
            onMouseLeave={() => {
              const element = document.getElementById("learningDrop");

              element.classList.remove("w--open");
            }}
          >
            <div className="dropdown-toggle w-dropdown-toggle">
              <div className="icon w-icon-dropdown-toggle"></div>
              <div className="nav-link">Learning Methods</div>
            </div>
            <nav className="w-dropdown-list" id="learningDrop">
              <a
                href="../../learning-methods/national-training"
                className="dropdown-link w-dropdown-link"
                id="national-training"
              >
                National Annual Summit
              </a>
              <a
                href="../../learning-methods/regional-summit"
                className="dropdown-link w-dropdown-link"
                id="regional-summits"
              >
                Regional Small Group Training - Vero
              </a>

              <a
                href="../../learning-methods/destination-training"
                className="dropdown-link w-dropdown-link"
                id="destination-training"
              >
                Destination Training
              </a>

              <a
                href="../../learning-methods/1on1"
                className="dropdown-link w-dropdown-link"
                id="1on1"
              >
                Customized 1:1 Training
              </a>

              <a
                href="../../learning-methods/fellowship"
                className="dropdown-link w-dropdown-link"
                id="fellowship"
              >
                Fellowship Program
              </a>
            </nav>
          </div> */}

          <div
            data-hover="true"
            data-delay="0"
            className="w-dropdown"
            onMouseEnter={() => {
              const element = document.getElementById("calendarDrop");

              element.classList.add("w--open");
            }}
            onMouseLeave={() => {
              const element = document.getElementById("calendarDrop");

              element.classList.remove("w--open");
            }}
          >
            <div className="dropdown-toggle w-dropdown-toggle">
              <div className="icon w-icon-dropdown-toggle"></div>
              <div className="nav-link">Calendar</div>
            </div>
            <nav className="w-dropdown-list" id="calendarDrop">
              <a
                href="/eventCalendar"
                className="dropdown-link w-dropdown-link"
                id="eventCalendar"
              >
                All Events
              </a>
              <a
                href="/virtualEvents"
                className="dropdown-link w-dropdown-link"
                id="virtualEvents"
              >
                Webinars
              </a>
              <a
                href="/courses"
                className="dropdown-link w-dropdown-link"
                id="courses"
              >
                Online Courses
              </a>
            </nav>
          </div>

          <div
            data-hover="true"
            data-delay="0"
            className="w-dropdown"
            onMouseEnter={() => {
              const element = document.getElementById("partnerDrop");

              element.classList.add("w--open");
            }}
            onMouseLeave={() => {
              const element = document.getElementById("partnerDrop");

              element.classList.remove("w--open");
            }}
          >
            <div className="dropdown-toggle w-dropdown-toggle">
              <div className="icon w-icon-dropdown-toggle"></div>
              <div className="nav-link">Industry Partners</div>
            </div>
            <nav
              className="vendor-dropdown-list w-dropdown-list"
              id="partnerDrop"
            >
              <div
                id="w-node-cbfc8680-26ef-fa69-4a9e-5ac7b4baefda-706da7be"
                className="w-layout-layout quick-stack-4 wf-layout-layout"
              >
                <div
                  id="w-node-_7921fe20-0276-6fc1-35a8-e6e6d5ee5bc7-706da7be"
                  className="w-layout-cell dropdown-cell"
                >
                  <h3 className="dropdown-header">Injectables</h3>
                  <a
                    href="/vendor-partner/merz"
                    className="dropdown-link w-dropdown-link"
                    id="merz"
                  >
                    Merz
                  </a>
                  <a
                    href="/vendor-partner/mtf-biologics"
                    className="dropdown-link w-dropdown-link"
                    id="mtf"
                  >
                    MTF Biologics
                  </a>
                  <a
                    href="/vendor-partner/suneva"
                    className="dropdown-link w-dropdown-link"
                    id="suneva"
                  >
                    Suneva
                  </a>
                </div>
                <div
                  id="w-node-fb7d1d77-410a-7275-142e-59139dddf243-706da7be"
                  className="w-layout-cell dropdown-cell"
                >
                  <h3 className="dropdown-header">Devices</h3>
                  <a
                    href="/vendor-partner/renuvion"
                    className="dropdown-link w-dropdown-link"
                    id="renuvion"
                  >
                    Renuvion
                  </a>
                  <a
                    href="/vendor-partner/rohrer"
                    className="dropdown-link w-dropdown-link"
                    id="rohrer"
                  >
                    Rohrer
                  </a>
                  <a
                    href="/vendor-partner/salt-facial"
                    className="dropdown-link w-dropdown-link"
                    id="salt-facial"
                  >
                    Salt Facial
                  </a>
                </div>
                <div
                  id="w-node-_678cfb96-2bcb-e6f3-39f5-d9a86cec1f9a-706da7be"
                  className="w-layout-cell dropdown-cell"
                >
                  <h3 className="dropdown-header">Skin Care</h3>
                  <a
                    href="/vendor-partner/alastin"
                    className="dropdown-link vendor-dropdown-link w-dropdown-link"
                    id="alastin"
                  >
                    Alastin
                  </a>
                  <a
                    href="/vendor-partner/babor"
                    className="dropdown-link w-dropdown-link"
                    id="babor"
                  >
                    Babor
                  </a>
                  <a
                    href="/vendor-partner/environ"
                    className="dropdown-link w-dropdown-link"
                    id="environ"
                  >
                    Environ
                  </a>
                  <a
                    href="/vendor-partner/ourself"
                    className="dropdown-link w-dropdown-link"
                    id="environ"
                  >
                    Ourself
                  </a>
                </div>
                <div
                  id="w-node-_1b04b922-8c59-95d8-25c7-eee676a0089f-706da7be"
                  className="w-layout-cell"
                >
                  <h3 className="dropdown-header">Other</h3>
                  <a
                    href="/vendor-partner/advital"
                    className="dropdown-link w-dropdown-link"
                    id="advital"
                  >
                    Ad Vital
                  </a>
                  <a
                    href="/vendor-partner/cherry"
                    className="dropdown-link w-dropdown-link"
                    id="cherry"
                  >
                    Cherry
                  </a>
                  {/* <a
                    href="/vendor-partner/corganics"
                    className="dropdown-link w-dropdown-link"
                    id="corganics"
                  >
                    Corganics
                  </a> */}
                  <a
                    href="/vendor-partner/influx"
                    className="dropdown-link w-dropdown-link"
                    id="influx"
                  >
                    Influx
                  </a>
                  {/* <a
                    href="/vendor-partner/new-beauty"
                    className="dropdown-link w-dropdown-link"
                    id="new-beauty"
                  >
                    New Beauty
                  </a> */}
                  <a
                    href="/vendor-partner/repeat-md"
                    className="dropdown-link w-dropdown-link"
                    id="repeatMD"
                  >
                    Repeat MD
                  </a>
                </div>
              </div>
            </nav>
          </div>

          <div
            data-hover="true"
            data-delay="0"
            className="w-dropdown"
            onMouseEnter={() => {
              const element = document.getElementById("aboutDrop");

              element.classList.add("w--open");
            }}
            onMouseLeave={() => {
              const element = document.getElementById("aboutDrop");

              element.classList.remove("w--open");
            }}
          >
            <div className="dropdown-toggle w-dropdown-toggle">
              <div className="icon w-icon-dropdown-toggle"></div>
              <div className="nav-link">About</div>
            </div>
            <nav id="aboutDrop" className="w-dropdown-list">
              <a href="/about" className="dropdown-link w-dropdown-link">
                About Us
              </a>
              <a href="/team" className="dropdown-link w-dropdown-link">
                Our Team
              </a>
              <a href="/industry-partners" className="dropdown-link w-dropdown-link">
                Partnerships
              </a>
            </nav>
          </div>

          <div
            data-hover="true"
            data-delay="0"
            className="w-dropdown"
            onMouseEnter={() => {
              const element = document.getElementById("memberDrop");

              element.classList.add("w--open");
            }}
            onMouseLeave={() => {
              const element = document.getElementById("memberDrop");

              element.classList.remove("w--open");
            }}
          >
            <div className="dropdown-toggle w-dropdown-toggle">
              <div className="icon w-icon-dropdown-toggle"></div>
              <div className="nav-link">Members</div>
            </div>
            {localStorage.getItem("authToken") ? (
              <nav id="memberDrop" className="w-dropdown-list">
                <a href="/shop" className="dropdown-link w-dropdown-link">
                  Shop
                </a>
                <a href="/myAccount" className="dropdown-link w-dropdown-link">
                  My Account
                </a>
                <a href="/logout" className="dropdown-link w-dropdown-link">
                  Logout
                </a>
              </nav>
            ) : (
              <nav id="memberDrop" className="w-dropdown-list">
                <a href="/shop" className="dropdown-link w-dropdown-link">
                  Shop
                </a>
                <a href="/Login" className="dropdown-link w-dropdown-link">
                  Login
                </a>
              </nav>
            )}
          </div>

          {localStorage.getItem("authToken") ? null : (
            <a href="/registration" className="nav-button-stroke anim300">
              Join
            </a>
          )}
          <a href="/contact" className="nav-button-fill anim300">
            Contact
          </a>
        </div>
        <div className="hambuger-container">
          <HamburgerMenu />
        </div>
      </div>
    </div>
  );
}
