export default function CookieConsent() {
  const cookie_consent = localStorage.getItem("cookie_consent");

  return (
    <div
      className="div-block-53"
      id="CookieConsent"
      style={{ position: "fixed", top: "70vh" }}
    >
      <div className="div-block-54">
        <div className="header-xs light">This website uses cookies</div>
        <p className="body-s light short">
          We use cookies to make sure this website can function. By continuing
          to browse on this website, you agree to our use of cookies as detailed
          in our{" "}
          <a href="/privacy" style={{ color: "white" }}>
            Privacy Policy
          </a>
          .
        </p>
      </div>
      <button
        className="button-dense-primary-light "
        style={{ textDecoration: "none", cursor: "pointer" }}
        onClick={() => {
          localStorage.setItem("cookie_consent", "true");
          window.location.reload();
        }}
      >
        OK
      </button>
    </div>
  );
}
