export default function PartnerBar() {
  return (
    <section className="section grey-bg partner-bar">
      <img
        src="images/environ_gray-2048x1283.png"
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 79.8125px"
        srcSet="images/environ_gray-2048x1283-p-500.png 500w, images/environ_gray-2048x1283-p-800.png 800w, images/environ_gray-2048x1283-p-1080.png 1080w, images/environ_gray-2048x1283-p-1600.png 1600w, images/environ_gray-2048x1283-p-2000.png 2000w, images/environ_gray-2048x1283.png 2048w"
        alt=""
        className="partner-logo"
      />
      <img
        src="images/Babor_grey-2048x880.webp"
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 116.359375px"
        srcSet="images/Babor_grey-2048x880-p-500.webp 500w, images/Babor_grey-2048x880-p-800.webp 800w, images/Babor_grey-2048x880-p-1080.webp 1080w, images/Babor_grey-2048x880-p-1600.webp 1600w, images/Babor_grey-2048x880-p-2000.webp 2000w, images/Babor_grey-2048x880.webp 2048w"
        alt=""
        className="partner-logo"
      />
      <img
        src="images/Merz_gray-01-2048x2048.png"
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 50px"
        srcSet="images/Merz_gray-01-2048x2048-p-500.png 500w, images/Merz_gray-01-2048x2048-p-800.png 800w, images/Merz_gray-01-2048x2048-p-1080.png 1080w, images/Merz_gray-01-2048x2048-p-1600.png 1600w, images/Merz_gray-01-2048x2048-p-2000.png 2000w, images/Merz_gray-01-2048x2048.png 2048w"
        alt=""
        className="partner-logo"
      />
      <img
        src="images/MTF_grey-2048x564-p-2000.png"
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 181.484375px"
        srcSet="images/MTF_grey-2048x564-p-2000-p-500.png 500w, images/MTF_grey-2048x564-p-2000-p-800.png 800w, images/MTF_grey-2048x564-p-2000-p-1080.png 1080w, images/MTF_grey-2048x564-p-2000.png 2000w"
        alt=""
        className="partner-logo"
      />
      <img
        src="images/Rhorer_grey-2048x336.png"
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 304.7578125px"
        srcSet="images/Rhorer_grey-2048x336-p-500.png 500w, images/Rhorer_grey-2048x336-p-800.png 800w, images/Rhorer_grey-2048x336-p-1080.png 1080w, images/Rhorer_grey-2048x336-p-1600.png 1600w, images/Rhorer_grey-2048x336-p-2000.png 2000w, images/Rhorer_grey-2048x336.png 2048w"
        alt=""
        className="partner-logo"
      />
      <img
        src="images/SaltFacial_grey-2048x431.png"
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 237.5859375px"
        srcSet="images/SaltFacial_grey-2048x431-p-500.png 500w, images/SaltFacial_grey-2048x431-p-800.png 800w, images/SaltFacial_grey-2048x431-p-1080.png 1080w, images/SaltFacial_grey-2048x431-p-1600.png 1600w, images/SaltFacial_grey-2048x431-p-2000.png 2000w, images/SaltFacial_grey-2048x431.png 2048w"
        alt=""
        className="partner-logo"
      />
      <img
        src="images/Babor_grey-2048x880.webp"
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 116.359375px"
        srcSet="images/Babor_grey-2048x880-p-500.webp 500w, images/Babor_grey-2048x880-p-800.webp 800w, images/Babor_grey-2048x880-p-1080.webp 1080w, images/Babor_grey-2048x880-p-1600.webp 1600w, images/Babor_grey-2048x880-p-2000.webp 2000w, images/Babor_grey-2048x880.webp 2048w"
        alt=""
        className="partner-logo"
      />
    </section>
  );
}
