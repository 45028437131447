import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { useLocation } from "react-router-dom";

export default function TeamSpotlight() {
  const location = useLocation();
  return (
    <>
      <Navbar />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container spotlight-stack">
          <div className="div-block-55">
            <img
              src={location.state.image}
              loading="lazy"
              alt=""
              className="staff-image"
              style={{objectFit: "cover"}}
            />
          </div>
          <div className="div-block-56">
            <div className="staff-socials-container">
              <div className="eyebrow s">Social</div>
              <a
                href={location.state.socials.instagram}
                target="_blank"
                className="staff-social-icon w-inline-block"
              >
                <img
                  src={require("../../assets/logos/instagram-logo.png")}
                  loading="lazy"
                  alt=""
                  className="staff-social-icon-image"
                />
              </a>
              <a
                href={location.state.socials.linkedin}
                target="_blank"
                className="staff-social-icon w-inline-block"
              >
                <img
                  src={require("../../assets/logos/linkedin-logo.png")}
                  loading="lazy"
                  alt=""
                  className="staff-social-icon-image"
                />
              </a>
            </div>
            <div>
              <div className="header-xl">{location.state.name}</div>
              <div className="header-xl light">{location.state.credential}</div>
            </div>
            <div className="biography-container">
              {location.state.bio.map((p, index) => {
                return (
                  <p key={index} className="body-l">
                    {p}
                  </p>
                );
              })}

              {/* <div
                id="w-node-bb207b8c-0cf1-5f0f-be22-a0836f0eee08-e2cbdfff"
                className="w-layout-layout staff-bio-stack wf-layout-layout"
              >
                <div
                  id="w-node-bb207b8c-0cf1-5f0f-be22-a0836f0eee09-e2cbdfff"
                  className="w-layout-cell featured-item"
                >
                  <p className="body-l">
                    Dr. Alan Durkin is a Board Certified Plastic &amp;
                    Reconstructive Surgeon and the owner of Ocean Drive Plastic
                    Surgery, a premier surgery center known for its blend of
                    style, elegance, and state-of-the-art medical facilities.
                  </p>
                </div>
                <div
                  id="w-node-bb207b8c-0cf1-5f0f-be22-a0836f0eee0a-e2cbdfff"
                  className="w-layout-cell featured-item"
                >
                  <p className="body-l">
                    Dr. Alan Durkin is a Board Certified Plastic &amp;
                    Reconstructive Surgeon and the owner of Ocean Drive Plastic
                    Surgery, a premier surgery center known for its blend of
                    style, elegance, and state-of-the-art medical facilities.
                  </p>
                </div>
              </div> */}
              {/* <div
                id="w-node-_879f8681-b374-63f6-4710-0f3ea1e78f7c-e2cbdfff"
                className="w-layout-layout staff-bio-stack wf-layout-layout"
              >
                <div
                  id="w-node-_879f8681-b374-63f6-4710-0f3ea1e78f7d-e2cbdfff"
                  className="w-layout-cell featured-item"
                >
                  <p className="body-l">
                    Dr. Alan Durkin is a Board Certified Plastic &amp;
                    Reconstructive Surgeon and the owner of Ocean Drive Plastic
                    Surgery, a premier surgery center known for its blend of
                    style, elegance, and state-of-the-art medical facilities.
                  </p>
                </div>
                <div
                  id="w-node-_879f8681-b374-63f6-4710-0f3ea1e78f80-e2cbdfff"
                  className="w-layout-cell featured-item"
                >
                  <p className="body-l">
                    Dr. Alan Durkin is a Board Certified Plastic &amp;
                    Reconstructive Surgeon and the owner of Ocean Drive Plastic
                    Surgery, a premier surgery center known for its blend of
                    style, elegance, and state-of-the-art medical facilities.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="w-layout-blockcontainer container w-container"></div>
      </section>

      <Footer />
    </>
  );
}
