import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { useLocation } from "react-router-dom";
import PartnerProductCard from "../components/partnerProductCard";
import { Dense_button_dark, Dense_Button_Light } from "../components/buttons";

export default function PartnerSpotlight() {
  const location = useLocation();
  console.log(location.state);
  return (
    <>
      <Navbar />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xxl pad-top-m">{location.state.name}</div>
          <div className="header-xxl light">Partner Spotlight</div>
          <p className="body-l pad-top-m">{location.state.description}</p>
          <div className="partner-products-container pad-top-m">
            {location.state.products.map((product, index) => {
              return <PartnerProductCard key={index} {...product} />;
            })}
          </div>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xl">Why we Partner</div>
          <div className="header-xl light">With {location.state.name}</div>
          <p className="body-l pad-top-m">
            At MedSpa Network, we choose our partners carefully, and{" "}
            {location.state.name} stands out for their commitment to quality,
            safety, and innovation. Their products align perfectly with our
            mission to provide top-tier resources that help our members deliver
            outstanding patient care.
          </p>
          <div
            id="w-node-_8fe4ec08-ff30-22b0-df13-8f706664ce01-d9271105"
            className="w-layout-layout full-width-stack pad-top-m wf-layout-layout"
          >
            <div
              id="w-node-_13615ae6-f8fd-dfa3-af22-8ebbc4376d1f-d9271105"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Product Access</div>
              <p className="body-m pad-top-m">
                Access to {location.state.name}'s full portfolio of proven
                products that enhance your practice&#x27;s offerings.
              </p>
            </div>
            <div
              id="w-node-_13615ae6-f8fd-dfa3-af22-8ebbc4376d26-d9271105"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Trusted Assurance</div>
              <p className="body-m pad-top-m">
                The assurance of working with a brand that prioritizes patient
                safety and satisfaction.
              </p>
            </div>
            <div
              id="w-node-_13615ae6-f8fd-dfa3-af22-8ebbc4376d2d-d9271105"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Ongoing Education</div>
              <p className="body-m pad-top-m">
                Opportunities for ongoing education and training to keep you at
                the cutting edge of aesthetic medicine.
              </p>
            </div>
            <div
              id="w-node-_13615ae6-f8fd-dfa3-af22-8ebbc4376d34-d9271105"
              className="w-layout-cell featured-item"
            ></div>
          </div>
        </div>
      </section>
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light underline">
            Learn More About {location.state.name}
          </div>
          <div className="header-l pad-top-m">
            Interested in how {location.state.name} can Elevate Your Practice?
          </div>
          <div className="header-l light">
            Explore More About Their Innovative Products and Discover the
            Benefits of Integrating Their Solutions Into Your Aesthetic
            Services.
          </div>
          <Dense_Button_Light text="Get Membership" link="/membership" />
        </div>
      </section>
      <Footer />
    </>
  );
}
