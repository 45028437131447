import { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import DarkStaticNavBar from "../components/darkStaticNavBar";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function RohrerOrdering() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(null);
  const [formData, setFormData] = useState({});
  const [warning, setWarning] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmission = (e) => {
    e.preventDefault();

    setSubmitting(true);
    axios
      .post("/api/emails/rohrerOrder", {
        firstName: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        content: formData.packageSelection,
        address: formData.address,
      })
      .then((res) => {
        console.log(res.data);
        setWarning("Order was processed successfully");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((err) => {
        setWarning(JSON.stringify(err));
      });
  };

  return (
    <>
      <DarkStaticNavBar />
      <section className="rohrer-order-package-section">
        <div className="w-layout-blockcontainer w-container">
          {/* <h1>This promotion has closed</h1> */}
          <div className="w-form">
            <form
              id="email-form-2"
              name="email-form-2"
              data-name="Email Form 2"
              method="get"
              className="form-11"
              data-wf-page-id="65732df15dabdc12515a7700"
              data-wf-element-id="6ba2850f-aa61-dc4a-c1d3-7c1f30302bd2"
              onSubmit={(e) => {
                handleSubmission(e);
              }}
            >
              <div className="register-field-block">
                <label htmlFor="name" className="field-label-2">
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="name"
                  data-name="Name 3"
                  placeholder=""
                  id="name-3"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="email" className="field-label-2">
                  Email
                </label>
                <input
                  required
                  type="email"
                  className="register-field w-input"
                  maxLength="256"
                  name="email"
                  data-name="email"
                  placeholder=""
                  id="email"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="phoneNumber" className="field-label-2">
                  Phone Number
                </label>
                <input
                  required
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="phoneNumber"
                  data-name="phoneNumber"
                  placeholder=""
                  id="phoneNumber"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="address" className="field-label-2">
                  Full Shipping Address
                </label>
                <input
                  required
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="address"
                  data-name="address"
                  placeholder=""
                  id="address"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="notes" className="field-label-2">
                  Notes
                </label>
                <textarea
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="notes"
                  data-name="notes"
                  placeholder=""
                  id="notes"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="div-block-203">
                <div className="rohrer-available-credit">
                  Select your package.
                </div>
                <div className="div-block-205">
                  <div>
                    <label className="w-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        data-name="Checkbox"
                        className="w-checkbox-input"
                        checked={checked == 0 ? true : false}
                        onChange={() => {
                          setChecked(0);
                          setFormData((prevState) => ({
                            ...prevState,
                            packageSelection:
                              "Package 1 -  (2) Radiesse+ 3cc and (1) Belotero Balance+",
                          }));
                        }}
                      />
                      <span
                        className="checkbox-label w-form-label"
                        htmlFor="checkbox"
                      >
                        Package 1
                      </span>
                    </label>
                  </div>
                  <div className="rohrer-product-selection-container">
                    <ul role="list">
                      <li>
                        <div>(2) Radiesse+ 3cc</div>
                      </li>
                      <li>
                        <div>(1) Belotero Balance+ </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="div-block-205">
                  <div>
                    <label className="w-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox-2"
                        name="checkbox-2"
                        data-name="Checkbox 2"
                        className="w-checkbox-input"
                        checked={checked == 1 ? true : false}
                        onChange={() => {
                          setChecked(1);
                          setFormData((prevState) => ({
                            ...prevState,
                            packageSelection:
                              "Package 2 -  (1) Radiesse+ 3cc and (3) Belotero Balance+",
                          }));
                        }}
                      />
                      <span
                        className="checkbox-label w-form-label"
                        htmlFor="checkbox-2"
                      >
                        Package 2
                      </span>
                    </label>
                  </div>
                  <div className="rohrer-product-selection-container">
                    <ul role="list">
                      <li>
                        <div>(1) Radiesse+ 3cc </div>
                      </li>
                      <li>
                        <div>(3) Belotero Balance + </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="div-block-205">
                  <div>
                    <label className="w-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox-2"
                        name="checkbox-2"
                        data-name="Checkbox 2"
                        className="w-checkbox-input"
                        checked={checked == 2 ? true : false}
                        onChange={() => {
                          setChecked(2);
                          setFormData((prevState) => ({
                            ...prevState,
                            packageSelection:
                              "Package 1 -  (0) Radiesse+ 3cc and (6) Belotero Balance+",
                          }));
                        }}
                      />
                      <span
                        className="checkbox-label w-form-label"
                        htmlFor="checkbox-2"
                      >
                        Package 3
                      </span>
                    </label>
                  </div>
                  <div className="rohrer-product-selection-container">
                    <ul role="list">
                      <li>
                        <div>(0) Radiesse+ 3cc</div>
                      </li>
                      <li>
                        <div>(6) Belotero Balance + </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p style={{ fontSize: 22, color: "red" }}>{warning}</p>

              {checked == null ? null : submitting == true ? null : (
                <input
                  type="submit"
                  value="Submit"
                  data-wait="Please wait..."
                  className="rohrer-product-select-submit-button w-button"
                />
              )}
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
