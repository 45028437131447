import { selectClasses } from "@mui/material";
import { Dense_button_dark, Dense_Button_Light } from "../components/buttons";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import TestimonialsSection from "../components/testimonials-section";
import { useLayoutEffect, useState } from "react";

export default function AboutUs() {
  const [initialPosition, setInitialPosition] = useState({});
  const [yearData, setYearData] = useState({
    title: "2018",
    description: "Description for 2018",
  });

  function selectYear(year) {
    let buttons = document.getElementsByClassName("display-xxl");
    let points = document.getElementsByClassName("timeline-point");
    let timeline = document.getElementById("numbersAndPoints");
    for (let i = 0; i < buttons.length; i++) {
      if (buttons[i].innerText === year) {
        const button = buttons[i];
        const point = points[i];
        const movement = initialPosition.width * i;
        button.classList.add("selected");
        point.classList.add("selected");
        point.classList.remove("unselected");
        button.classList.remove("unselected");
        timeline.style.right = movement + 32 * i + "px";
      } else {
        buttons[i].classList.remove("selected");
        points[i].classList.remove("selected");
        points[i].classList.add("unselected");
        buttons[i].classList.add("unselected");
      }
    }
  }

  useLayoutEffect(() => {
    let firstPosition = document.getElementById("initialPosition");
    const position = firstPosition.getBoundingClientRect();
    console.log(position);
    setInitialPosition(position);
  }, []);

  return (
    <>
      <Navbar type="" />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">About Us</div>
          <div className="header-xxl pad-top-m">
            MedSpa Network: The Expertise
          </div>
          <div className="header-xxl light">
            Behind Your Professional Growth.
          </div>
          <p className="body-l pad-top-m">
            Since 2018, we have been at the forefront of providing comprehensive
            training to all licensed medical professionals, ensuring they have
            the knowledge and skills to deliver safe and effective treatments.
          </p>
          <div className="button-group">
            <Dense_button_dark link="/team" text="Meet the Team" />
            <Dense_Button_Light link="/events" text="View Events" />
          </div>
          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video w-video w-embed"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FX2rGgsqaq3A%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DX2rGgsqaq3A&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FX2rGgsqaq3A%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="green-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs title-underline light">
            Why Choose Medspa Network?
          </div>
          <div className="header-xl top-pad-m">
            We are one of the Fastest-Growing
          </div>
          <div className="header-xl light">
            Networks of Aesthetic Practitioners in the USA.
          </div>
          <div
            id="w-node-baf977d2-a00a-51e6-8143-d91326088cae-6df1ccd1"
            className="w-layout-layout full-width-stack pad-top-xl wf-layout-layout"
          >
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088caf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <div className="header-xs light">Years in Operation</div>
              <div>
                <div className="header">6 Yrs</div>
                <p className="body-s light">
                  Our mission is to help you achieve excellence by offering
                  high-quality education that enhances your skills and broadens
                  your expertise. Our vision is to be the leading name in
                  aesthetic training.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cb7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <div className="header-xs light">Trusted by Professionals</div>
              <div>
                <div className="header">2500</div>
                <p className="body-s light">
                  Over 1,000 medical professionals have trusted us to advance
                  their skills and knowledge, ensuring they can provide the
                  highest standard of care to their patients.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cbf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <div className="header-xs light">Industry Partnerships</div>
              <div>
                <div className="header">20+</div>
                <p className="body-s light">
                  We collaborate with over 20 leading industry partners, keeping
                  you connected to the latest innovations and best practices in
                  the field.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cc7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <div className="header-xs light">Training Programs</div>
              <div>
                <div className="header">50+</div>
                <p className="body-s light">
                  Offering over 50 specialized training programs, we cover every
                  aspect of aesthetic medicine, providing training for everyone
                  from aestheticians to plastic surgeons.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Mission and Vision</div>
          <div className="header-xl pad-top-l">
            MedSpa Network was Founded to Elevate
          </div>
          <div className="header-xl light">
            the Standards of Care in Aesthetic Medicine
          </div>
          <p className="body-l pad-top-m">
            Our mission is to help you achieve excellence by offering
            high-quality education that enhances your skills and broadens your
            expertise. Our vision is to be the leading name in aesthetic
            training, recognized for our commitment to safety, innovation, and
            your professional growth.
          </p>
          <div className="button-group">
            <Dense_button_dark link="/team" text="Meet the Team" />
            <Dense_Button_Light link="/events" text="View Events" />
          </div>
          <div
            id="w-node-_93b5f37f-02d4-6d75-2e62-f9e86e6cd4a8-6df1ccd1"
            className="w-layout-layout double-stack large-picture-container wf-layout-layout"
          >
            <div
              id="w-node-_93b5f37f-02d4-6d75-2e62-f9e86e6cd4a9-6df1ccd1"
              className="w-layout-cell"
            >
              <img
                src={require("../../assets/images/cadaverLab.png")}
                loading="lazy"
                alt=""
                className="large-photo-left"
              />
            </div>
            <div
              id="w-node-_93b5f37f-02d4-6d75-2e62-f9e86e6cd4aa-6df1ccd1"
              className="w-layout-cell cell-12"
            >
              <img
                src={require("../../assets/images/Rectangle-5-1-p-1080.png")}
                loading="lazy"
                alt=""
                className="large-photo-right"
              />
              <img
                src={require("../../assets/images/Rectangle-4-2.png")}
                loading="lazy"
                alt=""
                className="large-photo-right"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="light-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xl">
            We are Committed to Growing the Expertise
          </div>
          <div className="header-xl light">
            of Medical Professionals in Aesthetic Medicine.
          </div>
          <p className="body-l pad-top-m">
            MedSpa Network ensures you have the skills and knowledge to excel,
            offering comprehensive programs that equip you with the latest
            techniques and technologies in aesthetic medicine.
          </p>
          <div className="button-group">
            <Dense_button_dark link="/team" text="Meet the Team" />
            <Dense_Button_Light link="/membership" text="Get Membership" />
          </div>
          <div
            id="w-node-_29b8e1ec-e0e5-75cb-6319-a2f9f35d27f5-f35d27f5"
            className="w-layout-layout full-width-stack wf-layout-layout"
          >
            <div
              id="w-node-_29b8e1ec-e0e5-75cb-6319-a2f9f35d27f6-f35d27f5"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">
                Quality Education <br />
                &amp; Trainers
              </div>
              <p className="body-m pad-top-m">
                Gain hands-on training from renowned experts who bring
                real-world experience directly to you.
              </p>
            </div>
            <div
              id="w-node-_29b8e1ec-e0e5-75cb-6319-a2f9f35d27fd-f35d27f5"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">
                Comprehensive <br />
                Training
              </div>
              <p className="body-m pad-top-m">
                Whether you’re an RN, CRNA, ARNP, PA, or physician, our programs
                are designed to meet your specific needs and level of expertise.
              </p>
            </div>
            <div
              id="w-node-_29b8e1ec-e0e5-75cb-6319-a2f9f35d2804-f35d27f5"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">
                Innovative <br />
                Techniques
              </div>
              <p className="body-m pad-top-m">
                Stay at the forefront of aesthetic medicine with training that
                covers the latest advancements and best practices.
              </p>
            </div>
            <div
              id="w-node-_29b8e1ec-e0e5-75cb-6319-a2f9f35d280b-f35d27f5"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">
                Supportive
                <br />
                Community
              </div>
              <p className="body-m pad-top-m">
                Join a network of like-minded professionals where you can learn,
                grow, and thrive together.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="plum-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light underline">Our History</div>
          <div className="header-xl pad-top-l">
            Bridging the Training Gap Since 2018
          </div>
          <p className="body-l thin">
            Our founder, Dr. Alan Durkin, recognized a critical need for
            comprehensive training in aesthetic medicine, especially for
            practitioners outside traditional specialties like plastic surgery
            and dermatology. MedSpa Network was created to meet this need,
            providing a platform where talent and training are prioritized,
            allowing more professionals to excel in this dynamic field.
          </p>
        </div>
        <div
          className=" w-layout-blockcontainer container left-justify pad-top-m"
          style={{ overflow: "visible" }}
        >
          <div
            className=" w-layout-blockcontainer container left-justify"
            style={{
              position: "relative",
              transition: "all ease-in-out .5s",
              flexDirection: "row",
              gap: 32,
              overflow: "visible",
            }}
            id="numbersAndPoints"
          >
            <button
              id={"initialPosition"}
              className="display-xxl selected"
              style={{ position: "relative", transition: "ease-in-out 1s" }}
              onClick={() => {
                selectYear("2018");
                setYearData({
                  title: "2018",
                  description:
                    "Medspa Network is founded by Dr. Alan Durkin. Babor Skin care is introduced to the network.",
                });
              }}
            >
              2018
              <div
                className="timeline-point unselected"
                style={{
                  transition: "all ease-in-out 1s",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                }}
              ></div>
            </button>
            <button
              className="display-xxl unselected"
              style={{ position: "relative", transition: "ease-in-out 1s" }}
              onClick={() => {
                selectYear("2019");
                setYearData({
                  title: "2019",
                  description:
                    "Environ skin care, Salt Facial, Corganics CBD, Rohrer Lasers, Ultherapy and Influx Marketing are all brough to the network",
                });
              }}
            >
              2019
              <div
                className="timeline-point unselected"
                style={{
                  transition: "all ease-in-out 1s",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                }}
              ></div>
            </button>
            <button
              className="display-xxl unselected"
              style={{ position: "relative", transition: "ease-in-out 1s" }}
              onClick={() => {
                selectYear("2020");
                setYearData({
                  title: "2020",
                  description:
                    "Medspa Network Launches its first training session in Orlando, FL. The network also introduces the first ever virtual training session.",
                });
              }}
            >
              2020
              <div
                className="timeline-point unselected"
                style={{
                  transition: "all ease-in-out 1s",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                }}
              ></div>
            </button>
            <button
              className="display-xxl unselected"
              style={{ position: "relative", transition: "ease-in-out 1s" }}
              onClick={() => {
                selectYear("2021");
                setYearData({
                  title: "2021",
                  description: "Medspa Network launches 5 more online classes.",
                });
              }}
            >
              2021
              <div
                className="timeline-point unselected"
                style={{
                  transition: "all ease-in-out 1s",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                }}
              ></div>
            </button>
            <button
              className="display-xxl unselected"
              style={{ position: "relative", transition: "ease-in-out 1s" }}
              onClick={() => {
                selectYear("2022");
                setYearData({
                  title: "2022",
                  description:
                    "Medspa Network hit the 1000 people trained mark. We also start offering 1 on 1 trainings around the country.",
                });
              }}
            >
              2022
              <div
                className="timeline-point unselected"
                style={{
                  transition: "all ease-in-out 1s",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                }}
              ></div>
            </button>

            <button
              className="display-xxl unselected"
              style={{ position: "relative", transition: "ease-in-out 1s" }}
              onClick={() => {
                selectYear("2023");
                setYearData({
                  title: "2023",
                  description:
                    "Medspa Network start offering free webinars to the public.",
                });
              }}
            >
              2023
              <div
                className="timeline-point unselected"
                style={{
                  transition: "all ease-in-out 1s",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                }}
              ></div>
            </button>
            <button
              className="display-xxl unselected"
              style={{ position: "relative", transition: "ease-in-out 1s" }}
              onClick={() => {
                selectYear("2024");
                setYearData({
                  title: "2024",
                  description:
                    "Medspa Network start to become a global brand with trainings in China and Canada.",
                });
              }}
            >
              2024
              <div
                className="timeline-point unselected"
                style={{
                  transition: "all ease-in-out 1s",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                }}
              ></div>
            </button>
            <img
              onClick={() => {
                selectYear("2018");
                setYearData({
                  title: "2018",
                  description:
                    "Medspa Network is founded by Dr. Alan Durkin. Babor Skin care is introduced to the network.",
                });
              }}
              src={require("../../assets/icons/back-2.png")}
              style={{ width: 100, height: 100, zIndex: 99 }}
            />
          </div>
        </div>
        <div
          className="timeline-line"
          style={{ marginTop: 6, zIndex: 0 }}
        ></div>
        <div className="w-layout-blockcontainer container pad-top-large  left-justify">
          {/* <div className="header-xl light" style={{transition: "all ease-in-out 1s"}}>{yearData.title}</div> */}
          <p
            className="body-l pad-top-m"
            style={{
              transition: "all ease-in-out 1s",
              position: "absolute",
              top: "100%",
            }}
          >
            {yearData.description}
          </p>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Get Started Today</div>
          <div className="header-l pad-top-m">
            Get Your Membership and Take the Next Step in Your Career.
          </div>
          <div className="header-l light">
            Stay Ahead in the Fast-Evolving Field of Aesthetic Medicine,
            Ensuring you Have Skills, Knowledge, and Connections Needed to
            Excel.
          </div>
          <div className="button-group">
            <Dense_button_dark link="/membership" text="Join Us Today" />
            <Dense_Button_Light link="/team" text="More about us" />
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </>
  );
}
