import axios from "axios";
import { useEffect, useState } from "react";
import AttendeeCard from "../../components/attendeeCard";

export default function ViewAttendees() {
  const [attendees, setAttendees] = useState([]);
  let url;

  useEffect(() => {
    axios
      .get("/api/v2/Website/accounts/attendees")
      .then((res) => {
        console.log(res);
        setAttendees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const cards = attendees.map((selectedAttendee, index) => {
    return <AttendeeCard key={index} props={selectedAttendee} index={index} />;
  });

  return (
    <>
      <h1>Total Attendees: {attendees.length}</h1>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        {cards}
      </div>
    </>
  );
}
