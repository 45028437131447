import axios from "axios";

export default function WebinarTest() {
  axios
    .post("/api/v2/Website/events/webinarTest")
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });

  return null;
}
