import { useEffect, useRef } from "react";
import ReactHlsPlayer from "react-hls-player";

export default function VideoPlayer({ props, callback }) {
  const playerRef = useRef();

  useEffect(() => {
    playerRef.current.addEventListener("ended", () => {
      callback();
    });
  }, []);

  const decodedVideoURL = decodeURIComponent(props.videoURL);

  return (
    <ReactHlsPlayer
      playerRef={playerRef}
      src={`/videos/${decodedVideoURL}/master.m3u8`}
      autoPlay={false}
      controls={true}
      width="100%"
      height="auto"
    />
  );
}
