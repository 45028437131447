import axios from "axios";
import * as tf from "@tensorflow/tfjs";

export default function PredictCancer() {
  const CATEGORIES = [
    "actinic keratosis",
    "basal cell carcinoma",
    "dermatofibroma",
    "melanoma",
    "nevus",
    "pigmented benign keratosis",
    "squamous cell carcinoma",
    "vascular lesion",
  ];

  async function predictImage(image) {
    const model = await tf.loadLayersModel("../../assets/model.json");
    const prediction = model.predict(image);

    // axios
    //   .post("http://localhost:5252/api/tensorflow/predictImage", formData)
    //   .then((response) => {
    //     console.log(response.data);
    //     const prediction = CATEGORIES[response.data];
    //     console.log(prediction);
    //   });
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h1>Predict Cancer</h1>

      <input type="file" onChange={(e) => predictImage(e)} />
    </div>
  );
}
