import { Dense_Button_Light } from "./buttons";

export default function EventCardType5({ title, learningOptions, link }) {
  return (
    <div className="event-card-type-2">
      <div className="featured-event-title-bar">
        <div className="featured-event-title">
          <div className="header-s">{title}</div>
        </div>
      </div>
      <div className="featured-event-details-bar">
        <div className="div-block-50">
          {learningOptions.map((option, index) => {
            return (
              <div className="badge" key={index}>
                <div className="eyebrow s light-aurora">{option}</div>
              </div>
            );
          })}
        </div>
        <Dense_Button_Light link={link} text="Learn More" />
      </div>
    </div>
  );
}
