import { useLocation } from "react-router-dom";
import AccountNav from "../../components/accountNav";
import Footer from "../../components/footer";
import Navbar from "../../components/navigations";
import moment from "moment";
import { useState } from "react";

export default function Payments() {
  const location = useLocation();
  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);
  const [amountOfPaymentsToDisplay, setAmountOfPaymentsToDisplay] = useState(5);

  return (
    <>
      <Navbar />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="account-stack">
            <AccountNav currentPage={location.pathname} user={user} />
            <div className="account-right-side">
              <div className="header-xxl">My Account</div>
              <div className="account-right-side-container">
                <div className="header-m">Payments</div>
                <div>
                  <div className="header-xs title-underline">
                    Payment History
                  </div>
                  {user.payments
                    .slice(0, amountOfPaymentsToDisplay)
                    .map((payment, index) => {
                      return (
                        <div key={index} className="account-payment-card">
                          <div className="account-payment-reason-container">
                            <p className="body-m">{payment.reason}</p>
                            <p className="caption-xs">
                              Paid: {moment(payment.date).format("MM/DD/YY")}
                            </p>
                          </div>
                          <div className="account-payment-details-container">
                            <p className="body-s bold">
                              {payment.amount.toLocaleString("usd", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  {user.payments.length > 5 ? (
                    <button
                      className="button m dark center margin-top-m"
                      onClick={() => {
                        setAmountOfPaymentsToDisplay(
                          amountOfPaymentsToDisplay + 5
                        );
                      }}
                    >
                      Load More
                    </button>
                  ) : null}
                </div>
                <div>
                  <div className="header-xs title-underline">
                    Billing Information
                  </div>
                  <div className="w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      data-wf-page-id="6719a9bce6abbbb5f2904896"
                      data-wf-element-id="3c5994e0-bdde-a6bc-513c-2999daca480d"
                    >
                      <div className="field-group">
                        <label htmlFor="name" className="field-label-2">
                          Name on card
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="name"
                          data-name="name"
                          placeholder="Arya Stark"
                          type="text"
                          id="name"
                          required=""
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="lastName-2" className="field-label-2">
                          Address
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="lastName-2"
                          data-name="Last Name 2"
                          placeholder="Stark"
                          type="text"
                          id="lastName-2"
                          required=""
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="email-2" className="field-label-2">
                          City
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="billingCity"
                          data-name="billingCity"
                          placeholder="Vero Beach"
                          type="text"
                          id="billingCity"
                          required=""
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="billingState" className="field-label-2">
                          State (**)
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="billingState"
                          data-name="billingState"
                          placeholder="FL"
                          type="text"
                          id="billingState"
                          required=""
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="billingZipCode"
                          className="field-label-2"
                        >
                          Zip Code
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="billingZipCode"
                          data-name="billingZipCode"
                          placeholder="123456789"
                          type="text"
                          id="billingZipCode"
                          required=""
                        />
                      </div>
                      <input
                        type="submit"
                        data-wait="Please wait..."
                        className="button-dense-primary-light "
                        value="Save Changes"
                      />
                    </form>
                  </div>
                </div>
                <div>
                  <div className="header-xs title-underline">
                    Payment Method
                  </div>
                  <div className="w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      data-wf-page-id="6719a9bce6abbbb5f2904896"
                      data-wf-element-id="564241c4-ff0d-76ce-2cff-356f9a088e59"
                    >
                      <div className="field-group">
                        <label htmlFor="nameOnCard" className="field-label-2">
                          Cardholder Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="nameOnCard"
                          data-name="nameOnCard"
                          placeholder="Enter Card Holders Name"
                          type="text"
                          id="nameOnCard"
                          required=""
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="cardNumber" className="field-label-2">
                          Card Number
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="cardNumber"
                          data-name="cardNumber"
                          placeholder="XXXX XXXX XXXX XXXX"
                          type="text"
                          id="cardNumber"
                          required=""
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="expirationDate"
                          className="field-label-2"
                        >
                          Expiration Date (MMYYYY)
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="expirationDate"
                          data-name="expirationDate"
                          placeholder="MM/YYYY"
                          type="text"
                          id="expirationDate"
                          required=""
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="cvv" className="field-label-2">
                          CVV
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="cvv"
                          data-name="cvv"
                          placeholder="XXXX"
                          type="text"
                          id="cvv"
                          required=""
                        />
                      </div>
                      <input
                        type="submit"
                        data-wait="Please wait..."
                        className="button-dense-primary-light "
                        value="Save Changes"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
