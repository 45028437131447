import { Dense_button_dark } from "../components/buttons";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export default function CourseCompletion() {
  return (
    <>
      <Navbar />
      <section
        className="white-background-section"
        style={{
          backgroundImage: `https://lottie.host/561c0b9c-79fb-4ec5-b976-58dba285b891/I1KUE6kKrN.json`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <div className="w-layout-blockcontainer container">
          <div className="center">
            <DotLottieReact
              src="https://lottie.host/561c0b9c-79fb-4ec5-b976-58dba285b891/I1KUE6kKrN.json"
              loop
              autoplay
            />
            <h1 className="header-xxl">Congrats!</h1>
            <h2>You completed the course</h2>
          </div>
          <div className="button-group">
            <Dense_button_dark
              text="Go back to Courses"
              link="/online-courses"
            />
            <Dense_button_dark
              text="Check out your Certificate"
              link="/certificates"
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
