import axios, { all } from "axios";
import { set } from "lodash";
import { useEffect, useState } from "react";

export default function Test() {
  const [details, setDetails] = useState({
    data: {
      page: "Specials",
    },
  });
  const [errMessage, setErrMessage] = useState("");
  let url;

  async function sendPushNotification(e) {
    e.preventDefault();
    if (details.password == "Kickingit1!") {
      console.log("running");
      axios
        .post("/api/pushNotifications/getTokens", details)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(err);
        });
    } else {
      setErrMessage("Incorrect Password");
    }
  }

  function handleVerifyEmail() {
    console.log("test");
    axios
      .post("http://localhost:5252/api/user/verificationEmailSend")
      .then((res) => {
        console.log(res.data);
      });
  }

  function handleMandatoryEmail() {
    // console.log("test");
    // axios.post("http://localhost:5252/api/user/mandatoryEmail").then((res) => {
    //   console.log(res.data);
    // });
  }

  function handleCodeOfConduct() {
    // console.log("test");
    // axios.post("http://localhost:5252/api/user/codeOfConduct").then((res) => {
    //   console.log(res.data);
    // });
  }

  function checkin() {
    console.log("test");
    axios.post("http://localhost:5252/api/user/checkin").then((res) => {
      console.log(res.data);
    });
  }

  function handleSurveySend() {
    console.log("test");
    axios.post("http://localhost:5252/api/user/survey").then((res) => {
      console.log(res.data);
    });
  }

  function handleTestAuthorizeNet() {
    console.log("test auth net");
    axios
      .post("http://localhost:5252/api/authorizeNet/process-one-time-payment")
      .then((res) => {
        console.log(res.data);
      });
  }

  async function handleTestAuthorizeNetSubscription() {
    // console.log("test auth net Subscription");
    // axios
    //   .post("http://localhost:5252/api/authorizeNet/process-subscription", {
    //     customerId: "",
    //     firstName: "",
    //     lastName: "",
    //     email: "",
    //     phoneNumber: "",
    //     companyName: "",
    //     subscriptionType: "",
    //     cardNumber: 4111111111111111,
    //     cardExpiration: 1234,
    //     billingAddress: "",
    //     billingState: "",
    //     billingCity: "",
    //     billingZipCode: "",
    //   })
    //   .then((res) => {
    //     console.log(res.data);
    //     if (res.data.ok == false) {
    //       setErrMessage(res.data.response);
    //     }
    //   });
  }

  function getContacts() {
    axios
      .post(
        "http://localhost:5252/api/hubspot/insert-new-contact-with-company-association",
        {
          firstName: "Keaton",
          lastName: "Benning",
          email: "keaton@oceandrive.org",
          company: "Ocean Drive",
          website: "www.oceandriveplasticsurgery.com",
          phoneNumber: 7728280628,
        }
      )
      .then((res) => {
        console.log(res.data);
      });
  }

  useEffect(() => {
    // getContacts();
  }, []);

  //RETURN
  //
  //
  return (
    <section className="section-34">
      <div className="w-layout-blockcontainer container-9 w-container">
        <div className="w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            method="get"
            className="form-12"
            data-wf-page-id="65aa92657053b49b5cb87ed2"
            data-wf-element-id="f28f8475-c18d-bfae-0476-53bcaaa327e4"
            onSubmit={sendPushNotification}
          >
            <div>
              <label for="name">Title</label>
              <input
                className="w-input"
                maxLength="50"
                name="name"
                data-name="Name"
                placeholder=""
                type="text"
                id="name"
                required={true}
                onChange={(e) =>
                  setDetails({ ...details, title: e.target.value })
                }
              />
            </div>
            <div>
              <label for="password">Password</label>
              <input
                className="w-input"
                maxLength="256"
                name="password"
                data-name="password"
                placeholder=""
                type="text"
                id="password"
                required={true}
                onChange={(e) =>
                  setDetails({ ...details, password: e.target.value })
                }
              />
            </div>
            <div>
              <label for="field">Details</label>
              <textarea
                required={true}
                placeholder="Example Text"
                maxLength="128"
                id="field"
                name="field"
                data-name="Field"
                className="w-input"
                onChange={(e) =>
                  setDetails({ ...details, body: e.target.value })
                }
              ></textarea>
            </div>
            <div className="text-block-81">{errMessage}</div>
            <input
              type="submit"
              data-wait="Please wait..."
              className="w-button"
              value="Submit"
            />
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </section>
  );
}
