import { useEffect, useState } from "react";
import { GetBlogById } from "../../services/blogServices";
import Navbar from "../components/navigations";
import Footer from "../components/footer";
import moment from "moment";
import { JsonLd } from "react-schemaorg";
import { useLocation } from "react-router-dom";

export default function Article() {
  const _id = window.location.pathname.split("/")[2];
  const location = useLocation();
  console.log(_id);
  const [blog, setBlog] = useState();

  async function getBlogData() {
    const data = await GetBlogById(_id);
    setBlog(data);
  }

  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "@id": location.pathname,
          headline: blog?.fields.title,
          name: blog?.fields.title,
          description: blog?.fields.content.content[0].content[0].value,
          about: "Aesthetics Trainings and Events",
          author: blog?.fields.author,
          image: {
            "@type": "ImageObject",
            "@id": blog?.fields.banner.fields.file.url,
            url: blog?.fields.banner.fields.file.url,
            height: "362",
            width: "388",
          },
        }}
      />
      <Navbar />
      <section
        className="white-background-section"
        itemscope
        itemtype="https://schema.org/Article"
      >
        <div className="w-layout-blockcontainer container left-justify">
          <div className="header-xs underline">Article</div>
          {
            <div key={blog?.sys.id}>
              <img
                src={blog?.fields.banner.fields.file.url}
                style={{
                  maxHeight: "40vh",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
              <h2 className="header-m" itemprop="name">
                {blog?.fields.title}
              </h2>
              <h5 className="header-xs" itemprop="author">
                Author(s): {blog?.fields.author}
              </h5>
              <h5 className="header-xs" itemprop="datePublished">
                Published:{" "}
                {moment(blog?.fields.publishDate).format("MMM DD YYYY")}
              </h5>
              <div itemprop="description">
                {blog?.fields.content.content.map((contentContainer) => {
                  return contentContainer.content.map((node, index) => {
                    if (node.nodeType === "text") {
                      return (
                        <p
                          key={index}
                          className="body-l pad-top-m"
                          style={{
                            fontSize: 16,
                            lineHeight: 1.5,
                            width: "75ch",
                            fontWeight: node.marks.find(
                              (mark) => mark.type === "bold"
                            )
                              ? "bold"
                              : "normal",
                          }}
                        >
                          {node.value}
                        </p>
                      );
                    }
                    if (node.nodeType === "embedded-asset-block") {
                      return (
                        <img
                          key={index}
                          src={node.data.target.fields.file.url}
                          alt={node.data.target.fields.title}
                        />
                      );
                    }
                  });
                })}
              </div>
            </div>
          }
        </div>
      </section>
      <Footer />
    </>
  );
}
