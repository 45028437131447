import {
  Dense_button_dark,
  Dense_Button_Light,
  Medium_button_secondary_dark,
  Medium_button_secondary_light,
  Primary_button_dark,
  Primary_button_light,
} from "../components/buttons";
import Footer from "../components/footer";
import Navbar from "../components/navigations";

export default function Error() {
  return (
    <>
      <Navbar type="dark" />
      <div className="dark-background-section full-height center">
        <div className="w-layout-blockcontainer container left-justify w-container">
          <div className="header-xxl">Error 404</div>
          <div className="header-xxl">
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </div>
          <div className="button-group">
            <Dense_button_dark text="Retry" link="" />
            <Dense_Button_Light text="Back to Home" link="/" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
