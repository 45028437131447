import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import axios from "axios";
import moment from "moment";
import Navbar from "../components/navigations";
import Footer from "../components/footer";

ReactGA.initialize("G-CVD1XQ5B0K");

export default function WebinarRegistration() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const urlParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlParams.entries());
  console.log(params);

  async function handleSubmit(e) {
    e.preventDefault();
    // setProcessing(true);
    console.log("register");
    axios
      .post("/api/v2/Website/events/zoom-event-registration", {
        formData: userDetails,
        eventData: params,
      })
      .then((res) => {
        if (res.data.status == "success") {
          console.log(res.data);
          // Send a sign up event hit to Google Analytics
          ReactGA.send({ hitType: "sign_up", method: params.search });

          // Then forward user to the Event Thank You Page
          ////////////////////////////////////////////////////
          navigate("/eventThankYou", {
            state: {
              formData: userDetails,
              eventDetails: params,
              pathname: location.pathname,
            },
          });
        } else {
          console.log(res.data.message);
        }
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container">
          <div className="vertical">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form"
              data-wf-page-id="66f4597a34ff7685625ce5d6"
              data-wf-element-id="5dd738e7-aa57-5745-4a0a-f5575b02dd00"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <h1 className="heading-4">{"Live Webinar"}</h1>
              <div
                id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a89-625ce5d6"
                className="w-layout-layout quick-stack-10 wf-layout-layout"
              >
                <div
                  id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a8a-625ce5d6"
                  className="w-layout-cell "
                >
                  <div className="pricing-card dark">
                    <div className="pricing-card-top">
                      <div
                        className="header-s light"
                        style={{ color: "white" }}
                      >
                        {"1 Hour"}
                      </div>
                      <div
                        className="header-s light"
                        style={{ color: "white" }}
                      >
                        {params.eventTitle}
                      </div>
                      <div className="display-xl light">Free</div>

                      <div className="badge">
                        <img
                          src={require("../../assets/icons/calendar-2.png")}
                          loading="lazy"
                          alt=""
                          className="tag-icon"
                        />
                        <div className="tag-text">
                          {moment(params.eventStartDate).format(
                            "MMM DD hh:mm A "
                          ) + "est"}
                        </div>
                      </div>
                    </div>
                    <div className="pricing-card-bottom">
                      <div className="body-s light">Includes</div>
                      <div className="pricing-card-bottom-bullet-container">
                        <img
                          loading="lazy"
                          src={require("../../assets/icons/next.png")}
                          alt=""
                          className="pricing-card-bullet-icon"
                        />
                        <div className="body-s light">
                          Expert Led Presentation
                        </div>
                      </div>
                      <div className="pricing-card-bottom-bullet-container">
                        <img
                          loading="lazy"
                          src={require("../../assets/icons/next.png")}
                          alt=""
                          className="pricing-card-bullet-icon"
                        />
                        <div className="body-s light">Webinar Recording</div>
                      </div>
                      <div className="pricing-card-bottom-bullet-container">
                        <img
                          loading="lazy"
                          src={require("../../assets/icons/next.png")}
                          alt=""
                          className="pricing-card-bullet-icon"
                        />
                        <div className="body-s light">Real-time feedback</div>
                      </div>
                      <div className="pricing-card-bottom-bullet-container">
                        <img
                          loading="lazy"
                          src={require("../../assets/icons/next.png")}
                          alt=""
                          className="pricing-card-bullet-icon"
                        />
                        <div className="body-s light">Questions and Answer</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a8b-625ce5d6"
                  className="w-layout-cell masterclass-checkout-right-cell"
                >
                  <div className="form-block w-form">
                    <div className="form-2-fields">
                      <div className="field-group">
                        <label htmlFor="name" className="field-label-2">
                          First Name
                        </label>
                        <input
                          className="checkout-field"
                          maxLength="256"
                          name="First-Name"
                          data-name="First Name"
                          placeholder="First Name"
                          type="text"
                          id="First-Name"
                          required={true}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              first_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="" className="field-label-2">
                          Last Name
                        </label>
                        <input
                          className="checkout-field "
                          maxLength="256"
                          name="Last-Name"
                          data-name="Last Name"
                          placeholder="Last Name"
                          type="text"
                          id="Last-Name"
                          required={true}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              last_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="field-group">
                      <label htmlFor="Email" className="field-label-2">
                        Email Address
                      </label>
                      <input
                        className="checkout-field "
                        maxLength="256"
                        name="Email"
                        data-name="Email"
                        placeholder="Enter your Email Address"
                        type="email"
                        id="Email"
                        required={true}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="mobile" className="field-label-2">
                        Phone Number
                      </label>
                      <input
                        className="checkout-field "
                        maxLength="256"
                        name="mobile"
                        data-name="mobile"
                        placeholder="Phone Number"
                        type="tel"
                        id="mobile"
                        required={true}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            phone: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="name-3" className="field-label-2">
                        Company Name
                      </label>
                      <input
                        className="checkout-field "
                        maxLength="256"
                        name="company"
                        data-name="company"
                        placeholder="Example"
                        type="text"
                        id="company"
                        required={true}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            org: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="" className="field-label-2">
                        Company Website
                      </label>
                      <input
                        className="checkout-field "
                        maxLength="256"
                        name="website"
                        data-name="website"
                        placeholder="www.example.com"
                        type="text"
                        id="website"
                        required={true}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            website: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="" className="field-label-2">
                        Informed Consent
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 12,
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <input
                          name="website"
                          data-name="website"
                          type="checkbox"
                          required={true}
                          style={{ width: "20px", padding: 0, margin: 0 }}
                        />
                        <p>
                          I acknowledge that my phone number will be used to
                          updated me on the status of the webinar.
                        </p>
                      </div>
                    </div>
                    <div className="div-block-31">
                      <input
                        style={{ cursor: "pointer" }}
                        type="submit"
                        value="Register"
                        data-wait="Please wait..."
                        className="button-dense-primary-dark "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
