import axios from "axios";
import { forEach } from "lodash";
import { useEffect, useState } from "react";
import Modal from "react-modal";

export default function TestSpecials() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [specials, setSpecials] = useState([]);
  const [typeOfAvailableSpecials, setTypeOfAvailableSpecials] = useState([]);
  const [reasonSelections, setReasonSelections] = useState([]);
  const [reasonId, setReasonId] = useState({});
  const [attachedSchedules, setAttachedSchedules] = useState([]);
  const [formData, setFormData] = useState({
    bullets: [],
    title: "",
    type: "",
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    axios
      .get("/api/v2/Ocean-Drive-App/specials/get-specials")
      .then((res) => {
        setSpecials(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getData(link) {
    switch (link) {
      case "plastics":
        return [2878, "e5394ad71f"];

      case "derm":
        return [2903, "aa8e428ab7"];

      case "medspa":
        return [2902, "34a25797cb"];

      case "plastics-flash":
        return [3661, "cfab4b9ae9"];

      case "spa-flash":
        return [3926, "349d977e84"];

      case "derm-flash":
        return [3925, "632cb133d1"];

      default:
        break;
    }
  }

  //   `https://nextpatient.co/api/${practiceIdentifiers[0]}/${practiceIdentifiers[1]}/appointments?reason_id=${schedule.reason_id}`;

  async function selectPractice(e) {
    const practiceIdentifiers = getData(e.target.value);
    setFormData((prevState) => ({
      ...prevState,
      scheduleLink: e.target.value,
    }));

    axios
      .get(
        `https://nextpatient.co/api/${practiceIdentifiers[0]}/${practiceIdentifiers[1]}/visit-reasons`
      )
      .then((res) => {
        setTypeOfAvailableSpecials(res.data);
      });
  }

  async function setSelectedType(e) {
    setReasonSelections(typeOfAvailableSpecials[e.target.value].reasons);
    setFormData((prevState) => ({
      ...prevState,
      group: e.target.value.group_name,
    }));
  }

  async function setSelectedFlashSaleType(e) {
    setReasonId(JSON.parse(e.target.value));
    setFormData((prevState) => ({
      ...prevState,
      reason_id: JSON.parse(e.target.value).reason_id,
    }));
  }

  async function attachSchedule(e) {
    e.preventDefault();
    setAttachedSchedules((prevState) => [
      ...prevState,
      {
        scheduleLink: formData.scheduleLink,
        reason_id: formData.reason_id,
        group: formData.group,
      },
    ]);
    closeModal();
  }

  async function removeSchedule(e) {
    setAttachedSchedules((prevState) =>
      prevState.filter((schedule, index) => index !== e)
    );
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function sendPushNotification(e) {
    e.preventDefault();
    // if (details.password == "Kickingit1!") {
    //   console.log("running");
    //   axios
    //     .post(
    //       "/api/pushNotifications/getTokens",
    //       details
    //     )
    //     .then((res) => {
    //       console.log(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       setErrMessage(err);
    //     });
    // } else {
    //   setErrMessage("Incorrect Password");
    // }
  }

  async function handleFlashSaleSubmission() {
    axios
      .post("/api/v2/Ocean-Drive-App/specials/add-special", {
        formData: formData,
        attachedSchedules: attachedSchedules,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function deleteFlashSaleFromApp(e) {
    axios
      .post("/api/v2/Ocean-Drive-App/specials/remove-special", {
        title: e,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Create a new special"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
          Tell me more about this special
        </h2>
        <button
          onClick={() => {
            closeModal();
          }}
          style={{ position: "absolute", top: 20, right: 20, fontSize: 22 }}
        >
          close
        </button>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            width: "100%",
            gap: 40,
          }}
        >
          <div style={{ width: "100%" }}>
            <label style={{ width: "100%" }}>
              Which practice is this special for?
            </label>
            <select
              name="practice"
              defaultValue="Choose One"
              onChange={(e) => {
                selectPractice(e);
              }}
            >
              <option disabled>Choose One</option>
              <option value="plastics-flash">Plastics</option>
              <option value="derm-flash">Dermatology</option>
              <option value="spa-flash">Medspa</option>
            </select>
          </div>
          <div>
            <label>What kind of special is it?</label>
            <select
              defaultValue="Choose One"
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  group: typeOfAvailableSpecials[e.target.value].group_name,
                }));
                setReasonSelections(
                  typeOfAvailableSpecials[e.target.value].reasons
                );
              }}
            >
              <option disabled>Choose One</option>
              {typeOfAvailableSpecials.map((type, index) => {
                return (
                  <option key={index} value={index}>
                    {type.group_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label>
              Even more specifically, what kind? If you dont know, just click
              Flash Sale. This helps us setup the proper appointment in Nextech.
            </label>
            <select
              defaultValue="Choose One"
              onChange={(e) => {
                setSelectedFlashSaleType(e);
                setFormData((prevState) => ({
                  ...prevState,
                  reason_id: JSON.parse(e.target.value).reason_id,
                }));
              }}
            >
              <option disabled>Choose One</option>
              {reasonSelections.map((reason, index) => {
                return (
                  <option key={index} value={JSON.stringify(reason)}>
                    {reason.reason_name}
                  </option>
                );
              })}
            </select>
          </div>

          <button
            style={{
              backgroundColor: "pink",
              padding: 20,
              width: 250,
              borderRadius: 10,
            }}
            value="Attach Schedule"
            onClick={(e) => {
              attachSchedule(e);
            }}
          >
            Add Schedule
          </button>
        </div>
      </Modal>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <section style={{ backgroundColor: "lightgrey", padding: 30 }}>
          <h1>There are {specials.length} currently running flash sales </h1>
          {specials.map((special, index) => {
            return (
              <div
                key={index}
                style={{
                  width: 200,
                  height: 200,
                  backgroundColor: "white",
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  border: "1px solid black",
                  position: "relative",
                }}
              >
                <button
                  style={{
                    color: "red",
                    position: "absolute",
                    top: 20,
                    right: 20,
                  }}
                  onClick={() => {
                    deleteFlashSaleFromApp(special.title);
                  }}
                >
                  X
                </button>
                <h2>{special.title}</h2>
                <p>{special.type}</p>
                <p>{special.scheduleLink}</p>
              </div>
            );
          })}
        </section>
        <section style={{ backgroundColor: "lightblue", padding: 30 }}>
          <h1>Add a Special</h1>
          <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
            <div>
              <label>Whats the title of the sale?</label>
              <input
                required
                type="text"
                placeholder="Title"
                name="title"
                style={{ padding: 10 }}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }));
                }}
              />
            </div>
            <div
              style={{ flexDirection: "column", display: "flex", width: 200 }}
            >
              <label>Whats the special about?</label>
              <input
                required
                placeholder={"Give some info"}
                onChange={(e) => {
                  let newArray = [
                    e.target.value,
                    formData.bullets[1],
                    formData.bullets[2],
                    formData.bullets[3],
                  ];
                  setFormData((prevState) => ({
                    ...prevState,
                    bullets: newArray,
                  }));
                }}
              />
              <input
                placeholder={"Give some info"}
                onChange={(e) => {
                  let newArray = [
                    formData.bullets[0],
                    e.target.value,
                    formData.bullets[2],
                    formData.bullets[3],
                  ];
                  setFormData((prevState) => ({
                    ...prevState,
                    bullets: newArray,
                  }));
                }}
              />
              <input
                placeholder={"Give some info"}
                onChange={(e) => {
                  let newArray = [
                    formData.bullets[0],
                    formData.bullets[1],
                    e.target.value,
                    formData.bullets[3],
                  ];
                  setFormData((prevState) => ({
                    ...prevState,
                    bullets: newArray,
                  }));
                }}
              />
              <input
                placeholder={"Give some info"}
                onChange={(e) => {
                  let newArray = [
                    formData.bullets[0],
                    formData.bullets[1],
                    formData.bullets[2],
                    e.target.value,
                  ];
                  setFormData((prevState) => ({
                    ...prevState,
                    bullets: newArray,
                  }));
                }}
              />
            </div>
            <div>
              <label>App special or Call in special?</label>
              <select
                required
                defaultValue="Choose One"
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    type: e.target.value,
                  }));
                }}
                style={{ padding: 10 }}
              >
                <option disabled>Choose One</option>
                <option value="App">App</option>
                <option value="Call">Call</option>
              </select>
            </div>
          </div>
          {formData.type == "App" ? (
            <div>
              <h1>Attached Schedules</h1>
              <div style={{ display: "flex", flexDirection: "row", gap: 30 }}>
                {attachedSchedules.map((schedule, index) => {
                  return (
                    <div
                      style={{
                        width: 400,
                        height: 200,
                        backgroundColor: "white",
                        borderRadius: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        border: "1px solid black",
                        position: "relative",
                      }}
                    >
                      <p>{schedule.scheduleLink}</p>
                      <button
                        style={{ position: "absolute", top: 20, right: 20 }}
                        onClick={() => {
                          removeSchedule(index);
                        }}
                      >
                        <p style={{ color: "red" }}>X</p>
                      </button>
                    </div>
                  );
                })}
                <button
                  onClick={() => {
                    openModal();
                  }}
                  style={{
                    width: 400,
                    height: 200,
                    backgroundColor: "lightgrey",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p>+</p>
                  <p>Attach a practice to this special</p>
                </button>
              </div>
            </div>
          ) : null}
          <input
            onClick={() => handleFlashSaleSubmission()}
            style={{
              backgroundColor: "grey",
              padding: 10,
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
              width: 200,
            }}
            placeholder="Create Special"
            type="submit"
          />
        </section>
      </div>
    </>
  );
}
