import axios from "axios";
const userData = localStorage.getItem("user");
const user = JSON.parse(userData);

export default function Courses() {}

export async function GetBlogs() {
  const res = await axios.get("/api/v2/Website/blog/get-all");
  return res.data.data.items;
}

export async function GetBlogById(id) {
  const res = await axios.get(`/api/v2/Website/blog/get-blog-by-id`, {
    params: { _id: id },
  });
  return res.data.data;
}
