import { useState, useEffect, useRef } from "react";
import Footer from "../components/footer";
import NavBar from "../components/navBar";
import axios from "axios";

export default function RequestTraining() {
  const myRef = useRef(null);
  const [form, setForm] = useState({});
  const [formSuccess, setFormSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function setField(e) {
    setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  }

  async function submitForm(e) {
    e.preventDefault();

    axios
      .post("/api/emails/requestTraining", form)
      .then((res) => {
        console.log(res.data);
        setFormSuccess(true);
        setMessage(
          "Your request has been submitted, we will be in touch as soon as possible!"
        );
        const successSection = document.getElementById("success");
        if (successSection) {
          myRef.current.scrollIntoView();
        }
      })
      .catch((err) => {
        console.log(err);
        setFormSuccess(true);
        setMessage(
          "We are sorry, we could not process your request. Please call Michele Woodhouse at 772-258-9650"
        );
        const successSection = document.getElementById("success");
        if (successSection) {
          myRef.current.scrollIntoView();
        }
      });
  }

  return (
    <>
      <section className="section-2">
        <NavBar />
        <div className="div-block-13">
          <h1 className="hero-heading">Request a training</h1>
        </div>
      </section>
      <section className="two-column-section">
        <div className="half-block">
          <div className="half-paragraph-container">
            <h3 className="heading">
              Choosing MedSpa Network for private training at your practice is a
              strategic investment in elevating your team&#x27;s expertise and
              the quality of care you provide.
            </h3>
            <p className="bold-paragraph">
              Led by nationally renowned, board-certified plastic surgeon, Dr.
              Alan Durkin, MedSpa Network trainings bring years of hands-on
              experience to your doorstep, tailoring a curriculum to address
              your specific needs and goals.
            </p>
            <p className="paragraph">
              By partnering with MedSpa Network, you can ensure that your staff
              is up-to-date with the latest injector techniques and industry
              best practices, enhancing their skills and confidence. MedSpa
              Network private trainings empowers your team to deliver
              exceptional patient experiences, ultimately boosting client
              satisfaction and loyalty. Elevate your practice to new heights by
              booking a private training session with MedSpa Network today, and
              unlock the potential for growth and success in the competitive
              field of medical aesthetics.
            </p>
          </div>
        </div>
        <div className="half-block">
          <img
            src="images/Rectangle-4-6.png"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 37vw"
            srcSet="images/Rectangle-4-6-p-500.png 500w, images/Rectangle-4-6.png 765w"
            alt=""
            className="large-image"
          />
        </div>
      </section>
      <section className="section training-form-section">
        <h2 className="register-column-heading">
          Complete the form below and someone from the MedSpa Network team will
          be in contact soon.
        </h2>
        <div className="form-two-column">
          <div className="form-block-2 w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="training-for-block"
              onSubmit={(e) => {
                submitForm(e);
              }}
            >
              <div className="column register-column">
                <div className="register-field-block">
                  <label htmlFor="firstName" className="register-field-heading">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="register-field w-input"
                    maxLength="256"
                    name="firstName"
                    data-name="First Name"
                    placeholder=""
                    id="firstName"
                    required={true}
                    onChange={(e) => {
                      setField(e);
                    }}
                  />
                </div>
                <div className="register-field-block">
                  <label htmlFor="lastName" className="register-field-heading">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="register-field w-input"
                    maxLength="256"
                    name="lastName"
                    data-name="Last Name"
                    placeholder=""
                    id="lastName"
                    required={true}
                    onChange={(e) => {
                      setField(e);
                    }}
                  />
                </div>
                <div className="register-field-block">
                  <label
                    htmlFor="companyName"
                    className="register-field-heading"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="register-field w-input"
                    maxLength="256"
                    name="companyName"
                    data-name="Company Name"
                    placeholder=""
                    id="companyName"
                    required={true}
                    onChange={(e) => {
                      setField(e);
                    }}
                  />
                </div>
                <div className="register-field-block">
                  <label htmlFor="address" className="register-field-heading">
                    Company Address
                  </label>
                  <input
                    type="text"
                    className="register-field w-input"
                    maxLength="256"
                    name="address"
                    data-name="Address"
                    placeholder=""
                    id="address"
                    required={true}
                    onChange={(e) => {
                      setField(e);
                    }}
                  />
                </div>
                <div className="register-field-block">
                  <label htmlFor="email" className="register-field-heading">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="register-field w-input"
                    maxLength="256"
                    name="email"
                    data-name="Email"
                    placeholder=""
                    id="email"
                    onChange={(e) => {
                      setField(e);
                    }}
                  />
                </div>
                <div className="register-field-block"></div>
                <div className="register-field-block"></div>
              </div>
              <div className="column register-column">
                <div className="register-field-block">
                  <label
                    htmlFor="phoneNumber"
                    className="register-field-heading"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="register-field w-input"
                    maxLength="256"
                    name="phoneNumber"
                    data-name="First Name"
                    placeholder=""
                    id="phoneNumber"
                    required={true}
                    onChange={(e) => {
                      setField(e);
                    }}
                  />
                </div>
                <div className="register-field-block">
                  <label htmlFor="interest" className="register-field-heading">
                    What type of training would you be interested in?
                  </label>
                  <input
                    type="text"
                    className="register-field w-input"
                    maxLength="256"
                    name="interest"
                    data-name="interest"
                    placeholder=""
                    id="interest"
                    required={true}
                    onChange={(e) => {
                      setField(e);
                    }}
                  />
                </div>
                <div className="register-field-block">
                  <label
                    htmlFor="interestedDate"
                    className="register-field-heading"
                  >
                    When would you like the training to take place?
                  </label>
                  <input
                    type="text"
                    className="register-field w-input"
                    maxLength="256"
                    name="interestedDate"
                    data-name="interestedDate"
                    placeholder=""
                    id="interestedDate"
                    required={true}
                    onChange={(e) => {
                      setField(e);
                    }}
                  />
                </div>
                <input
                  type="submit"
                  value="Submit"
                  data-wait="Please wait..."
                  className="submit-button-blacl w-button"
                />
                <div className="register-field-block"></div>
                <div className="register-field-block"></div>
                <div className="register-field-block"></div>
                <div className="register-field-block"></div>
              </div>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </section>
      {formSuccess ? (
        <section
          id="success"
          ref={myRef}
          className="email-confirmation-section"
        >
          <h1 className="email-confirmation-text">{message}</h1>
        </section>
      ) : null}
      <Footer />
    </>
  );
}
