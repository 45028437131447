import { useLocation } from "react-router-dom";
import AccountNav from "../../components/accountNav";
import Footer from "../../components/footer";
import Navbar from "../../components/navigations";
import { useEffect, useState } from "react";
import { UpdateUserData } from "../../../services/members";

export default function MyAccount() {
  const location = useLocation();
  const userData = localStorage.getItem("user");
  const [userInformation, setUserInformation] = useState(JSON.parse(userData));

  async function submitPersonalInfoForm(e) {
    e.preventDefault();
    const button = document.getElementById("personal-info-save-button");
    button.disabled = true;
    button.value = "Saving...";
    console.log("submitting form");
    const result = await UpdateUserData(
      userInformation._id,
      userInformation
    ).catch(() => {
      navigator.push("/login");
    });
    if (result.status === "success") {
      button.disabled = true;
      button.value = "Saved";
      button.classList.add("button-dense-primary-light");
      localStorage.setItem("user", JSON.stringify(result.data));
    } else {
      button.classList.remove("button-dense-primary-light");
      button.classList.add("button-dense-primary-dark");
      button.disabled = false;
      button.value = "Saving Failed";
    }
    console.log(result);
  }

  useEffect(() => {
    const button = document.getElementById("personal-info-save-button");
    button.disabled = false;
    button.value = "Save Changes";
    button.classList.remove("button-dense-primary-light");
    button.classList.add("button-dense-primary-dark");
  }, [userInformation]);

  console.log(userInformation);

  return (
    <>
      <Navbar />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="account-stack">
            <AccountNav
              currentPage={location.pathname}
              user={userInformation}
            />
            <div className="account-right-side">
              <div className="header-xxl">My Account</div>
              <div className="account-right-side-container">
                <div className="header-m">Profile</div>
                <div className="account-form-container">
                  <div className="header-xs title-underline">Personal Info</div>
                  <div className="w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      data-wf-page-id="6719a9b12b6a0979c80f4c3d"
                      data-wf-element-id="8ef3c9d5-ef30-ca50-d981-4472530b36e3"
                      onSubmit={(e) => {
                        submitPersonalInfoForm(e);
                      }}
                    >
                      <div className="field-group">
                        <label htmlFor="firstName" className="field-label-2">
                          First Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="firstName"
                          data-name="firstName"
                          placeholder={userInformation.firstName || "Arya"}
                          type="text"
                          id="firstName"
                          required=""
                          onChange={(e) => {
                            setUserInformation({
                              ...userInformation,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="lastName" className="field-label-2">
                          Last Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="lastName"
                          data-name="lastName"
                          placeholder={userInformation.lastName || "Stark"}
                          type="text"
                          id="lastName"
                          required=""
                          onChange={(e) => {
                            setUserInformation({
                              ...userInformation,
                              lastName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="email" className="field-label-2">
                          Email
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="email"
                          data-name="email"
                          placeholder={
                            userInformation.email || "Arya@gmail.com"
                          }
                          type="email"
                          id="email"
                          required=""
                          onChange={(e) => {
                            setUserInformation({
                              ...userInformation,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="license" className="field-label-2">
                          License
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="license"
                          data-name="license"
                          placeholder={userInformation.license || "123456789"}
                          type="text"
                          id="license"
                          required=""
                          onChange={(e) => {
                            setUserInformation({
                              ...userInformation,
                              license: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <input
                        type="submit"
                        data-wait="Please wait..."
                        className="button-dense-primary-light"
                        value="Save Changes"
                        id="personal-info-save-button"
                      />
                    </form>
                  </div>
                </div>
                <div className="account-form-container">
                  <div className="header-xs title-underline">Company Info</div>
                  <div className="w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      data-wf-page-id="6719a9b12b6a0979c80f4c3d"
                      data-wf-element-id="0650a630-26ad-a9dd-152c-95b4cce429fb"
                      onSubmit={(e) => {
                        submitPersonalInfoForm(e);
                      }}
                    >
                      <div className="field-group">
                        <label htmlFor="name-5" className="field-label-2">
                          Company Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="companyName"
                          data-name="company"
                          placeholder={
                            userInformation.companyInformation.companyName ||
                            "The Throne"
                          }
                          type="text"
                          id="company"
                          onChange={(e) => {
                            setUserInformation((prevState) => ({
                              ...prevState,
                              companyInformation: {
                                ...prevState.companyInformation,
                                companyName: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="website" className="field-label-2">
                          Website
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="companyWebsite"
                          data-name="website"
                          placeholder={
                            userInformation?.companyInformation
                              .companyWebsite || "Got.com"
                          }
                          type="text"
                          id="website"
                          onChange={(e) => {
                            setUserInformation((prevState) => ({
                              ...prevState,
                              companyInformation: {
                                ...prevState.companyInformation,
                                companyWebsite: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="companyAddress"
                          className="field-label-2"
                        >
                          Company Address
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="companAddress"
                          data-name="address"
                          placeholder={
                            userInformation?.companyInformation.companyAddress
                          }
                          type="text"
                          id="website"
                          onChange={(e) => {
                            setUserInformation((prevState) => ({
                              ...prevState,
                              companyInformation: {
                                ...prevState.companyInformation,
                                companyAddress: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="companyCity" className="field-label-2">
                          Company City
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="companyCity"
                          data-name="city"
                          placeholder={
                            userInformation?.companyInformation.companyCity
                          }
                          type="text"
                          id="companyCity"
                          onChange={(e) => {
                            setUserInformation((prevState) => ({
                              ...prevState,
                              companyInformation: {
                                ...prevState.companyInformation,
                                companyCity: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="companyState" className="field-label-2">
                          Company State
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="companyState"
                          data-name="state"
                          placeholder={
                            userInformation?.companyInformation.companyState
                          }
                          type="text"
                          id="companyState"
                          onChange={(e) => {
                            setUserInformation((prevState) => ({
                              ...prevState,
                              companyInformation: {
                                ...prevState.companyInformation,
                                companyState: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="companyZipCode"
                          className="field-label-2"
                        >
                          Company Zip Code
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="companyZipCode"
                          data-name="zip"
                          placeholder={
                            userInformation?.companyInformation.companyZipCode
                          }
                          type="text"
                          id="companyZipCode"
                          onChange={(e) => {
                            setUserInformation((prevState) => ({
                              ...prevState,
                              companyInformation: {
                                ...prevState.companyInformation,
                                companyZipCode: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>

                      <input
                        type="submit"
                        data-wait="Please wait..."
                        className="button-dense-primary-light"
                        value="Save Changes"
                        id="company-info-save-button"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
