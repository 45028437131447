import { Dense_button_dark, Dense_Button_Light } from "../components/buttons";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import PartnerCard from "../components/partnerCard";
import TestimonialsSection from "../components/testimonials-section";
import { GetPartners } from "../../services/web-information.js";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Partners() {
  const [partners, setPartners] = useState([]);

  async function getData() {
    const partners = await GetPartners();
    setPartners(partners);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Navbar />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Partners</div>
          <div className="header-xxl pad-top-m">
            Partnering With top Companies
          </div>
          <div className="header-xxl light">
            for Your Best in Aesthetic Medicine.
          </div>
          <p className="body-l pad-top-m">
            MedSpa Network partners with the industry&#x27;s leading providers
            to bring you the best products, devices, and solutions in aesthetic
            medicine.
          </p>
          <div className="button-group">
            <Dense_button_dark text="More About Us" link="/about" />
            <Dense_Button_Light text="Shop" link="/shop" />
          </div>
          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video w-video w-embed"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FX2rGgsqaq3A%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DX2rGgsqaq3A&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FX2rGgsqaq3A%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div>
          <div className="card-type-4 margin-top-l">
            <div className="div-block-48">
              <img
                src={require("../../assets/images/pexels-anna-tarazevich-5629205-p-1600.jpg")}
                loading="lazy"
                sizes="72px"
                alt=""
                className="card-type-4-image"
              />
              <div>
                <div className="header-s">
                  Interested in becoming a MedSpa Network partner or provider?
                </div>
                <div className="body-m light">
                  Please contact: info@medspanetwork.com
                </div>
              </div>
            </div>
            <div>
              <Link
                to="/contact"
                className="button-dense-primary-dark"
                style={{ textDecoration: "none" }}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Our Partnerships</div>
          <div className="header-l pad-top-m">
            Together, we Transform Aesthetics.
          </div>
          <div className="header-l light">
            Our Collaborations Ensure That you Have Access to Top-Tier Tools and
            Resources to Enhance Your Practice and Deliver Outstanding Results.
          </div>
        </div>
        <div
          className="w-layout-blockcontainer container left-justify "
          style={{ marginBottom: 64 }}
        >
          <div className="header-s pad-top-m">Injectables</div>
          <p className="body-m light short">
            Offering a range of injectable solutions designed to enhance facial
            aesthetics, including dermal fillers and neuromodulators.
          </p>
          <div className="parner-card-container">
            {partners
              ?.filter((partner) => {
                return partner.type === "Injectables";
              })
              .sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              })
              .map((partner, index) => {
                return (
                  <PartnerCard
                    key={index}
                    name={partner.name}
                    image={"/image/" + partner.logo}
                    description={partner.description}
                    link={partner.link}
                    products={partner.products}
                  />
                );
              })}
          </div>
        </div>
        <div
          className="w-layout-blockcontainer container left-justify "
          style={{ marginBottom: 64 }}
        >
          <div className="header-s pad-top-m">Devices</div>
          <p className="body-m light short">
            Featuring state-of-the-art medical devices used in aesthetic
            procedures, from lasers to skin resurfacing technologies.
          </p>
          <div className="parner-card-container">
            {partners
              ?.filter((partner) => {
                return partner.type === "Devices";
              })
              .sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              })
              .map((partner, index) => {
                return (
                  <PartnerCard
                    key={index}
                    name={partner.name}
                    image={"/image/" + partner.logo}
                    description={partner.description}
                    link={partner.link}
                    products={partner.products}
                  />
                );
              })}
          </div>
        </div>
        <div
          className="w-layout-blockcontainer container left-justify "
          style={{ marginBottom: 64 }}
        >
          <div className="header-s pad-top-m">Skin Care</div>
          <p className="body-m light short">
            Providing professional-grade skincare products that target various
            skin concerns, from anti-aging to hydration.
          </p>
          <div className="parner-card-container">
            {partners
              ?.filter((partner) => {
                return partner.type === "Skin Care";
              })
              .sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              })
              .map((partner, index) => {
                return (
                  <PartnerCard
                    key={index}
                    name={partner.name}
                    image={"/image/" + partner.logo}
                    description={partner.description}
                    link={partner.link}
                    products={partner.products}
                  />
                );
              })}
          </div>
        </div>
        <div
          className="w-layout-blockcontainer container left-justify "
          style={{ marginBottom: 64 }}
        >
          <div className="header-s pad-top-m">Other</div>
          <p className="body-m light short">
            Including essential services and products that support and enhance
            the overall aesthetic practice, from payment processing to patient
            management.
          </p>
          <div className="parner-card-container">
            {partners
              ?.filter((partner) => {
                return partner.type === "Other";
              })
              .sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              })
              .map((partner, index) => {
                return (
                  <PartnerCard
                    key={index}
                    name={partner.name}
                    image={"/image/" + partner.logo}
                    description={partner.description}
                    link={partner.link}
                    products={partner.products}
                  />
                );
              })}
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </>
  );
}
