export default function Footer() {
  return (
    <div className="footer-section wf-section">
      <div className="div-block-18">
        <a href="/courses" className="link">
          Courses
        </a>
        {/* <a href="/shop" className="link">
          Shop
        </a> */}
        <a href="/terms" className="link">
          Terms and Conditions
        </a>
      </div>
    </div>
  );
}
