import Navbar from "../components/navigations";
import Footer from "../components/footer";

export default function TermsAndConditions() {
  const terms = [
    {
      title: "Terms and Conditions",
      content: `Please read these Terms of Use carefully before accessing or using any part of this Website. By accessing or using this Website, you agree that you have read, understand and agree to be bound by these Terms of Use, as amended from time to time, which is hereby incorporated into these Terms of Use. If you do not wish to agree to these Terms of Use, do not access or use any part of this Website.

MedSpa Network may revise and update these Terms of Use at any time by posting the amended terms to this Website. Your continued use of the MedSpa Network Website means that you accept and agree to the revised Terms of Use. If you disagree with the Terms of Use (as amended from time to time) or are dissatisfied with this Website, your sole and exclusive remedy is to discontinue using this Website.`,
    },
    {
      title: `COPYRIGHT RESTRICTIONS:`,
      content: `The entire contents of this Website (including all information, software, text, displays, images and audio) and the design, selection and arrangement thereof, are proprietary to MedSpa Network or its licensors and are protected by United States and international laws regarding copyrights, trademarks, trade secrets and other proprietary rights. You are authorized only to use the content on the MedSpa Network Website for personal use. You may not copy, modify, create derivative works of, publicly display or perform, republish, store, transmit or distribute any of the material on this site without the prior written consent of MedSpa Network, except to: (a) store copies of such materials temporarily in RAM, (b) store files that are automatically cached by your web browser for display enhancement purposes, and (c) print a reasonable number of pages of the MedSpa Network Website; provided in each case that you do not alter or remove any copyright or other proprietary notices included in such materials. Neither the title nor any intellectual property rights to any information or material in this Website are transferred to you, but remain with Baptist Health or the applicable owner of such content. Except as expressly authorized by MedSpa Network in writing, you may not reproduce, sell or exploit for any commercial purposes (a) any part of this Website, (b) access to this Website or (c) use of this Website or of any services or materials available through this Website.`,
    },
    {
      title: `TRADEMARKS:`,
      content: `The term “Ocean Drive” is a trademark and the Ocean Drive logo and all related product and service names, designs and slogans are Ocean Drive Plastic Surgery trademarks. You may not use such marks without Ocean Drive Plastic Surgery’s prior written permission. All other names, brands and marks are used for identification purposes only and may be trademarks or registered trademarks of their respective owners.`,
    },
    ,
    {
      title: `MEDICAL ADVISE IS NOT PROVIDED ON THIS WEBSITE:`,
      content: `The information and content contained on the MedSpa Network Website is for informational purposes only and is never intended to be a substitute for or relied on for professional medical advice, diagnosis or treatment. Use of this website does not constitute medical advise and does not establish any kind of patient-client relationship by your use of this website.If you think you may be experiencing a medical emergency, immediately call your doctor or 911. You should always seek the advice of your physician or other qualified healthcare provider concerning your health or any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking such advice because of information or opinions on the MedSpa Network Website.MedSpa Network does not recommend or endorse any specific tests, products, equipment, procedures, opinions, or other information that may be mentioned on the Website. Reliance on any information provided by MedSpa Network, its employees and organized medical staff, others appearing on the Website at the invitation of MedSpa Network or other visitors or contributors to the Website is solely at your own risk.`,
    },
    ,
    {
      title: `USE OF THIS WEBSITE:`,
      content: `Use of this website does not replace your obligations to meet all education, training, licensing or other professional standards provided by any Federal, State, or local laws or that regulate the use any products, procedures, or devices depicted on this website. You agree to use the MedSpa Network Website only for lawful purposes. In using this Web Site you agree not to do any of the following: (1) use the MedSpa Network Website to violate the legal rights (including the rights of publicity and privacy) of others or to violate the laws of any jurisdiction or location; (2) intercept or attempt to intercept electronic mail not intended for you; (3) misrepresent an affiliation with any person or organization; or (4) misrepresent your identity or assume the identity of another person or organization. Your failure to comply with these provisions may expose you to civil and/or criminal liability.`,
    },
    ,
    {
      title: `LINKS TO OTHER SITES:`,
      content: `‍The MedSpa Network Website may contain links to other web sites on the Internet. MedSpa Network and Ocean Drive Plastic Surgery are not responsible for and does not endorse the content, products or services of any third-party web sites, including, without limitation, sites framed within the MedSpa Network Website or third-party advertisements, and does not make any representations regarding their quality, content or accuracy. Your use of third-party web sites is at your own risk and subject to the terms and conditions of use for such web sites.If you choose to correspond, participate in a promotion or engage in transactions with any merchant linked to through this Website, you acknowledge and agree that MedSpa Network, LLC and Ocean Drive Plastic Surgery, LLC is not a party to, and will not be responsible for, your interaction with such merchant, including its treatment of your information and the terms and conditions applicable to any transaction between you and the merchant. The terms of your interaction with any merchant are solely between you and such merchant. You agree that MedSpa Network and Ocean Drive Plastic Surgery will have no responsibility or liability for any loss or damage of any kind that you may suffer as the result of any such interaction or the presence of links to such merchants on this Website.`,
    },
    ,
    {
      title: `INDEMNIFICATION:`,
      content: `‍You agree to indemnify and hold harmless MedSpa Network, LLC and Ocean Drive Plastic Surgery, LLC and its officers, trustees, directors, employees, agents, affiliates, third-party information providers, licensors and others involved in the Website or the delivery of products, services or information over the Website, from and against any and all liabilities, expenses, damages and costs, including reasonable attorney’s fees, arising from any violation by you of this Agreement or your use of the Website or any products, services or information obtained from this Website.`,
    },
  ];

  return (
    <>
      <Navbar />
      <section class="white-background-section">
        <div class="w-layout-blockcontainer container left-justify w-container">
          <div class="header-xs title-underline">Legal</div>
          <div class="header-xxl pad-top-m">Terms and Conditions</div>
        </div>
      </section>
      <section class="white-background-section">
        <div class="w-layout-blockcontainer container w-container">
          <div class="legal-container">
            <div class="legal-left-side">
              <div
                class="legal-stick-container"
                style={{ position: "relative" }}
              >
                {terms.map((term, index) => {
                  return (
                    <a
                      href={"#" + term.title}
                      class="eyebrow s"
                      key={index}
                      style={{ width: "100%", textDecoration: "none" }}
                    >
                      {term.title}
                    </a>
                  );
                })}
              </div>
            </div>
            <div class="legal-right-side">
              {terms.map((term, index) => {
                return (
                  <div class="legal-paragraph-block" id={term.title}>
                    <div class="header-l">{term.title}</div>
                    <p class="body-s">{term.content}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
