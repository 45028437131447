import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

export default function EventCard({ props, link }) {
  const navigate = useNavigate();

  return (
    <div className="event-card">
      <Link
      to={props.fields.eventType.toLowerCase() == "virtual"
        ? `/webinar/${props.sys.id}`
        : `/masterclass/${props.sys.id}`}
        state={props}
        className="link-block-4 w-inline-block"
      >
        <div className="event-card-tag-container">
          <div className="div-block-5"></div>
          <h6 className="event-card-tag-heading">{props.fields.eventType}</h6>
        </div>
        <h3 className="event-card-heading">
          {props.fields.eventTitle.substring(0, 30) + " ..."}
        </h3>
        <div className="div-block-42">
          <img
            src={props.fields.bannerImage.fields.file.url}
            loading="lazy"
            alt=""
            className="image-3"
          />
        </div>
        <div className="event-card-location-container">
          <h6 className="event-card-location">{props.fields.eventType}</h6>
          <div className="event-card-date-container">
            <div className="div-block-6"></div>
            <h6 className="event-card-date">
              {moment(props.fields.eventStartDate).format("MMM DD YYYY")}
            </h6>
          </div>
        </div>
        <p className="event-card-paragraph">
          {props.fields.eventDescription.substring(0, 75) + " ...Read More"}
        </p>
      </Link>
    </div>
  );
}
