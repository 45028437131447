import { useState } from "react";
import axios from "axios";

export default function Login() {
  const [email, setEmail] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  async function handleForgotPassword(e) {
    e.preventDefault();
    document.getElementById("submitButton").value = "Processing...";
    document.getElementById("submitButton").enabled = false;

    axios
      .post("/api/v2/Website/accounts/forgot-password", { email: email })
      .then((res) => {
        document.getElementById("submitButton").value = "Sent!";
        document.getElementById("submitButton").enabled = false;

        if (res.data.status == "success") {
          setErrorMessage(
            "An email has been sent to you with a link to reset your password"
          );
        } else {
          setErrorMessage(
            "We could not find your account using that email address. Please try again."
          );
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        document.getElementById("submitButton").value = "Submit";
        document.getElementById("submitButton").enabled = true;
      });
  }

  return (
    <div className="login-section wf-section">
      <div className="html-embed w-embed">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#111f2d"
            d="M28.6,-33.2C42,-29.6,61.1,-27.9,70.5,-18.4C79.8,-8.9,79.4,8.4,67.7,15.1C56,21.8,33.2,18,20,27.1C6.9,36.2,3.4,58.2,-4.8,64.9C-13.1,71.5,-26.2,62.9,-40.3,54C-54.3,45.2,-69.2,36.3,-78,22.2C-86.9,8.2,-89.7,-10.9,-83.7,-26.5C-77.7,-42.1,-62.9,-54.2,-47.5,-57.1C-32.1,-60.1,-16,-53.9,-4.2,-48.1C7.6,-42.3,15.2,-36.8,28.6,-33.2Z"
            transform="translate(100 100)"
          ></path>
        </svg>
      </div>
      <div className="div-block-20">
        <div className="div-block-19">
          <img
            src={"/images/MSN_Colored.svg"}
            loading="lazy"
            alt=""
            className="login-logo"
          />
          <div className="login-hero-text">
            Oh no, lets get you a new password
          </div>
          <div className="login-card w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form-6 login-form"
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={handleForgotPassword}
            >
              <label htmlFor="name">Email Address</label>
              <input
                type="text"
                className="w-input"
                maxLength="256"
                name="name"
                data-name="Name"
                placeholder=""
                id="name"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <h3 style={{ color: "red" }}> {errorMessage}</h3>

              <input
                type="submit"
                value="Login"
                data-wait="Please wait..."
                className="login-button w-button"
                id="submitButton"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
