import { useLocation } from "react-router-dom";
import AccountNav from "../../components/accountNav";
import Footer from "../../components/footer";
import Navbar from "../../components/navigations";
import { useState } from "react";
import moment from "moment";
import Modal from "react-modal";
import { CancelMembership } from "../../../services/members";
import { useNavigate } from "react-router-dom";

export default function AccountMembership() {
  const location = useLocation();
  const userData = localStorage.getItem("user");
  const [userInformation, setUserInformation] = useState(JSON.parse(userData));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#9795b5",
      width: "50%",
      height: "50%",
      borderRadius: 8,
      color: "black",
    },
  };

  Modal.setAppElement("#root");

  async function handleCancelMembership() {
    const results = await CancelMembership(userInformation._id).catch((err) => {
      console.log(err);
      navigate("/login");
    });
    console.log(results);
    if (results.status === "success") {
      localStorage.removeItem("user");
      window.location.href = "/leaving";
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Quiz Modal"
      >
        <h2 className="header-l">Hey There!</h2>
        <div className="header-s pad-top-s">
          Are you sure you want to cancel your membership?
        </div>
        <div className="button-group">
          <button
            className="button-dense-primary-light"
            onClick={() => {
              setModalIsOpen(false);
            }}
          >
            No, take me back
          </button>
          <button
            className="button-dense-primary-dark"
            onClick={() => {
              handleCancelMembership();
            }}
          >
            Yes, cancel my membership
          </button>
        </div>
      </Modal>
      <Navbar />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="account-stack">
            <AccountNav
              currentPage={location.pathname}
              user={userInformation}
            />
            <div className="account-right-side">
              <div className="header-xxl">My Account</div>
              <div className="account-right-side-container">
                <div className="header-m">Membership</div>
                <div className="account-form-container">
                  <div className="pricing-card">
                    <div className="pricing-card-top">
                      <div className="pricing-card-text">1-Year</div>
                      <div className="pricing-card-text">Membership Plan</div>
                      <div className="pricing-card-price">
                        {Number(1500).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 0,
                        }) + "/year"}
                      </div>

                      <div className="badge">
                        <div className="tag-text">
                          Expires:{" "}
                          {moment(
                            userInformation.membershipExpirationDate
                          ).format("MMMM DD YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="account-form-container">
                  <div className="header-xs title-underline">Manage</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 16,
                      marginTop: 16,
                    }}
                  >
                    <div>
                      <div className="header-m">Cancel Your Membership</div>
                      <div className="body-m">
                        You will continue to have your membership until your
                        expiration date.
                      </div>
                    </div>
                    <button
                      className="button-dense-primary-dark"
                      onClick={() => {
                        setModalIsOpen(true);
                      }}
                    >
                      Cancel Membership
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
