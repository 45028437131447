import NavBar from "../components/navBar";
import Footer from "../components/footer";

export default function Memberships() {
  return (
    <>
      <section className="section-2">
        <NavBar />
        <div>
          <h1 className="hero-heading">
            The Future of Aesthetic <br />
            Medicine Starts Here
          </h1>
          <h2 className="heading header-spacing">
            Ready to take your career or business to the next level? Look no
            further – MedSpa Network is your gateway to a world of opportunities
            in the fast-growing field of medical aesthetics.
          </h2>
        </div>
      </section>
      <section className="two-column-section">
        <div className="half-block">
          <div className="half-paragraph-container">
            <h3 className="heading-2">
              Unlock unparalleled success and foster collaboration within the
              industry with MedSpa Network.
            </h3>
            <p className="paragraph">
              As a medspa owner or operator, you gain access to a diverse range
              of exclusive benefits crafted to suit your unique needs. Embrace
              the transformative power of MedSpa Network, and witness a paradigm
              shift in how you steer your business.
            </p>
            <a href="#" className="nav-button-stroke ">
              Sign up Now
            </a>
          </div>
        </div>
        <div className="half-block">
          <img
            src="images/Rectangle-4-2.png"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 767px) 36vw, 37vw"
            srcSet="images/Rectangle-4-2.png 500w, images/Rectangle-4-2.png 765w"
            alt=""
            className="large-image"
          />
        </div>
      </section>
      <section className="section padtop">
        <div className="three-column-conatiner">
          <div className="column membership-column">
            <div className="membership-header">
              <h2 className="membership-title">INSIDER</h2>
            </div>
            <div className="membership-pricing-block">
              <div className="membership-price">$950</div>
              <div className="annual-text">Annual Membership</div>
            </div>
            <div className="membership-detail-block">
              <ul role="list" className="list">
                <li>
                  <div className="text-block-2">
                    MSN exclusive product and device pricing
                  </div>
                </li>
              </ul>
            </div>
            <a
              href="registration"
              className="nav-button-fill anim300 dark-button "
            >
              Sign up Now
            </a>
          </div>
          <div className="column membership-column">
            <div className="membership-header elite">
              <h2 className="membership-title">Elite</h2>
            </div>
            <div className="membership-pricing-block">
              <div className="membership-price">$1,550</div>
              <div className="annual-text">Annual Membership</div>
            </div>
            <div className="membership-detail-block">
              <ul role="list" className="list">
                <li>
                  <div className="text-block-2">All previous perks plus</div>
                </li>
                <li>
                  <div className="text-block-2">
                    Access to MSN Live Webinars
                  </div>
                </li>
                <li>
                  <div className="text-block-2">
                    Access to MSN On Demand Library
                  </div>
                </li>
                <li>
                  <div className="text-block-2">
                    Access to MSN Member Chat Forum with MSN Faculty and Staff
                  </div>
                </li>
                <li>
                  <div className="text-block-2">
                    (1) 45-minute virtual business development consultation with
                    MSN National Sales Manager
                  </div>
                </li>
                <li>
                  <div className="text-block-2">
                    (1) Complimentary website OR social media assessment
                  </div>
                </li>
                <li>
                  <div className="text-block-2">
                    Discounted MSN in-person trainings
                  </div>
                </li>
              </ul>
            </div>
            <a
              href="registration"
              className="nav-button-fill anim300 dark-button "
            >
              Sign up Now
            </a>
          </div>
          <div className="column membership-column">
            <div className="membership-header vip">
              <h2 className="membership-title">VIP</h2>
            </div>
            <div className="membership-pricing-block">
              <div className="membership-price">$2,350</div>
              <div className="annual-text">Annual Membership</div>
            </div>
            <div className="membership-detail-block">
              <ul role="list" className="list">
                <li>
                  <div className="text-block-2">All previous perks plus</div>
                </li>
                <li>
                  <div className="text-block-2">
                    Special invitations to Industry &amp; Society member only
                    events
                  </div>
                </li>
                <li>
                  <div className="text-block-2">
                    Phone or text access to MSN board certified Plastic Surgeon
                    for procedure consultation, complimentary assessment and
                    treatment plan within 24 hours of any complications arising
                    from injection, laser, or aesthetics procedures*
                  </div>
                </li>
                <li>
                  <div className="text-block-2">
                    (1) Additional 45-minute virtual business development
                    consultation with MSN National Sales Manager
                    <br />
                    Complimentary website AND social media assessment
                  </div>
                </li>
                <li>
                  <div className="text-block-2">
                    Access to VIP Member Reception at MSN in-person trainings
                  </div>
                </li>
              </ul>
            </div>
            <a
              href="registration"
              className="nav-button-fill anim300 dark-button "
            >
              Sign up Now
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
