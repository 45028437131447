import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function Login() {
  const [loginInfo, setLoginInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  let navigate = useNavigate();
  const { loginUser } = useUserContext();
  console.log("location.state.redirect", location.state);

  async function Login(e) {
    e.preventDefault();
    const button = document.getElementById("submit-button");
    button.disabled = true;
    button.value = "Logging in...";
    await axios
      .post("/api/v2/Website/members/login", {
        username: loginInfo.username,
        password: loginInfo.password,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status !== "success") {
          console.log("no success");
          setErrorMessage(res.data.data.message);
          localStorage.setItem("authToken", null);
          button.disabled = false;
          button.value = "Login";
        } else {
          localStorage.setItem("authToken", res.data.data.token);
          loginUser(res.data.data.userInfo);
          console.log(location.state.redirect);
          if (location.state.redirect != null) {
            console.log("redirecting to", location.state.redirect);
            navigate(location.state.redirect);
          } else {
            navigate("/");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <section className="signup-section">
      <div
        id="w-node-c6d14739-3c42-80c8-0137-3dc5425f747f-314c76f3"
        className="w-layout-layout signup-stack wf-layout-layout"
      >
        <div
          id="w-node-c6d14739-3c42-80c8-0137-3dc5425f7480-314c76f3"
          className="w-layout-cell signup-left-side"
        >
          <div className="signup-nav-bar">
            <img
              src={require("../../assets/logos/Color=Light 1.png")}
              loading="lazy"
              alt=""
              className="signup-logo"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          <div className="sign-up-form-container">
            <div className="w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="signup-form"
                data-wf-page-id="6717f01ccf8ef524314c76f3"
                data-wf-element-id="198cbce3-3680-27fc-5746-166f2df8a8b0"
                onSubmit={(e) => {
                  Login(e);
                }}
              >
                <div className="center">
                  <div className="header-xxl center">Log in</div>
                  <p className="body-l center">
                    Log In to your Medspa Network profile.
                  </p>
                </div>
                <div>
                  <div className="field-group">
                    <input
                      className="checkout-field light"
                      style={{ width: "100%" }}
                      maxlength="256"
                      name="email"
                      data-name="email"
                      placeholder="Email"
                      type="email"
                      id="email"
                      onChange={(e) => {
                        setLoginInfo({
                          ...loginInfo,
                          username: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="field-group">
                    <input
                      className="checkout-field light"
                      style={{ width: "100%" }}
                      maxlength="256"
                      name="password-2"
                      data-name="Password 2"
                      placeholder="Password"
                      type="password"
                      id="password-2"
                      onChange={(e) => {
                        setLoginInfo({
                          ...loginInfo,
                          password: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errorMessage}
                </div>
                <input
                  className="button-dense-primary-dark"
                  value={"Login"}
                  type="submit"
                  id="submit-button"
                />
                <div className="signup-login-link">
                  <p className="body-s">Don&#x27;t have an account?</p>
                  <a href="/create-account" className="link">
                    Sign up
                  </a>
                </div>
              </form>
            </div>
          </div>
          <div className="signup-footer"></div>
        </div>
        <div
          id="w-node-c6d14739-3c42-80c8-0137-3dc5425f7481-314c76f3"
          className="w-layout-cell signup-right-side"
        >
          <img
            src={require("../../assets/images/Screenshot-2024-09-19-at-4.20.28-PM.jpg")}
            loading="lazy"
            sizes="100vw"
            alt=""
            className="signup-image"
          />
        </div>
      </div>
    </section>
  );
}
