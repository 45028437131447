import "../css/medspa-network-v3.webflow.css";
import "../css/normalize.css";
import "../css/webflow.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Navbar from "../components/navigations";
import Footer from "../components/footer";

export default function GlpInformation() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    signatureDate: new Date().toLocaleDateString(),
  });

  async function submitForm(e) {
    e.preventDefault();
    document.getElementById("submit").value = "Submitting...";
    document.getElementById("submit").disabled = true;
    try {
      const response = await axios.post(
        "/api/v2/emails/semaglutide-landing-page",
        formData
      );

      if (response.data.status === "success") {
        navigate(`/glp-information?${search}&success=true`);
        document.getElementById("submit").value = "Success";
      } else {
        setErrorMessage("Something went wrong, please try again");
        document.getElementById("submit").value = "Submit";
        document.getElementById("submit").disabled = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Navbar />
      <section className="dark-background-section image">
        <div className="w-layout-blockcontainer container gap-m ">
          <div className="header-l">Manufactured GLP Program</div>
          <div className="header-xs light" style={{ textAlign: "center" }}>
            Direct from an FDA approved manufacturer to your practice or
            patients, ensuring consistency and convenience.
            <br />
          </div>
          <div>
            <a
              href="#apply"
              className="button-dense-primary-dark "
              style={{ textDecoration: "none" }}
            >
              Apply to the Program
            </a>
          </div>
        </div>
      </section>
      <section className="light-background-section">
        <div className="w-layout-blockcontainer container ">
          <div
            id="w-node-_54bee268-f6a5-3c74-d593-f8f42c6383be-6e8a08cb"
            className="w-layout-layout triple-stack wf-layout-layout"
          >
            <div
              id="w-node-_54bee268-f6a5-3c74-d593-f8f42c6383bf-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="image-info-card featured-item">
                <div className="image-info-card-header-container">
                  <div className="header-m">Manufactured Product</div>
                </div>
                <p className="body-m">
                  This Semaglutide is manufactured by an FDA-approved
                  manufacturer. It is NOT made in a compounding pharmacy. This
                  Manufactured Semaglutide has an eight-month BUD (Beyond Use
                  Date), non-billable NDC number, and no salts or added
                  peptides.
                </p>
              </div>
            </div>
            <div
              id="w-node-_54bee268-f6a5-3c74-d593-f8f42c6383c0-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="image-info-card featured-item">
                <div className="image-info-card-header-container">
                  <div className="header-m">Send Direct to Patient</div>
                </div>
                <p className="body-m">
                  Enjoy the convenience of sending directly to your patients
                  home.
                </p>
              </div>
            </div>
            <div
              id="w-node-_9d51323c-c43b-f3ce-cf31-4a0e592c26cb-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="image-info-card featured-item">
                <div className="image-info-card-header-container">
                  <div className="header-m">Low Cost to Provider</div>
                </div>
                <p className="body-m">
                  Exclusive Medspa Network pricing. Manufactured Semaglutide
                  comes in a variety of prices and includes shipping. Bulk
                  orders are available.
                </p>
              </div>
            </div>
          </div>
          <div
            id="w-node-_9fdf70d8-3fd7-7c24-aef1-21ea9983b319-6e8a08cb"
            className="w-layout-layout triple-stack wf-layout-layout"
          >
            <div
              id="w-node-_9fdf70d8-3fd7-7c24-aef1-21ea9983b31a-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="image-info-card featured-item">
                <div className="image-info-card-header-container">
                  <div className="header-m">No Cold Shipping</div>
                </div>
                <p className="body-m">
                  No cold shipping required. Product is shipped 2-day.
                </p>
              </div>
            </div>
            <div
              id="w-node-_9fdf70d8-3fd7-7c24-aef1-21ea9983b321-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="image-info-card featured-item">
                <div className="image-info-card-header-container">
                  <div className="header-m">Buy and Bill</div>
                </div>
                <p className="body-m">
                  Providers prescribe Manufactured Semaglutide through our
                  distribution partner, The Pharmacy Hub.
                </p>
              </div>
            </div>
            <div
              id="w-node-_9fdf70d8-3fd7-7c24-aef1-21ea9983b328-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="image-info-card featured-item">
                <div className="image-info-card-header-container">
                  <div className="header-m">Guaranteed Inventory</div>
                </div>
                <p className="body-m">
                  Inventory management to prevent patient and practice inventory
                  delays.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="brown-background-section">
        <div className="w-layout-blockcontainer container gap-m ">
          <div className="header-m full-width">Semaglitude</div>
          <div
            id="w-node-b0e68f4f-f799-fab6-ff2d-e3e6a73061c8-6e8a08cb"
            className="w-layout-layout quad-stack wf-layout-layout"
          >
            <div
              id="w-node-b0e68f4f-f799-fab6-ff2d-e3e6a73061c9-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="glp-card">
                <img
                  loading="lazy"
                  src={require("../../assets/images/product-images/semaglutide1mg.png")}
                  alt=""
                  className="glp-image"
                />
                <div className="glp-info-container">
                  <div className="header-xs">Total mg: </div>
                  <div className="header-xs">1mg</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Strength:</div>
                  <div className="header-xs">1mg/ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Size:</div>
                  <div className="header-xs">1ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Units:</div>
                  <div className="header-xs">100</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price:</div>
                  <div className="header-xs">$98.00</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price/mg:</div>
                  <div className="header-xs">$98.00</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-b0e68f4f-f799-fab6-ff2d-e3e6a73061ca-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="glp-card">
                <img
                  loading="lazy"
                  src={require("../../assets/images/product-images/Semaglutide2-5mg.png")}
                  alt=""
                  className="glp-image"
                />
                <div className="glp-info-container">
                  <div className="header-xs">Total mg: </div>
                  <div className="header-xs">2.5</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Strength</div>
                  <div className="header-xs">2.5mg/ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Size</div>
                  <div className="header-xs">1ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Units</div>
                  <div className="header-xs">100</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price</div>
                  <div className="header-xs">$114.00</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price/mg</div>
                  <div className="header-xs">$45.60</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-f46fdf46-2092-b102-4901-b04bfb6e28f7-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="glp-card">
                <img
                  sizes="102.8828125px"
                  src={require("../../assets/images/product-images/Semaglutide5mg.png")}
                  loading="lazy"
                  className="glp-image"
                />
                <div className="glp-info-container">
                  <div className="header-xs">Total mg: </div>
                  <div className="header-xs">5mg</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Strength</div>
                  <div className="header-xs">2.5mg/ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Size</div>
                  <div className="header-xs">2ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Units</div>
                  <div className="header-xs">200</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price</div>
                  <div className="header-xs">$167.00</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price/mg</div>
                  <div className="header-xs">$33.40</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-f1b9de1a-57d7-d14e-b066-ce93f6bd0e5b-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="glp-card">
                <img
                  loading="lazy"
                  src={require("../../assets/images/product-images/Semaglutide12-5mg.png")}
                  alt=""
                  className="glp-image"
                />
                <div className="glp-info-container">
                  <div className="header-xs">Total mg: </div>
                  <div className="header-xs">12.5mg</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Strength</div>
                  <div className="header-xs">5mg/ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Size</div>
                  <div className="header-xs">2.5ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Units</div>
                  <div className="header-xs">250</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price</div>
                  <div className="header-xs">$227.00</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price/mg</div>
                  <div className="header-xs">$18.16</div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-m full-width">Tirzepatide</div>
          <div
            id="w-node-b5b24049-a189-ff5c-f80d-81837e9e6e84-6e8a08cb"
            className="w-layout-layout quad-stack wf-layout-layout"
          >
            <div
              id="w-node-b5b24049-a189-ff5c-f80d-81837e9e6e85-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="glp-card">
                <img
                  alt=""
                  src={require("../../assets/images/product-images/tirzepatide-10mg-ml.JPG")}
                  loading="lazy"
                  className="glp-image"
                />
                <div className="glp-info-container">
                  <div className="header-xs">Tirzepatide</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Total mg: </div>
                  <div className="header-xs">10mg</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Strength:</div>
                  <div className="header-xs">10mg/ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Size:</div>
                  <div className="header-xs">1ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price:</div>
                  <div className="header-xs">$153.00</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-b5b24049-a189-ff5c-f80d-81837e9e6ea6-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="glp-card">
                <img
                  alt=""
                  src={require("../../assets/images/product-images/tirzepatide-30mg-3ml.JPG")}
                  loading="lazy"
                  className="glp-image"
                />
                <div className="glp-info-container">
                  <div className="header-xs">Tirzepatide</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Total mg: </div>
                  <div className="header-xs">30mg</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Strength:</div>
                  <div className="header-xs">10mg/ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Size:</div>
                  <div className="header-xs">3ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price:</div>
                  <div className="header-xs">$251.00</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-b5b24049-a189-ff5c-f80d-81837e9e6ec7-6e8a08cb"
              className="w-layout-cell"
            >
              <div className="glp-card">
                <img
                  alt=""
                  src={require("../../assets/images/product-images/tirzepatide-60mg-3ml.JPG")}
                  loading="lazy"
                  className="glp-image"
                />
                <div className="glp-info-container">
                  <div className="header-xs">Tirzepatide</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Total mg: </div>
                  <div className="header-xs">60mg</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Strength:</div>
                  <div className="header-xs">20mg/ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Size:</div>
                  <div className="header-xs">3ml</div>
                </div>
                <div className="glp-info-container">
                  <div className="header-xs">Price:</div>
                  <div className="header-xs">$360.00</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-b5b24049-a189-ff5c-f80d-81837e9e6ee8-6e8a08cb"
              className="w-layout-cell"
            ></div>
          </div>
        </div>
      </section>
      <section id="apply" className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="form w-form">
            {queryParams.get("success") === "true" ? (
              <div
                // className="w-form-done"
                style={{
                  backgroundColor: "green",
                  width: "100%",
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <h4 style={{ color: "black", fontSize: 16, fontWeight: 400 }}>
                  Your form was successfully submitted, we will be in touch
                  soon!
                </h4>
              </div>
            ) : (
              <>
                <div className="header-m">Start your application here!</div>
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form"
                  data-wf-page-id="6703fd059851b7d46e8a08cb"
                  data-wf-element-id="89c99320-b25c-8e08-6050-501f9097ffbd"
                  style={{ width: "100%" }}
                  onSubmit={(e) => {
                    submitForm(e);
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <label for="name" className="field-label-2">
                      Practice Name
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name"
                      data-name="Name"
                      placeholder=""
                      type="text"
                      id="name"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          practiceName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <label for="name-5" className="field-label-2">
                      Contact Name
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name-5"
                      data-name="Name 5"
                      placeholder=""
                      type="text"
                      id="name-5"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          contactName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <label for="name-4" className="field-label-2">
                      Contact Phone Number
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name-4"
                      data-name="Name 4"
                      placeholder=""
                      type="text"
                      id="name-4"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          contactPhone: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <label for="name-3" className="field-label-2">
                      Contact Email
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name-3"
                      data-name="Name 3"
                      placeholder=""
                      type="email"
                      id="name-3"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          contactEmail: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <label for="name-2" className="field-label-2">
                      Do you currently prescribe GLP&#x27;s?
                    </label>
                    <input
                      className="checkout-field "
                      maxlength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      type="text"
                      id="name-2"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          current: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <input
                    type="submit"
                    data-wait="Please wait..."
                    className="button-primary-light "
                    id="submit"
                    value="Submit"
                  />
                </form>
              </>
            )}
          </div>
        </div>
      </section>
      <section className="light-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-m full-width">FAQ&#x27;S</div>
          <div className="fa">
            <div className="faq-row">
              <div className="header-s">One All-In Cost</div>
              <p className="body-s">
                A prescription of Semaglutide comes in a multiple use vial.
                Price includes shipping, syringes, and alcohol prep pads.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Buy-and-Bill</div>
              <p className="body-s">
                The Semaglutide program is a buy-and-bill program for physician
                offices and medical spas. Providers prescribe Semaglutide for
                each patient, or they can bulk order for clinic use. Once the
                prescription/order is filled, a practice and/or medical spa will
                be billed for the prescription/order.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">The Pharmacy Hub</div>
              <p className="body-s">
                Members will be able to order product through our distribution
                partner, The Pharmacy Hub. The Pharmacy Hub will handle all
                product kit logistics, billing and shipping through their
                state-of-the-art distribution facilities. Although The Pharmacy
                Hub is a 503A pharmacy, they receive this manufactured
                Semaglutide product from an independent, FDA-approved
                manufacturer, who has 503B distributor licenses in over 20
                states. The Pharmacy Hub is LegitScript certified and
                Surescripts certified.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Bulk Orders</div>
              <p className="body-s">
                If you would like to place orders for clinic use, a Bulk Order
                is 25 vials. Current Bulk Order Delivery States: AZ, CO, CT, FL,
                GA, IL, IN, KS, KY, LA, MA, MS, NC, NJ, NV, OH, OK, OR, PA, SC,
                TN, TX, WI.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Shipping</div>
              <p className="body-s">
                The Pharmacy Hub offers 2nd Day UPS Shipping. There are no cold
                shipping requirements, however in warmer months, the pharmacy
                may use cold shipping out of an abundance of caution. If
                applicable to your order, this will increase the cost by $10. A
                provider can get product to a clinic or patient quickly. If a
                provider prescribes a product by 4:00PM EST Monday through
                Wednesday, a patient or provider&#x27;s facility will receive
                product in two (2) business days. Thursday and Friday
                prescriptions will be shipped Monday of the following week and
                delivered in two (2) business days. Weather and holidays can
                affect delivery times and dates. A practice/provider must be
                registered with an account before any prescriptions can be
                filled.
                <br />
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Setting Up an Account</div>
              <p className="body-s">
                Whether you are a Provider, Clinic, or medical spa, we require
                that you register an account by filling out an Onboarding Form.
                The Onboarding Form requires pertinent information, such as DEA
                number, NPI number and state licensure number. A completed
                Onboarding Form is required prior to prescribing. This is the
                only way you will be able to prescribe and order the products.
                Processing your account application may take up to 3 business
                days as information from the application needs to be verified.
                Once your application is processed, you will receive a welcome
                email from The Pharmacy Hub with your account and support
                information, as well as an instructional video for online
                ordering through their DispensePro site (if you do not have
                e-scribing capabilities through an EHR/EMR).
                <br />
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Billing &amp; Reporting</div>
              <p className="body-s">
                As previously mentioned, this is a Buy-and-Bill program. Your
                practice or medical spa will be responsible for payment of each
                prescription. As you generate and send a prescription, The
                Pharmacy Hub will process the credit card on file associated
                with your account once a prescription is received. As the charge
                on the card clears, the prescription immediately goes to
                fulfillment and is shipped.
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
