import { Dense_button_dark } from "../components/buttons";

export default function SuccessfulAccountCreation() {
  return (
    <>
      <section className="onbording-section">
        <div className="signup-nav-bar">
          <img
            src="../images/ColorLight-1.svg"
            loading="lazy"
            alt=""
            className="signup-logo"
          />
        </div>
        <div className="sign-up-form-container">
          <div className="w-form">
            <div id="email-form" name="email-form" className="signup-form">
              <div className="center">
                <div className="signup-progress-group">
                  <div className="signup-progress-bar-text-group">
                    <div className="eyebrow s">Step</div>
                    <div className="eyebrow s">1/4</div>
                  </div>
                </div>
                <div className="header-xl center">Welcome to our network</div>
                <p className="body-l center">
                  Explore our events and discover the exclusive benefits of your
                  membership. We&#x27;re excited to have you with us!
                </p>
                <div className="button-group">
                  <Dense_button_dark text="Events" link="/events" />
                  <Dense_button_dark text="Courses" link="/online-courses" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="signup-footer"></div>
      </section>
    </>
  );
}
