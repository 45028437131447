import { Dense_Button_Light } from "../components/buttons";
import EventCardType5 from "../components/eventCardType5";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import TestimonialsSection from "../components/testimonials-section";

export default function InPerson() {
  return (
    <>
      <Navbar type={"dark"} />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light title-underline">
            In-Person Courses
          </div>
          <div className="header-xxl pad-top-m">Master the art of</div>
          <div className="header-xxl light pad-top-xs">Aesthetic Medicine</div>
          <p className="body-l pad-top-m">
            Our hands-on training courses are designed for small groups of up to
            5 participants, ensuring personalized attention and a focused
            learning experience. Led by industry experts, these intimate
            sessions allow you to master the latest techniques in aesthetic
            medicine with the guidance and support you need to excel.
          </p>

          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video w-video w-embed margin-top-l"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FX2rGgsqaq3A%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DX2rGgsqaq3A&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FX2rGgsqaq3A%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="brown-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs center">Featured</div>
          <div className="header-xxl light pad-top-m">4 Hour Class</div>
          <div className="header-xxl center">Filler fundamentals</div>
          <p className="body-l center pad-top-m">
            Enhance your skills in facial aesthetics with our comprehensive
            "Filler Fundamentals" course, designed specifically for healthcare
            professionals looking to deepen their understanding of hyaluronic
            acid (HA) dermal filler therapy.
          </p>
          <div className="featured-event">
            <img
              src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
              loading="lazy"
              alt=""
              className="featured-event-image"
            />
            <div className="featured-event-details brown">
              <div className="featured-event-title-bar">
                <div className="featured-event-title">
                  <div className="header-s">Art of Regenerative Aesthetics</div>
                  <div className="date-badge-light general-1">
                    <div>NEW</div>
                  </div>
                </div>
              </div>
              <div className="featured-event-details-bar">
                <div className="div-block-50">
                  <div className="badge">
                    <img
                      alt=""
                      src={require("../../assets/icons/calendar-2.png")}
                      loading="lazy"
                      className="tag-icon"
                    />
                    <div className="eyebrow s">21-22 FEB 2025</div>
                  </div>
                  <div className="badge">
                    <img
                      alt=""
                      src={require("../../assets/icons/calendar-2.png")}
                      loading="lazy"
                      className="tag-icon"
                    />
                    <div className="eyebrow s">21-22 FEB 2025</div>
                  </div>
                </div>
                <Dense_Button_Light
                  text="Book Your Date Now"
                  link="/in-person-learning/book"
                  state={{
                    title: "Filler fundamentals",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs light underline">Courses</div>
          <div className="header-xl pad-top-l">Available Courses</div>
          <div className="class-list pad-top-m">
            <EventCardType5
              title="Filler Fundamentals"
              learningOptions={["Online", "In-Person"]}
              link={"/in-person-learning/filler-fundamentals"}
            />
            <EventCardType5
              title="Filler Masterclass"
              learningOptions={["Online", "In-Person"]}
              link={"/in-person-learning/filler-masterclass"}
            />
            <EventCardType5
              title="Neurotoxin Fundamentals"
              learningOptions={["Online", "In-Person"]}
              link={"/in-person-learning/neurotoxin-fundamentals"}
            />
            <EventCardType5
              title="Neurotoxin Masterclass"
              learningOptions={["Online", "In-Person"]}
              link={"/in-person-learning/neurotoxin-masterclass"}
            />
            <EventCardType5
              title="Combination Therapy"
              learningOptions={["Online", "In-Person"]}
              link={"/in-person-learning/neurotoxin-masterclass"}
            />
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </>
  );
}
