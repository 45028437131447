import Nav from "../../components/nav";
import Footer from "../../components/footer";

export default function VendorThankYou() {
  return (
    <>
      {" "}
      <Nav />
      <div className="section-21 wf-section">
        <img
          src="images/heart.webp"
          loading="lazy"
          sizes="200px"
          srcSet="images/heart.webp 500w"
          alt=""
          className="image-16"
        />
        <h1 className="congrats-heading">CONGRATS</h1>
        <h1 className="thank-you-sub-text">
          Your vendor package has been purchased
        </h1>
        <h1 className="thank-you-paragraph">
          We cant wait to see you! Keep an eye on your email, we just sent you a
          receipt and some more information. We have also added you to our
          mailing list for future events.
        </h1>
        <div className="text-block-42">Share this event</div>
        <div className="div-block-97">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://www.medspanetwork.com/labRegistration"
            className="w-inline-block"
          >
            <img
              src="images/facebook.webp"
              loading="lazy"
              sizes="(max-width: 479px) 29vw, 75px"
              srcSet="images/facebook.webp 500w"
              alt=""
              className="share-button"
            />
          </a>
          <a
            href="https://twitter.com/share?text=twitter&amp;url=https://www.medspanetwork.com/labRegistration"
            className="w-inline-block"
          >
            <img
              src="images/twitter.webp"
              loading="lazy"
              sizes="(max-width: 479px) 29vw, 75px"
              srcSet="images/twitter.webp 500w"
              alt=""
              className="share-button"
            />
          </a>
          <a
            href="https://www.linkedin.com/sharing/share-offsite/?&amp;url=https://www.medspanetwork.com/labRegistration"
            className="w-inline-block"
          >
            <img
              src="images/linkedin.webp"
              loading="lazy"
              alt=""
              className="share-button"
            />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}
