import axios from "axios";

export default function WebInfo() {}

export async function GetPartners() {
  try {
    const res = await axios.get("/api/v2/Website/info/partners");
    return res.data.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
