import { useEffect, useState } from "react";
import { GetBlogs } from "../../services/blogServices";
import Navbar from "../components/navigations";
import Footer from "../components/footer";
import moment from "moment";

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);

  async function getBlogs() {
    const data = await GetBlogs();
    setBlogs(data);
  }

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      <Navbar />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light underline">Blogs</div>
          <div className="header-xxl light pad-top-m">Find all of the</div>
          <div className="header-xxl">News from Medspa Network</div>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="staff-card-container">
            {blogs.map((blog, index) => {
              return (
                <a
                  key={index}
                  className="staff-card"
                  style={{ textDecoration: "none" }}
                  href={`/article/${blog.sys.id}`}
                >
                  <div className="staff-tag rounded">
                    <div className="eyebrow s">
                      {moment(blog.fields.publishDate).format("MMM DD YYYY")}
                    </div>
                  </div>
                  <h3 className="header-xs">{blog.fields.title}</h3>
                  <div className="div-block-51">
                    <img
                      alt=""
                      src={blog.fields.banner.fields.file.url}
                      loading="lazy"
                      className="image-3"
                    />
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
