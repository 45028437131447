import axios from "axios";

export default function TestPurchase() {
  async function handleSubmit(e) {
    e.preventDefault();
    axios
      .post("/api/v2/purchase/single-purchase")
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <input type="submit" />
      </form>
    </div>
  );
}
