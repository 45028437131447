import { useNavigate } from "react-router-dom";

export default function EventCardType3({ length, title, link, state }) {
  const navigate = useNavigate();

  return (
    <div className="event-card-type-3">
      <div className="header-s">
        <div className="class-row">
          <p className="body-m">{length}</p>
          <div className="header-s">{title}</div>
          <button
            onClick={() => {
              navigate(link, { state: state });
            }}
            className="link-block-2 w-inline-block"
          >
            <p className="body-m">Book Now</p>
          </button>
        </div>
      </div>
    </div>
  );
}
