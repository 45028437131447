import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";
import PartnerBar from "../components/partnerBar";
import Footer from "../components/footer";
import DarkStaticNavBar from "../components/darkStaticNavBar";

export default function Product() {
  const match = useMatch("/product/:id");
  const [product, setProduct] = useState({});

  async function getProductDetails() {
    axios
      .get(
        "/api/products/details?" + new URLSearchParams({ _id: match.params.id })
      )
      .then((res) => {
        console.log(res.data);
        setProduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getProductDetails();
  }, []);

  return (
    <>
      <DarkStaticNavBar />
      <section className="product-section">
        <div className="w-layout-blockcontainer product-container w-container">
          <div className="div-block-195">
            <img
              src={"/images/" + product.productImage}
              loading="lazy"
              alt=""
              className="image-42"
            />
          </div>
          <div className="div-block-196">
            <h1 className="product-title">{product.title}</h1>
            <div className="product-price">
              {" "}
              {product.price
                ? product.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                  })
                : null}
            </div>
            <p className="product-description">{product.description}</p>
            <a href={product.link} className="button-22 w-button">
              Purchase
            </a>
          </div>
        </div>
      </section>
      <PartnerBar />
      <Footer />
    </>
  );
}
