import { useEffect, useState } from "react";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { GetQuiz } from "../../services/courseServices";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { MarkCourseFinished } from "../../services/courseServices";

export default function Quiz() {
  const [quiz, setQuiz] = useState([]);
  const [quizStatus, setQuizStatus] = useState({
    trackedAnswers: [],
    percentage: 0,
    correctAnswers: 0,
    questionNumber: 0,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [score, setScore] = useState(0);
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const scoreNeededToPass = 90;

  async function getQuiz() {
    console.log(query.get("_id"));
    const course = await GetQuiz(query.get("_id")).catch((err) => {
      navigate("/login", { state: { redirect: window.location.pathname } });
    });
    setQuiz(course.quiz);
  }

  useEffect(() => {
    getQuiz();
  }, []);

  function selectedAnswer(choice) {
    if (quizStatus.questionNumber + 1 < quiz.length) {
      setQuizStatus((prevState) => ({
        ...prevState,
        trackedAnswers: [...prevState.trackedAnswers, choice],
        questionNumber: quizStatus.questionNumber + 1,
      }));
    } else {
      setQuizStatus((prevState) => ({
        ...prevState,
        trackedAnswers: [...prevState.trackedAnswers, choice],
      }));

      calculateScore([...quizStatus.trackedAnswers, choice]);
    }
  }

  function calculateScore(arrayOfAnswers) {
    let correctAnswers = 0;
    arrayOfAnswers.forEach((answer) => {
      if (answer.correct === true) {
        correctAnswers++;
      }
    });

    const percentage = (correctAnswers / quiz.length) * 100;
    setScore(percentage);

    if (percentage >= scoreNeededToPass) {
      console.log("passed");
      finishCourse();
    } else {
      setModalIsOpen(true);
    }
  }

  async function finishCourse() {
    await MarkCourseFinished(
      query.get("_id"),
      localStorage.getItem("authToken")
    );

    navigate(
      "/completed-course?" + new URLSearchParams(window.location.search)
    );
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      width: "50%",
      height: "50%",
      borderRadius: 8,
      color: "black",
    },
  };

  Modal.setAppElement("#root");

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Quiz Modal"
      >
        <h2 className="header-l">
          {score >= scoreNeededToPass ? "Congratulations!" : "Sorry!"}
        </h2>
        <div className="header-s pad-top-s">
          You scored {score}% on the quiz
        </div>

        {score >= scoreNeededToPass ? (
          <div className="button-group">
            <button
              className="button-dense-primary-dark"
              onClick={() => {
                navigate(
                  "/completed-course?" +
                    new URLSearchParams(window.location.search)
                );
              }}
            >
              Continue
            </button>
          </div>
        ) : (
          <div className="button-group">
            <button
              className="button-dense-primary-light"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back to Course
            </button>
            <button
              className="button-dense-primary-dark"
              onClick={() => {
                navigate(0);
              }}
            >
              Retry Quiz
            </button>
          </div>
        )}
      </Modal>

      <Navbar />
      <section className="onbording-section">
        <div className="signup-nav-bar">
          <img
            src={require("../../assets/logos/Color=Light 1.png")}
            loading="lazy"
            alt=""
            className="signup-logo"
          />
        </div>
        <div className="sign-up-form-container">
          <div className="w-form">
            <div
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="signup-form"
              data-wf-page-id="6717e3d2e7b2fb4fa2ac38da"
              data-wf-element-id="198cbce3-3680-27fc-5746-166f2df8a8b0"
            >
              <div className="center">
                <div className="header-m center">
                  Question {quizStatus.questionNumber + 1}:{" "}
                  {quiz.length != 0 ? quiz[quizStatus.questionNumber].q : null}
                </div>
              </div>
              <div>
                <div className="field-group">
                  <p style={{ color: "red" }}></p>
                </div>
                {quiz.length != 0
                  ? quiz[quizStatus.questionNumber].choices.map(
                      (choice, index) => {
                        return (
                          <div key={index} className="field-group">
                            <button
                              className="button-primary-light full-width "
                              onClick={() => {
                                selectedAnswer(choice);
                              }}
                            >
                              {choice.answer}
                            </button>
                          </div>
                        );
                      }
                    )
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="signup-footer"></div>
      </section>
      <Footer />
    </>
  );
}
