import NavBar from "../components/navBar";
import Footer from "../components/footer";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const [formData, setFormData] = useState({});
  const [warning, setWarning] = useState(null);
  const [checkedNPI, setCheckNPI] = useState(false);
  const navigate = useNavigate();

  async function getNPI(e) {
    e.preventDefault();
    axios
      .get(`/api/v2/Website/accounts/verify-npi?npi=${formData.npiNumber}`)
      .then((res) => {
        const data = res.data.results[0];
        setFormData((prevState) => ({
          ...prevState,
          npiData: { ...data },
          firstName: data.basic.first_name,
          lastName: data.basic.last_name,
          address: data.addresses[0].address_1,
          phoneNumber: data.addresses[0].telephone_number,
          companyName: data.basic.organization_name,
          credential: data.basic.credential,
          city: data.addresses[0].city,
          state: data.addresses[0].state,
          postal_code: data.addresses[0].postal_code,
        }));

        setCheckNPI(true);
      })
      .catch((err) => {
        console.log(err);
        setWarning("Could not verify the NPI Number. Please try again.");
      });
  }

  async function submitForm(e) {
    console.log("submit");
    e.preventDefault();
    document.getElementById("submitButton").disabled = true;
    document.getElementById("submitButton").value = "Processing...";
    axios
      .post("/api/v2/Website/accounts/register", formData)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          navigate("/login");
        }
      })
      .catch((err) => {
        setWarning("Registration failed. Please try again. " + String(err));
        document.getElementById("submitButton").disabled = false;
      });
  }

  useEffect(() => {
    switch (formData.tierSelection) {
      case "Insider":
        setFormData((prevState) => ({ ...prevState, price: 950 }));
        break;

      case "Elite":
        setFormData((prevState) => ({ ...prevState, price: 1550 }));
        break;

      case "VIP":
        setFormData((prevState) => ({ ...prevState, price: 2350 }));
        break;

      default:
        break;
    }
  }, [formData.tierSelection]);

  return (
    <>
      <section className="section-2 registration">
        <NavBar />
        <div>
          <h1 className="hero-heading">Membership registration</h1>
        </div>
      </section>

      <section className="section whitebg two-column-section">
        <div className="form-block-2 w-form">
          <h2 className="register-column-heading">
            Ready to join Medspa Network?
          </h2>

          {checkedNPI ? (
            <form
              id="email-form-2"
              name="email-form-2"
              data-name="Email Form 2"
              method="get"
              className="form-2 register-form"
              data-wf-page-id="6508535901706168d08ad083"
              data-wf-element-id="d90ffdf5-d06a-509c-21eb-2a59bbf09d6e"
              onSubmit={(e) => {
                submitForm(e);
              }}
            >
              <div className="register-field-block">
                <label htmlFor="firstName" className="register-field-heading">
                  First Name
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="firstName"
                  data-name="firstName"
                  defaultValue={formData.firstName}
                  id="firstName"
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="lastName" className="register-field-heading">
                  Last Name
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="lastName"
                  data-name="lastName"
                  id="lastName"
                  defaultValue={formData.lastName}
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="credential" className="register-field-heading">
                  Credential
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="credential"
                  data-name="credential"
                  id="credential"
                  defaultValue={formData.credential}
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="email" className="register-field-heading">
                  Email Address
                </label>
                <input
                  type="email"
                  className="register-field w-input"
                  maxLength="256"
                  name="email"
                  data-name="email"
                  defaultValue={formData.email}
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="password" className="register-field-heading">
                  Create your Password
                </label>
                <input
                  type="password"
                  className="register-field w-input"
                  maxLength="256"
                  name="password"
                  data-name="password"
                  defaultValue={formData.password}
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="phoneNumber" className="register-field-heading">
                  Phone Number
                </label>
                <input
                  type="tel"
                  className="register-field w-input"
                  maxLength="256"
                  name="phoneNumber"
                  data-name="phoneNumber"
                  defaultValue={formData.phoneNumber}
                  id="phoneNumber"
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="companyName" className="register-field-heading">
                  Company Name
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="companyName"
                  data-name="companyName"
                  defaultValue={formData.companyName}
                  id="companyName"
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="address" className="register-field-heading">
                  Address
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="address"
                  data-name="address"
                  defaultValue={formData.address}
                  id="address"
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="city" className="register-field-heading">
                  City
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="city"
                  data-name="city"
                  defaultValue={formData.city}
                  id="city"
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="state" className="register-field-heading">
                  State
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="state"
                  data-name="state"
                  defaultValue={formData.state}
                  id="state"
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="register-field-block">
                <label htmlFor="postal_code" className="register-field-heading">
                  Zip Code
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="256"
                  name="postal_code"
                  data-name="postal_code"
                  defaultValue={formData.postal_code}
                  id="postal_code"
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              {/* <p className="register-disclaimer-text">
                  By becoming a member of the MedSpa Network, you agree to
                  adhere to the following terms and conditions. Membership
                  grants you access to exclusive benefits and services, subject
                  to availability and eligibility criteria. Your membership is
                  non-transferable and for your personal use only. You are
                  responsible for maintaining the confidentiality of your
                  account credentials and ensuring their safekeeping. Membership
                  fees, if applicable, are non-refundable and subject to change
                  with prior notice. MedSpa Network reserves the right to modify
                  or terminate membership benefits, services, or these terms and
                  conditions at its discretion. Members are expected to conduct
                  themselves in a respectful and courteous manner when
                  interacting with staff, fellow members, and service providers.
                  Any violation of these terms or any inappropriate behavior may
                  result in the suspension or termination of your membership
                  without refund. By continuing to use your membership, you
                  acknowledge and accept these terms and conditions. Please
                  review our privacy policy for information on how your data is
                  collected and used.
                  <br />
                </p> */}
              <h4>{warning}</h4>

              <input
                type="submit"
                title="purchase membership"
                className="nav-button-fill anim300 dark-button "
                id="submitButton"
              />
            </form>
          ) : (
            <form
              id="email-form-2"
              name="email-form-2"
              data-name="Email Form 2"
              method="get"
              className="form-2 register-form"
              data-wf-page-id="6508535901706168d08ad083"
              data-wf-element-id="d90ffdf5-d06a-509c-21eb-2a59bbf09d6e"
              onSubmit={(e) => {
                getNPI(e);
              }}
            >
              <div className="register-field-block">
                <label htmlFor="npiNumber" className="register-field-heading">
                  Medical Directors NPI Number
                </label>
                <input
                  type="text"
                  className="register-field w-input"
                  maxLength="10"
                  name="npiNumber"
                  data-name="npiNumber"
                  id="npiNumber"
                  required
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                {warning && <h4>{warning}</h4>}
                <button
                  className="nav-button-fill anim300 dark-button "
                  type="submit"
                >
                  Verify NPI
                </button>
              </div>
            </form>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
}
