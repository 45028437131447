import NavBar from "../components/navBar";
import Footer from "../components/footer";
import PartnerBar from "../components/partnerBar";

export default function Home() {
  return (
    <>
      <section className="section-2 home-hero">
        <NavBar />
        <div>
          <h1 className="hero-heading">
            Empowering Tomorrow’s
            <br />
            Aesthetic Clinicians Today
          </h1>
          <h2 className="heading header-spacing">
            Elevate Your Aesthetic Career with MedSpa Network&#x27;s <br />
            Comprehensive Physician-Led Training and Business Support
          </h2>
          <a href="/registration" className="button-red ">
            Collaborate with us
          </a>
        </div>
      </section>
      <section className="two-column-section">
        <div className="half-block">
          <div className="half-paragraph-container">
            <h3 className="heading-2">The Clinical Network You Need</h3>
            <p className="bold-paragraph">
              MedSpa Network is on a mission to provide the most advanced,
              comprehensive, and inclusive aesthetic training programs for
              medical professionals.
            </p>
            <p className="paragraph">
              As the only physician-led network of its kind, MedSpa Network
              empowers practitioners with cutting-edge techniques, virtual and
              over-the-shoulder live training, and access to state-of-the-art
              technologies and biomaterials. A hands-on approach to learning and
              personalized attention to each injector based on where they are in
              their career ensures that they have the knowledge and skills to
              succeed. 
            </p>
            <a href="/about" className="nav-button-stroke ">
              About Medspa Network
            </a>
          </div>
        </div>
        <div className="half-block">
          <img
            src={require("../../assets/images/Rectangle-4.png")}
            loading="lazy"
            alt=""
            className="large-image"
          />
        </div>
      </section>
      <section className="section">
        <h1 className="cap _35ch auto-center _7spacing _40-pad-top">
          MedSpa Network meets your training needs at your level.
        </h1>
      </section>
      <section className="small-padding-section whitebg flex-vert">
        <div className="width75 two-column-container">
          <div className="column-40 ">
            <div>
              <h4 className="grid-title _7-spacing">
                Physicians &amp; Surgeons
              </h4>
              <p className="paragraph-3">
                Looking to expand your patient offerings and your skill set?
                With the increased interest in non-invasive cosmetic treatments
                and wellness services, surgeons and physicians of all
                specialities turn to MedSpa Network to stay up to date on
                aesthetic medicine and the latest techniques. Become a member of
                MedSpa Network to tap into this growing patient demand and
                increase your bottom line.
              </p>
              <a href="/registration" className="pink-text-button ">
                Join MedSpa Network Today
              </a>
            </div>
          </div>
          <div className="column-60">
            <img
              src="/images/Rectangle-10.png"
              loading="lazy"
              sizes="41vw"
              srcSet="images/Rectangle-10.png 500w, images/Rectangle-10.png 791w"
              alt=""
              className="image-2"
            />
          </div>
        </div>
        <div className="width75 two-column-container">
          <div className="column-60">
            <img
              src="images/Rectangle-8.png"
              loading="lazy"
              sizes="41vw"
              srcSet="images/Rectangle-8.png 500w, images/Rectangle-8.png 791w"
              alt=""
              className="image-2"
            />
          </div>
          <div className="column-40 ">
            <div>
              <h4 className="grid-title _7-spacing">
                Nurse Injectors, Physician Associates, and Registered Nurses
              </h4>
              <p className="paragraph-3">
                The field of aesthetic medicine and wellness evolves rapidly. As
                a medical professional, you play an essential role in providing
                the best medical and aesthetic treatments within your practice.
                With a focus on staying at the forefront of aesthetic medicine
                advancements, MedSpa Network offers a platform for comprehensive
                training, collaboration, and networking. <br />
              </p>
              <a href="/registration" className="pink-text-button ">
                Collaborate with MedSpa Network Now
              </a>
            </div>
          </div>
        </div>
        <div className="width75 two-column-container">
          <div className="column-40 ">
            <div>
              <h4 className="grid-title _7-spacing">Medspa Owners</h4>
              <p className="paragraph-3">
                Ready to diversify your services and meet the rising demand for
                aesthetic treatments and wellness services? Joining MedSpa
                Network provides an invaluable collaboration opportunity for
                medspa owners like you. By becoming a part of the MedSpa Network
                community, you can expect to remain at the forefront of
                aesthetic medicine. Learn how to elevate your patient experience
                and enhance your practice’s success. <br />
              </p>
              <a href="/registration" className="pink-text-button ">
                Partner with MedSpa Network today
              </a>
            </div>
          </div>
          <div className="column-60">
            <img
              src="images/Rectangle-11.png"
              loading="lazy"
              sizes="41vw"
              srcSet="images/Rectangle-11.png 500w, images/Rectangle-11.png 791w"
              alt=""
              className="image-2"
            />
          </div>
        </div>
      </section>
      <section className="section less">
        <div className="w-layout-blockcontainer container-10 w-container">
          <div className="div-block-221">
            <div className="div-block-222">
              <h3 className="heading-48">Semaglutide Distribution</h3>
              <p style={{ marginBottom: 30 }}>
                Direct from manufacturer generic semaglutide is here. MedSpa
                Network has exclusive access to direct from manufacturer FDA
                approved generic semaglutide, the only one. This version has the
                same safety profile and years of data on efficacy for weight
                loss. The only other option with similar stature is brand name
                manufactured product which is in increasingly high demand with
                lowering supply.
              </p>
              <a
                href="/semaglutide"
                className="nav-button-stroke"
                style={{ marginTop: 20 }}
              >
                Apply Now
              </a>
            </div>
            <div className="div-block-223">
              <img
                src={require("../../assets/images/Semaglutide12-5mg.png")}
                loading="lazy"
                alt=""
                style={{ objectFit: "contain", height: "100%", maxHeight: 300 }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section dark-bg two-column-section minus-padding-bottom">
        <img
          src="images/Rectangle-4-1.png"
          loading="lazy"
          sizes="(max-width: 479px) 99.5px, (max-width: 767px) 32vw, (max-width: 991px) 36vw, 47vw"
          srcSet="images/Rectangle-4-1.png 500w, images/Rectangle-4-1.png 765w"
          alt=""
          className="image-3"
        />
        <div className="flex-vert">
          <h2 className="light-heading-h2">
            Meet the MedSpa Network Faculty &amp; Staff
          </h2>
          <p className="light-paragraph-bold">
            The MedSpa Network distinguished and dedicated faculty and staff
            have collective expertise and passion drive the organization&#x27;s
            commitment to excellence in aesthetic medicine.
          </p>
          <p className="paragraph-4">
            Led by renowned plastic surgeons Dr. Alan Durkin and Dr. Jimmy Chim,
            the MedSpa Network team is composed of accomplished medical
            professionals, experienced injectors, and administrative
            professionals who are united by a shared vision of providing
            top-tier education and patient care in the rapidly evolving world of
            medical spa services. With a wealth of knowledge, extensive
            training, and a genuine enthusiasm for enhancing beauty and
            wellness, our faculty and staff are at the forefront of innovation,
            ensuring that practitioners and their patients receive the highest
            level of care, skill, and guidance in their pursuit of aesthetic
            perfection.
          </p>
          <a href="/team" className="nav-button-stroke ">
            rEAD more about the medspa faculty &amp; staff
          </a>
        </div>
      </section>
      <section className="section pink-bg center-errythang">
        <h1 className="white-text">
          Ready to take your practice to the next level?
        </h1>
        <h2 className="white-text sub-heading">
          The MedSpa Network Membership is your all-inclusive resource for
          success and collaboration in the industry.
        </h2>
        <a href="/about" className="nav-button-stroke ">
          Learn More
        </a>
      </section>
      <section className="section whitebg center-errythang">
        <div className="div-block-5">
          <div className="testimonials-container">
            <div className="star-container center-errythang">
              <img
                src="images/Vector.svg"
                loading="lazy"
                alt=""
                className="image-4"
              />
              <img
                src="images/Vector.svg"
                loading="lazy"
                alt=""
                className="image-4"
              />
              <img
                src="images/Vector.svg"
                loading="lazy"
                alt=""
                className="image-4"
              />
              <img
                src="images/Vector.svg"
                loading="lazy"
                alt=""
                className="image-4"
              />
              <img
                src="images/Vector.svg"
                loading="lazy"
                alt=""
                className="image-4"
              />
            </div>
          </div>
          <h1 className="heading-3">testimonials</h1>
          <div className="two-column-testimonials">
            <div className="div-block-3">
              <div className="testimonial-block">
                <p className="paragraph-5">
                  Took a trip down to Orlando for a fantastic training by
                  @medspanetwork. Staying on top of the up and coming newest
                  techniques and knowledge is the forefront of our practice.
                  Frequent education and training is essential to bring the best
                  to our clients.
                </p>
                <div className="testimonial-from-block">
                  <img
                    src="images/Ellipse-1.png"
                    loading="lazy"
                    alt=""
                    className="testimonial-image"
                  />
                  <div className="testimonial-name">
                    <p className="paragraph-6">
                      Diva Aesthetics
                      <br />
                    </p>
                    <p className="paragraph-7">
                      @diva_aesthetics_pa
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-block-3">
              <div className="testimonial-block">
                <p className="paragraph-5">
                  We learned so much!!!! So many amazing faculty who really are
                  the best.
                  <br />
                  <br />
                  Why do we spend so much time on education?? These advanced
                  classes make us SAFER injectors for our amazing clients!
                </p>
                <div className="testimonial-from-block">
                  <img
                    src="images/Ellipse-1-1.png"
                    loading="lazy"
                    alt=""
                    className="testimonial-image"
                  />
                  <div className="testimonial-name">
                    <p className="paragraph-6">
                      injectorjoc
                      <br />
                    </p>
                    <p className="paragraph-7">
                      @injectorjoc
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PartnerBar />
      <Footer />
    </>
  );
}
