export default function Lexington() {
  return (
    <>
      <section className="section-2">
        <div className="div-block-13">
          <h1 className="hero-heading">Medspa Network</h1>
          <h2 className="heading header-spacing">
            Elevate Your Aesthetic Career with MedSpa Network&#x27;s <br />
            Comprehensive Physician-Led Training and Business Support
          </h2>
        </div>
      </section>
      <section className="section-38">
        <div className="w-layout-blockcontainer w-container">
          <h1 className="heading-51">Programs</h1>
          <div>
            <div
              id="w-node-_4e81a904-b9fb-1f48-ec72-8839484e02d7-49112acf"
              className="w-layout-layout quick-stack-12 wf-layout-layout"
            >
              <div
                id="w-node-_4e81a904-b9fb-1f48-ec72-8839484e02d8-49112acf"
                className="w-layout-cell"
              >
                <a
                  href="/labRegistration"
                  className="link-block-2 w-inline-block"
                >
                  <img
                    src="../assets/images/Rectangle-5-7.png"
                    loading="lazy"
                    sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
                    srcSet="../assets/images/Rectangle-5-7-p-500.png 500w, ../assets/images/Rectangle-5-7-p-800.png 800w, ../assets/images/Rectangle-5-7-p-1080.png 1080w, ../assets/images/Rectangle-5-7-p-1600.png 1600w, ../assets/images/Rectangle-5-7.png 1918w"
                    alt=""
                    className="image-52"
                  />
                  <h1 className="heading-54">Hands on Training June 14th</h1>
                </a>
              </div>
              <div
                id="w-node-_4e81a904-b9fb-1f48-ec72-8839484e02d9-49112acf"
                className="w-layout-cell cell-8"
              >
                <a
                  href="/semaglutideSignup"
                  className="link-block-3 w-inline-block"
                >
                  <img
                    src="../assets/images/2639754.jpeg"
                    loading="lazy"
                    sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
                    srcSet="../assets/images/2639754-p-500.jpeg 500w, ../assets/images/2639754-p-800.jpeg 800w, ../assets/images/2639754.jpeg 1000w"
                    alt=""
                    className="image-52"
                  />
                  <h1 className="heading-53">Manufactured Semaglutide</h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-37">
        <div className="w-layout-blockcontainer container-12 w-container">
          <h1 className="heading-52">Vendors</h1>
          <div className="div-block-211">
            <div className="div-block-212">
              <a
                href="https://form.jotform.com/241074997816166"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/RepeatMD-Logo-WhiteinBlack_1.jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 225px, 300px"
                  srcSet="../assets/images/RepeatMD-Logo-WhiteinBlack_1-p-500.jpg 500w, ../assets/images/RepeatMD-Logo-WhiteinBlack_1-p-800.jpg 800w, ../assets/images/RepeatMD-Logo-WhiteinBlack_1.jpg 1024w"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>RepeatMD</h1>
                <p>
                  Click &#x27;Learn More&#x27; to see how you can improve your
                  patient retention and lifetime value.
                </p>
                <a
                  href="https://repeatmd.referralrock.com/l/MedspaNtwrk/"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="div-block-212">
              <a
                href="https://form.jotform.com/241074997816166"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/Logo-Primary-1.svg"
                  loading="lazy"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>Cherry</h1>
                <p>
                  Cherry’s mission is to help medical providers treat more
                  patients by offering the best “buy now, pay later” solution
                  with high approvals, low cost, and a seamless experience.
                </p>
                <a
                  href="http://withcherry.com/medspa-network"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="div-block-212">
              <a
                href="https://www.joincoverwell.com/partner"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/coverwell.svg"
                  loading="lazy"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>Coverwell</h1>
                <p>
                  Use code MSN01. Give Patients the Reassurance of Coverage for
                  Unexpected Med and Day Spa Complications
                </p>
                <a
                  href="http://withcherry.com/medspa-network"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="div-block-212">
              <a
                href="https://form.jotform.com/241074997816166"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/logo.svg"
                  loading="lazy"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>Ourself</h1>
                <p>
                  Our proprietary approach goes deeper than the superficial.
                   Traditional skincare just sits on the surface. Ourself
                  scientists created a revolutionary, patent-pending delivery
                  system to propel power ingredients like Intides™—Intelligent
                  Peptides—to targeted areas within skin.
                </p>
                <a
                  href="mailto:tlewis@glo-pharma.com?subject=Hello%20from%20Lexington"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="div-block-212">
              <a
                href="https://form.jotform.com/241074997816166"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/SaltFacial_grey-2048x431.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 225px, 300px"
                  srcSet="../assets/images/SaltFacial_grey-2048x431-p-500.png 500w, ../assets/images/SaltFacial_grey-2048x431-p-800.png 800w, ../assets/images/SaltFacial_grey-2048x431-p-1080.png 1080w, ../assets/images/SaltFacial_grey-2048x431-p-1600.png 1600w, ../assets/images/SaltFacial_grey-2048x431-p-2000.png 2000w, ../assets/images/SaltFacial_grey-2048x431.png 2048w"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>Salt Facial</h1>
                <p>
                  Providing revolutionary and immediate results for clients, The
                  SaltFacial is a medical-grade device that uses natural sea
                  salt for skin rejuvenation. It’s safe and effective for
                  various skin conditions, including acne, sun damage, fine
                  lines, eczema, and more.
                </p>
                <a
                  href="https://saltmed.com/"
                  target="_blank"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="div-block-212">
              <a
                href="https://form.jotform.com/241074997816166"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/MTF_grey-2048x564-p-2000.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 225px, 300px"
                  srcSet="../assets/images/MTF_grey-2048x564-p-2000-p-500.png 500w, ../assets/images/MTF_grey-2048x564-p-2000-p-800.png 800w, ../assets/images/MTF_grey-2048x564-p-2000-p-1080.png 1080w, ../assets/images/MTF_grey-2048x564-p-2000.png 2000w"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>MTF Biologics</h1>
                <p>
                  Tissue and organ transplantation, related research, and
                  innovation hold vast potential to save and heal lives. At MTF
                  Biologics, we honor the donor gift by fully integrating
                  service, science, and advocacy to improve healthcare,
                  wellbeing, and restore the spirit.
                  <br />
                </p>
                <a
                  href="https://www.mtfbiologics.org/"
                  target="_blank"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="div-block-212">
              <a
                href="https://form.jotform.com/241074997816166"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/Rhorer_grey-2048x336.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 225px, 300px"
                  srcSet="../assets/images/Rhorer_grey-2048x336-p-500.png 500w, ../assets/images/Rhorer_grey-2048x336-p-800.png 800w, ../assets/images/Rhorer_grey-2048x336-p-1080.png 1080w, ../assets/images/Rhorer_grey-2048x336-p-1600.png 1600w, ../assets/images/Rhorer_grey-2048x336-p-2000.png 2000w, ../assets/images/Rhorer_grey-2048x336.png 2048w"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>Rohrer Aesthetics</h1>
                <p>
                  <strong>
                    <em>
                      Rohrer Aesthetics was established on a foundation of
                      integrity and a practical business sense about the
                      aesthetic industry. Rohrer’s reputation for providing cost
                      effective, high quality, reliable products continues,
                      positioning Rohrer Aesthetics for further expansion in the
                      aesthetic market.
                    </em>
                    <br />
                  </strong>
                </p>
                <a
                  href="https://www.rohreraesthetics.com/"
                  target="_blank"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="div-block-212">
              <a
                href="https://form.jotform.com/241074997816166"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/Merz_gray-01-2048x2048.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 225px, 300px"
                  srcSet="../assets/images/Merz_gray-01-2048x2048-p-500.png 500w, ../assets/images/Merz_gray-01-2048x2048-p-800.png 800w, ../assets/images/Merz_gray-01-2048x2048-p-1080.png 1080w, ../assets/images/Merz_gray-01-2048x2048-p-1600.png 1600w, ../assets/images/Merz_gray-01-2048x2048-p-2000.png 2000w, ../assets/images/Merz_gray-01-2048x2048.png 2048w"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>Merz</h1>
                <p>
                  At Merz Aesthetics, we exist to fuel confidence by helping
                  people look better, feel better and live better. We’re here
                  for patients and health care professionals every step of the
                  way – with a product portfolio that’s tailored to a wide
                  variety of aesthetic goals and more than 115 years of proven
                  results.
                  <br />
                </p>
                <a
                  href="https://www.merzusa.com/"
                  target="_blank"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="div-block-212">
              <a
                href="https://form.jotform.com/241074997816166"
                target="_blank"
                className="link-block-4 w-inline-block"
              >
                <img
                  src="../assets/images/corganics_logo.webp"
                  loading="lazy"
                  sizes="(max-width: 479px) 225px, 300px"
                  srcSet="../assets/images/corganics_logo-p-500.webp 500w, ../assets/images/corganics_logo-p-800.webp 800w, ../assets/images/corganics_logo-p-1080.webp 1080w, ../assets/images/corganics_logo-p-1600.webp 1600w, ../assets/images/corganics_logo-p-2000.webp 2000w, ../assets/images/corganics_logo.webp 3816w"
                  alt=""
                  className="image-51"
                />
              </a>
              <div className="div-block-213">
                <h1>Corganics</h1>
                <p>
                  Corganics is the clinical cannabinoid (CBD) brand healthcare
                  professionals trust most. Corganics provides scientifically
                  formulated and physician advised, broad-spectrum CBD products
                  with the same quality expected of clinical grade therapies.
                  Corganics products are rigorously tested, transparent,
                  clinically proven (IRB approved study) and exclusively
                  available through healthcare professionals.
                  <br />
                </p>
                <a
                  href="https://form.jotform.com/241074997816166"
                  className="button-17 "
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
