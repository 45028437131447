"use client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/userContext";

export default function Login() {
  const navigate = useNavigate();
  const { logoutUser } = useUserContext();

  useEffect(() => {
    localStorage.removeItem("companyId");
    localStorage.removeItem("authToken");
    localStorage.removeItem("admin");
    logoutUser();
    navigate("/");
  }, []);

  return;
}
