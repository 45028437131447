import HamburgerMenu from "./hamburger";
import { useShoppingCart } from "../../context/ShoppingCartContext";

export default function DarkStaticNavBar() {
  const { cartQuantity, openCart } = useShoppingCart();

  return (
    <div className="nav-container dark-nav" style={{ position: "relative" }}>
      <div className="logo-container">
        <a href="/">
          <img
            src={require("../../assets/images/Side-by-Side.png")}
            loading="lazy"
            alt=""
            className="image"
          />
        </a>
      </div>
      <div className="nav-link-container">
        <div
          data-hover="true"
          data-delay="0"
          className="w-dropdown"
          onMouseEnter={() => {
            const element = document.getElementById("aboutDrop");
            element.classList.add("w--open");
          }}
          onMouseLeave={() => {
            const element = document.getElementById("aboutDrop");
            element.classList.remove("w--open");
          }}
        >
          <div className="dropdown-toggle w-dropdown-toggle">
            <div className="icon dark-icon w-icon-dropdown-toggle"></div>
            <div className="nav-link dark-link">About</div>
          </div>
          <nav id="aboutDrop" className="w-dropdown-list">
            <a href="/about" className="dropdown-link w-dropdown-link">
              About Us
            </a>
            <a href="/team" className="dropdown-link w-dropdown-link">
              Our Team
            </a>
            <a href="/industry-partners" className="dropdown-link w-dropdown-link">
              Partnerships
            </a>
          </nav>
        </div>
        <div
          data-hover="true"
          data-delay="0"
          className="w-dropdown"
          onMouseEnter={() => {
            const element = document.getElementById("educationDrop");

            element.classList.add("w--open");
          }}
          onMouseLeave={() => {
            const element = document.getElementById("educationDrop");

            element.classList.remove("w--open");
          }}
        >
          <div className="dropdown-toggle w-dropdown-toggle">
            <div className="icon dark-icon w-icon-dropdown-toggle"></div>
            <div className="nav-link dark-link">Events &amp; Education</div>
          </div>
          <nav id="educationDrop" className="w-dropdown-list">
            <a href="/eventCalendar" className="dropdown-link w-dropdown-link">
              All Events
            </a>
            <a href="/virtualEvents" className="dropdown-link w-dropdown-link">
              Virtual Events
            </a>
            <a href="/courses" className="dropdown-link w-dropdown-link">
              Online Courses
            </a>
          </nav>
        </div>
        <div
          data-hover="true"
          data-delay="0"
          className="w-dropdown"
          onMouseEnter={() => {
            const element = document.getElementById("memberDrop");
            element.classList.add("w--open");
          }}
          onMouseLeave={() => {
            const element = document.getElementById("memberDrop");
            element.classList.remove("w--open");
          }}
        >
          <div className="dropdown-toggle w-dropdown-toggle">
            <div className="icon dark-icon w-icon-dropdown-toggle"></div>
            <div className="nav-link dark-link">Members</div>
          </div>
          {localStorage.getItem("authToken") ? (
            <nav id="memberDrop" className="w-dropdown-list">
              <a href="/myAccount" className="dropdown-link w-dropdown-link">
                My Account
              </a>
              <a href="/logout" className="dropdown-link w-dropdown-link">
                Logout
              </a>
            </nav>
          ) : (
            <nav id="memberDrop" className="w-dropdown-list">
              <a href="/Login" className="dropdown-link w-dropdown-link">
                Login
              </a>
            </nav>
          )}
        </div>
        <div>
          <a href="/shop" className="nav-link dark-link">
            Shop
          </a>
        </div>
        {localStorage.getItem("authToken") ? null : (
          <a
            href="/registration"
            className="nav-button-stroke dark-stroke-button anim300"
          >
            Join
          </a>
        )}
        {cartQuantity > 0 ? (
          <div
            className="cart-container"
            onClick={() => {
              openCart();
            }}
          >
            <img
              src={require("../../assets/images/shopping-cart.png")}
              loading="lazy"
              alt=""
              className="image-46"
            />
            <div className="cart-item-count">
              <div className="text-block-78">{cartQuantity}</div>
            </div>
          </div>
        ) : null}
        <a href="/contact" className="nav-button-fill dark-fill-button anim300">
          Contact
        </a>
      </div>

      <div className="hambuger-container">
        <HamburgerMenu />
      </div>
    </div>
  );
}
