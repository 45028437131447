export default function FaqSection({ backgroundColor, FAQList }) {
  return (
    <section className={backgroundColor}>
      <div className="w-layout-blockcontainer container">
        <div className="header-m full-width">FAQ&#x27;S</div>
        <div className="faw-container">
          {FAQList.map((faq, index) => {
            return (
              <div className="faq-row" key={index}>
                <div className="header-s">{faq.question}</div>
                <p className="body-s">
                  {faq.answer}
                  <br />
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
