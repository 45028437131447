import moment from "moment";
import tz from "moment-timezone";

export default function PrivateEvent({ event }) {
  return (
    <div className="private-event-details-container">
      <h4 className="private-event-heading">Private Event</h4>
      <h3 className="event-title">{event.fields.eventTitle}</h3>
      <p className="event-company">{event.fields.presentingCompany}</p>
      <p className="event-location">{event.fields.eventLocation}</p>
      <p className="event-time">
        {moment(event.fields.eventStartDate).format("HH:mm")}
      </p>
      <a
        href={
          event.fields.eventType == "In Person"
            ? null
            : event.fields.eventType == "Affiliate"
            ? event.fields.affiliateOutgoingLink
            : null
        }
        className="link private-event-link"
      >
        See Event Details
      </a>
    </div>
  );
}

export function PublicEvent({ event }) {
  return (
    <div className="public-event-details-container-copy">
      <h4 className="public-event-heading-copy">Public Event</h4>
      <h3 className="event-title">{event.fields.eventTitle}</h3>
      <p className="event-company">{event.fields.presentingCompany}</p>
      <p className="event-location">{event.fields.eventLocation}</p>
      <p className="event-time">
        {moment(event.fields.eventStartDate)
          .tz("America/New_York")
          .format("hh:mm z")}
      </p>
      <a
        href={
          event.fields.eventType == "Affiliate"
            ? event.fields.affiliateOutgoingLink
            : event.fields.eventType == "society"
            ? "/events/society/" + event.sys.id
            : event.fields.eventType == "industry"
            ? "/events/society/" + event.sys.id
            : "/webinar/" + event.sys.id
        }
        className="link public-event-link-copy"
      >
        See Event Details
      </a>
    </div>
  );
}
