import { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [password, setPassword] = useState({
    password: "",
    passwordVerify: "",
  });
  const [errorMessage, setErrorMessage] = useState();
  const [buttonIsAvailable, setButttonIsAvailable] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [requirements, setRequirements] = useState({
    eightChars: false,
    uppercaseLetter: false,
    lowercaseLetter: false,
    oneNumber: false,
  });

  let url;

  function sendNewPassword(e) {
    e.preventDefault();

    axios
      .post("/api/v2/Website/accounts/new-password-creation", {
        newPassword: password.password,
        id: searchParams.get("id"),
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "success") {
          navigate("/login");
          setErrorMessage("success");
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setErrorMessage(err);
      });
  }

  function setNewPassword(e) {
    const tempPassword = e.target.value;

    if (e.target.name == "password") {
      if (tempPassword.length >= 8 && requirements.eightChars == false) {
        setRequirements((prevState) => ({ ...prevState, eightChars: true }));
      } else if (tempPassword.length < 8) {
        setRequirements({ ...requirements, eightChars: false });
      }

      if (/[A-Z]/.test(tempPassword) && requirements.uppercaseLetter == false) {
        setRequirements({ ...requirements, uppercaseLetter: true });
      } else if (
        requirements.uppercaseLetter &&
        /[A-Z]/.test(tempPassword) == false
      ) {
        setRequirements({ ...requirements, uppercaseLetter: false });
      }

      if (/[a-z]/.test(tempPassword) && requirements.lowercaseLetter == false) {
        setRequirements({ ...requirements, lowercaseLetter: true });
      } else if (
        requirements.lowercaseLetter &&
        /[a-z]/.test(tempPassword) == false
      ) {
        setRequirements({ ...requirements, lowercaseLetter: false });
      }

      if (/\d/.test(tempPassword) && requirements.oneNumber == false) {
        setRequirements({ ...requirements, oneNumber: true });
      } else if (requirements.oneNumber && /\d/.test(tempPassword) == false) {
        setRequirements({ ...requirements, oneNumber: false });
      }
    }

    setPassword({
      ...password,
      [e.target.name]: tempPassword,
    });
  }

  return (
    <div className="login-section wf-section">
      <div className="html-embed w-embed">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#111f2d"
            d="M28.6,-33.2C42,-29.6,61.1,-27.9,70.5,-18.4C79.8,-8.9,79.4,8.4,67.7,15.1C56,21.8,33.2,18,20,27.1C6.9,36.2,3.4,58.2,-4.8,64.9C-13.1,71.5,-26.2,62.9,-40.3,54C-54.3,45.2,-69.2,36.3,-78,22.2C-86.9,8.2,-89.7,-10.9,-83.7,-26.5C-77.7,-42.1,-62.9,-54.2,-47.5,-57.1C-32.1,-60.1,-16,-53.9,-4.2,-48.1C7.6,-42.3,15.2,-36.8,28.6,-33.2Z"
            transform="translate(100 100)"
          ></path>
        </svg>
      </div>
      <div className="div-block-20">
        <div className="div-block-19">
          <img
            src={require("../../assets/images/MSN_Colored.svg")}
            loading="lazy"
            // sizes="(max-width: 479px) 100vw, 450px"
            // srcSet="images/MSN_Colored.svg 500w, images/MSN_Colored.svg 800w, images/MSN_Colored.svg 1080w, images/MSN_Colored.svg 1600w"
            alt=""
            className="login-logo"
          />
          <div className="login-hero-text">What's your new password?</div>
          <div className="login-card w-form">
            <form
              id="password-reset-form"
              name="password-reset-form"
              data-name="Password Reset Form"
              method="get"
              className="form"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "left",
              }}
              onSubmit={(e) => {
                sendNewPassword(e);
              }}
            >
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                className="w-input"
                maxLength="256"
                name="password"
                data-name="password"
                placeholder=""
                id="password"
                onChange={(e) => {
                  setNewPassword(e);
                }}
              />
              <label htmlFor="passwordVerify">Verify New Password</label>
              <input
                type="password"
                className="w-input"
                maxLength="256"
                name="passwordVerify"
                data-name="passwordVerify"
                placeholder=""
                id="passwordVerify"
                required=""
                onChange={(e) => {
                  setNewPassword(e);
                }}
              />

              <div className="div-block-87">
                <img
                  src={
                    requirements.eightChars
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>8 Characters or more</div>
              </div>
              <div className="div-block-87">
                <img
                  src={
                    requirements.uppercaseLetter
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>An Uppercase letter</div>
              </div>
              <div className="div-block-87">
                <img
                  src={
                    requirements.lowercaseLetter
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>A Lowercase Letter</div>
              </div>
              <div className="div-block-87">
                <img
                  src={
                    requirements.oneNumber
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>Atleast one number</div>
              </div>
              <div className="div-block-87">
                <img
                  src={
                    password.password == password.passwordVerify &&
                    password.password != ""
                      ? "/images/check.webp"
                      : "/images/remove.webp"
                  }
                  sizes="(max-width: 767px) 15px, (max-width: 991px) 2vw, 15px"
                  alt=""
                  className="image-14"
                />
                <div>Passwords Match</div>
              </div>

              <h3 style={{ color: "red" }}>{errorMessage}</h3>
              {requirements.eightChars &&
              requirements.uppercaseLetter &&
              requirements.lowercaseLetter &&
              requirements.oneNumber &&
              password.password == password.passwordVerify ? (
                <input
                  type="submit"
                  value="Update Password"
                  data-wait="Please wait..."
                  className="login-button w-button"
                />
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
