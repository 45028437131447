import { useLocation, useNavigate } from "react-router-dom";
import AccountNav from "../../components/accountNav";
import Footer from "../../components/footer";
import Navbar from "../../components/navigations";
import Certificate from "../../components/certificate";
import { useEffect, useState } from "react";
import { RefreshUserData } from "../../../services/members";
import moment from "moment";

export default function Certificates() {
  const location = useLocation();
  const [user, setUser] = useState({});
  const [completedCourses, setCompletedCourses] = useState([]);
  const navigate = useNavigate();

  async function GetMemberCertificates() {
    const member = await RefreshUserData().catch(() => {
      navigate("/login");
    });
    console.log(member);
    setUser(member.data);
    setCompletedCourses(member.data.completedCourses);
  }

  useEffect(() => {
    GetMemberCertificates();
  }, []);
  return (
    <>
      <Navbar />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="account-stack">
            <AccountNav currentPage={location.pathname} user={user} />
            <div className="account-right-side">
              <div className="header-xxl">My Account</div>
              <div className="certificates-container">
                <div className="header-m">Certificates</div>
                <div className="certificates-group">
                  {completedCourses.map((course, index) => {
                    return (
                      <Certificate
                        key={index}
                        title={course.title}
                        image={"/images/" + course.image}
                        completionDate={moment(course.completedOn).format(
                          "MM / DD / YYYY"
                        )}
                        description={course.description}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
