import { createContext, useContext } from "react";
import { UseLocaleStorage } from "../hooks/useLocalStorage";

const TrainingContext = createContext({});

export function useTrainingContext() {
  return useContext(TrainingContext);
}

export function TrainingProvider({ children }) {
  const [trainingTicket, setTrainingTicket] = UseLocaleStorage(
    "MSN-WEBSITE-training-ticket",
    {}
  );

  const [couponCodes, setCouponCodes] = UseLocaleStorage(
    "MSN-WEBSITE-coupon-codes",
    []
  );

  function choosePackage(packageData) {
    setTrainingTicket(packageData);
  }

  function addCoupons(coupons) {
    setCouponCodes(coupons);
  }

  function applyCoupon(code) {
    for (let index = 0; index < couponCodes.length; index++) {
      if (couponCodes[index].code === code) {
        setTrainingTicket((prevState) => ({
          ...prevState,
          originalPrice: trainingTicket.originalPrice,
          price: trainingTicket.originalPrice - couponCodes[index].discount,
        }));
        return true;
      } else if (index == couponCodes.length - 1) {
        setTrainingTicket((prevState) => ({
          ...prevState,
          originalPrice: trainingTicket.originalPrice,
          price: trainingTicket.originalPrice,
        }));
        return false;
      }
    }
  }

  return (
    <TrainingContext.Provider
      value={{
        choosePackage,
        trainingTicket,
        applyCoupon,
        addCoupons,
      }}
    >
      {children}
    </TrainingContext.Provider>
  );
}
