import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { UseLocaleStorage } from "../hooks/useLocalStorage";

const UserContext = createContext({});

export function useUserContext() {
  return useContext(UserContext);
}

export function UserContextProvider({ children }) {
  const [user, setUser] = UseLocaleStorage("user", {});
  const [isLoggedIn, setIsLoggedIn] = UseLocaleStorage(
    "MSN-WEBSITE-LoggedIn",
    false
  );

  function setUserInformation(data) {
    setUser(data);
  }

  function loginUser(data) {
    setIsLoggedIn(true);
    setUser(data);
  }

  function logoutUser() {
    setIsLoggedIn(false);
    setUser({});
  }

  function clearUser() {
    setUser({});
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUserInformation,
        clearUser,
        loginUser,
        isLoggedIn,
        logoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
