import Nav from "../../components/nav";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";

export default function SurveyThankYou() {
  const navigate = useNavigate();
  return (
    <>
      <Nav />
      <div className="section-21 wf-section">
        <img
          src="images/heart.webp"
          loading="lazy"
          sizes="200px"
          srcSet="images/heart.webp 500w"
          alt=""
          className="image-16"
        />
        <h1 className="congrats-heading">Thank You!</h1>
        <h1 className="thank-you-sub-text">
          Your survey entry has been anonymously entered!
        </h1>
        <button
          onClick={() => {
            navigate("/labRegistration");
          }}
          className="w-button"
          style={{ marginTop: 20 }}
        >
          Check out our upcoming in person training
        </button>
      </div>

      <Footer />
    </>
  );
}
