import { useEffect } from "react";

export default function AccountNav({ currentPage, user }) {
  useEffect(() => {
    const selectedLink = document.getElementById(currentPage);
    selectedLink.style.backgroundColor = "#eaebef";
  }, []);

  return (
    <div className="account-nav hide-mobile">
      <div className="account-nav-header">
        <img src={"https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"} loading="lazy" alt="" className="user-image" />
        <div>
          <div className="header-xs">
            {user.firstName + " " + user.lastName}
          </div>
          <div className="caption-s light">{user.email}</div>
        </div>
      </div>
      <div className="account-nav-selections-container">
        <a href="/account" className="account-nav-selection " id="/account">
          <div className="button m dark">Profile</div>
        </a>
        <a
          href="/certificates"
          className="account-nav-selection "
          id="/certificates"
        >
          <div className="button m dark">Certificates</div>
        </a>
        <a href="/payments" className="account-nav-selection " id="/payments">
          <div className="button m dark">Payments</div>
        </a>
        <a
          href="/my-membership"
          className="account-nav-selection "
          id="/my-membership"
        >
          <div className="button m dark">Membership</div>
        </a>
        <div className="horizontal-divider"></div>
        <a href="/settings" className="account-nav-selection " id="/settings">
          <div className="button m dark">Settings</div>
        </a>
        <a href="/support" className="account-nav-selection " id="/support">
          <div className="button m dark">Support</div>
        </a>
      </div>
    </div>
  );
}
