import { Dense_button_dark } from "../components/buttons";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export default function EventThankYou() {
  return (
    <>
      <Navbar />
      <section
        className="white-background-section"
        style={{
          backgroundImage: `https://lottie.host/561c0b9c-79fb-4ec5-b976-58dba285b891/I1KUE6kKrN.json`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <div className="w-layout-blockcontainer container">
          <div className="center">
            <DotLottieReact
              src="https://lottie.host/561c0b9c-79fb-4ec5-b976-58dba285b891/I1KUE6kKrN.json"
              loop
              autoplay
              style={{ width: "500px" }}
            />
            <h1
              className="header-xxl"
              style={{ width: "100%", textAlign: "center" }}
            >
              Woo Hoo!
            </h1>
            <h2
              className="header-s"
              style={{ width: "75%", textAlign: "center" }}
            >
              You are all set! We can't wait to see you at the webinar.
            </h2>
          </div>
          <div className="button-group">
            <Dense_button_dark text="Home" link="/" />
            <Dense_button_dark text="About Us" link="/about" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
