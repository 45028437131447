import { Link } from "react-router-dom";

export default function PartnerCard({
  name,
  image,
  description,
  link,
  products,
}) {
  return (
    <div className="partner-card">
      <img
        loading="lazy"
        src={image}
        alt=""
        className="partner-card-image"
        style={{ objectFit: "contain" }}
      />
      <div className="header-s">{name}</div>
      <p className="body-s">{description.substring(0, 100) + "..."}</p>
      <Link
        to={"/partner/" + name}
        className="button m dark"
        state={{
          name: name,
          image: image,
          description: description,
          link: link,
          products: products,
        }}
      >
        Read More
      </Link>
    </div>
  );
}
