export default function EventsListTag({ tag, tagsCounts, filter, setFilter }) {
  return (
    <div
      className="badge-dark"
      style={{
        backgroundColor:
          filter?.toLowerCase() == tag.name.toLowerCase() ? "lightgrey" : null,
        position: "relative",
      }}
    >
      <button
        className="badge-text"
        onClick={() => {
          setFilter(tag.name.toLowerCase());
        }}
      >
        {tag.name}
      </button>
      <div
        style={{
          borderRadius: 50,
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 20,
          height: 20,
          position: "absolute",
          top: -10,
          right: -10,
        }}
      >
        <p style={{ color: "black", padding: 0, margin: 0 }}>
          {tagsCounts ? tagsCounts : 0}
        </p>
      </div>
    </div>
  );
}
