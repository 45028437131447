import moment from "moment";

export default function AttendeeCard({ props, index }) {
  console.log(index);
  return (
    <div
      style={{
        border: "1px solid black",
        padding: "10px",
        backgroundColor: (index / 2) % 1 ? "rgb(230,230,230)" : "",
      }}
    >
      <h3>{props.firstName + " " + props.lastName}</h3>
      {Object.keys(props).map((key, index) => {
        return <h5 key={index}>{key + ": " + props[key]}</h5>;
      })}
    </div>
  );
}
