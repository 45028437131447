import { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

export default function QuizModal({ modalIsOpen, setModalIsOpen }) {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      width: "50%",
      height: "50%",
      borderRadius: 8,
      color: "black",
    },
  };

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Quiz Modal">
      <h2 className="header-l">
        Congrats, You've finished all of the classes in this course!
      </h2>
      <div className="header-s pad-top-s">
        Are you ready to take the final quiz, or would you like to watch more
        videos?
      </div>
      <div className="button-group">
        <button
          className="button-dense-primary-light"
          onClick={() => {
            setModalIsOpen(false);
          }}
        >
          Go back to class
        </button>
        <button
          className="button-dense-primary-dark"
          onClick={() => {
            navigate("/quiz?" + new URLSearchParams({ _id: query.get("_id") }));
          }}
        >
          Start Quiz
        </button>
      </div>
    </Modal>
  );
}
