import axios from "axios";
import { useState } from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";

export default function Survey() {
  const [surveyAnswers, setSurveyAnswers] = useState();
  const navigate = useNavigate();

  function submitSurvey(e) {
    e.preventDefault();
    axios
      .post("www.medspanetwork.com/api/survey/submit", {
        ...surveyAnswers,
      })
      .then((res) => {
        navigate("/surveyThankYou");
      });
  }

  return (
    <>
      <Nav />
      <div className="section-23 wf-section">
        <div className="div-block-105">
          <div className="form-block-2 w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              onSubmit={(e) => {
                submitSurvey(e);
              }}
            >
              <label htmlFor="field-2" className="survery-field-label">
                Did you enjoy this weeks webinar?
              </label>
              <textarea
                placeholder="Answer Here"
                maxLength="5000"
                id="field-2"
                name="field-2"
                data-name="Field 2"
                className="w-input"
                onChange={(e) => {
                  setSurveyAnswers({
                    ...surveyAnswers,
                    didYouEnjoy: e.target.value,
                  });
                }}
              ></textarea>
              <label htmlFor="field" className="survery-field-label">
                What would you change about the webinar series?
              </label>
              <textarea
                placeholder="Answer Here"
                maxLength="5000"
                id="field"
                name="field"
                data-name="Field"
                className="w-input"
                onChange={(e) => {
                  setSurveyAnswers({
                    ...surveyAnswers,
                    whatWouldYouChange: e.target.value,
                  });
                }}
              ></textarea>
              <label htmlFor="field-3" className="survery-field-label">
                What would you like us to cover next?
              </label>
              <textarea
                placeholder="Answer Here"
                maxLength="5000"
                id="field-3"
                name="field-3"
                data-name="Field 3"
                className="w-input"
                onChange={(e) => {
                  setSurveyAnswers({
                    ...surveyAnswers,
                    whatToCoverNext: e.target.value,
                  });
                }}
              ></textarea>
              <input
                type="submit"
                value="Submit"
                data-wait="Please wait..."
                className="w-button"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
