import axios from "axios";

export default function Emails() {}

export async function SendFeedback(reason, feedBack) {
  console.log(reason, feedBack);
  const res = await axios.post("/api/v2/emails/feedback", {
    reason: reason,
    feedback: feedBack,
  });
  return res.data.data;
}
