import axios from "axios";
import { useEffect, useState } from "react";

export default function ServiceStatus() {
  const [services, setServices] = useState(["", "", "", "", ""]);

  useEffect(() => {
    axios.get("/api/v2/services/status").then((res) => {
      console.log(res.data);
      setServices(res.data);
    });
  }, []);

  return (
    <>
      <div className="dashboard-page wf-section">
        <div className="dashboard-sidebar">
          <div className="div-block-168">
            <a href="#" className="link-block selected w-inline-block">
              <img
                src={require("../../assets/images/dashboard-interface-white.png")}
                loading="lazy"
                alt=""
                className="image-30"
              />
              <div className="text-block-68">Dashboard</div>
            </a>
            <a href="#" className="link-block unselected w-inline-block">
              <img
                src={require("../../assets/images/stats.png")}
                loading="lazy"
                alt=""
                className="image-30"
              />
              <div className="text-block-68">Stats</div>
            </a>
            <a href="#" className="link-block unselected w-inline-block">
              <img
                src={require("../../assets/images/todo.png")}
                loading="lazy"
                alt=""
                className="image-30"
              />
              <div className="text-block-68">Todo&#x27;s</div>
            </a>
            <a href="#" className="link-block unselected w-inline-block">
              <img
                src={require("../../assets/images/status.png")}
                loading="lazy"
                alt=""
                className="image-30"
              />
              <div className="text-block-68">Service Status</div>
            </a>
            <a href="#" className="link-block unselected w-inline-block">
              <img
                src={require("../../assets/images/error.png")}
                loading="lazy"
                alt=""
                className="image-30"
              />
              <div className="text-block-68">Error Logs</div>
            </a>
          </div>
        </div>
        <div className="dashboard-content">
          <div className="dashboard-searchbar-card">
            <div className="form-block-4 w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="form-7"
              >
                <input
                  type="text"
                  className="text-field-3 w-input"
                  maxLength="256"
                  name="field-2"
                  data-name="Field 2"
                  placeholder="Search"
                  id="field-2"
                  required=""
                />
              </form>
            </div>
          </div>
          <div className="div-block-169">
            {services.map((selectedService, index) => {
              return (
                <div
                  key={index}
                  className="service-card"
                  style={{
                    borderBottom:
                      selectedService.status == 200
                        ? "10px solid #13bb00"
                        : "10px solid #d2282d",
                  }}
                >
                  <h3 className="service-card-title">{selectedService.name}</h3>
                  <div className="text-block-69">
                    {selectedService.status == 200
                      ? "This service is running normally."
                      : "This service is NOT running normally."}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
